import { queryParamHandler } from './queryParamHandler';

export const queryParamMixin = {
    mixins: [queryParamHandler],

    methods: {
        saveQueryParamsToStore(queryParams) {
            if (queryParams.listId) {
                this.$store.commit(`setSelectedBookmarkList`, queryParams.listId);
            }
            if (queryParams.page) {
                this.$store.commit(`${this.moduleName}/setCurrentPage`, parseInt(queryParams.page, 10));
            }
            if (queryParams.size) {
                this.$store.commit(`${this.moduleName}/setSortSize`, parseInt(queryParams.size, 10));
            }
            if (queryParams.sort) {
                this.$store.commit(`${this.moduleName}/setSortBy`, queryParams.sort);
            }
            if (queryParams.order) {
                this.$store.commit(`${this.moduleName}/setSortOrder`, queryParams.order);
            }
        }
    }
};
