<template>
  <mein-genios-template :pageTitle="$t('meinGenios.searchHistory.title')" :page-info="$t('meinGenios.searchHistory.info')" class="search-history">
    <template v-slot:content>
      <div v-if="hasSearchHistory" class="flex-column-max-height">
        <header-layout
            :action-bar-config="actionBarConfig"
            @remove-all="removeAll"
            @update:selected-all="selectAll($event)"
            @update-list="updateList"
            class="search-history__action-bar">
        ></header-layout>

        <custom-table-component
            class="search-history__table"
            :customTableArray="customTableArray"
            @column-event="handleColumnEvent"
            @item-clicked="handleItemClicked"
        />
        <pagination-component
            v-if="pagesQuantity > 1"
            :pages="pagesQuantity"
            :selected="currentPage"
            @update-page="updateCurrentPage"
        />
        <info-overlay-component
            v-if="showFiltersModal"
            :modal-data="modalContent"
            v-click-outside="onClickOutside"
            @primary-action="searchAgain"
            @secondary-action="closeInfoOverlay"
        >
          <template v-for="(value, name) in activeFilters">
            <active-filter-tag
                v-for="filter in value"
                :key="name+':'+filter"
                :no-current-search="true"
                :element="filter"
                :removable="false"
                :type="name"
            />
          </template>
        </info-overlay-component>
      </div>
      <div v-else>
        <empty-content-placeholder :icon="'search-outline'" :message="$t('meinGenios.searchHistory.empty')" :is-loading="isLoading"></empty-content-placeholder>
      </div>

    </template>
  </mein-genios-template>
</template>

<script>
import MeinGeniosTemplate from "./MeinGeniosTemplate.vue";
import HeaderLayout from "./common/HeaderLayout.vue";
import {ActionBarType} from "../../../functions/consts";
import searchHistoryModule from "../../../functions/store/modules/searchHistory";
import ActionBar from "./common/ActionBar.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import CustomTableComponent from "../common/CustomTableComponent.vue";
import EmptyContentPlaceholder from "../styled/EmptyContentPlaceholder.vue";
import PaginationComponent from "../styled/PaginationComponent.vue";
import {queryParamMixin} from "../../../functions/setups/vue-components/queryParamMixin";
import InfoOverlayComponent from "../common/InfoOverlayComponent.vue";
import ActiveFilterTag from "../Filters/ActiveFilterTag.vue";
import {formatDate} from "../../../functions/utils/date_utils";
import i18n from "../plugins/Translations.vue";

export default {
  name: "SearchHistoryComponent.vue",
  components: {
    ActiveFilterTag,
    InfoOverlayComponent,
    PaginationComponent,
    EmptyContentPlaceholder,
    CustomTableComponent,
    CheckboxFormElement,
    ActionBar,
    HeaderLayout,
    MeinGeniosTemplate,
  },
  mixins: [queryParamMixin],
  data() {
    return {
      moduleName: 'searchHistoryModule',
      showFiltersModal: false,
      searchAgainUrl: null,
      modalContent: {},
      activeFilters: [],
      dropdownActionBarButtons: ActionBarType.DROPDOWN_SEARCH_HISTORY,
      actionBarButtons: ActionBarType.SEARCH_HISTORY,
      cutTextValue: 100,
      headers: [
        {
          key: 'checkbox',
          label: '',
          columnWidth: '10px',
          render: 'CheckboxFormElement',
          props: (row) => ({
            element: row.checkboxObj.element,
            passedValue: row.checkboxObj.passedValue,
          }),
        },
        { key: 'query', label: 'Suchbegriff', columnWidth: '45vw', isClickable: true },
        { key: 'source', label: 'Quelle', columnWidth: '25vw' },
        { key: 'noOfHits', label: 'Treffer', columnWidth: '15vw' },
        {
          key: 'action',
          label: '',
          render: "ActionBar",
          props: (row) => ({
            actionBarButtons: row.actionBarObject.actionBarButtons,
            selectedList: row.actionBarObject.selectedList,
            allDocumentsOnPage: row.actionBarObject.allDocumentsOnPage,
          }),
        },
      ]
    };
  },
  computed: {
    searchHistoryArray() {
      return this.$store.getters['searchHistoryModule/getSearchHistory'](this.currentPage) || [];
    },
    hasSearchHistory() {
      return this.searchHistoryArray.length > 0;
    },
    selectedSearchHistory() {
      return this.$store.getters['searchHistoryModule/getSelectedSearchHistory'];
    },
    pagesQuantity() {
      return (this.$store.getters['searchHistoryModule/getTotalNumberOfPages'] || 0 )
    },
    isLoading() {
      return this.$store.getters['searchHistoryModule/getLoading'];
    },
    isAllSelected() {
      return this.searchHistoryArray.length > 0 && this.searchHistoryArray.every(search => this.selectedSearchHistory.map(history => history.id).includes(search.id));
    },
    actionBarConfig() {
      return {
        dropdownActionBarButtons: this.dropdownActionBarButtons,
        selectedArticles: this.selectedSearchHistory,
        isAllSelected: this.isAllSelected,
        allDocumentsOnPage: this.searchHistoryArray,
        shouldDisplaySortBar: false
      }
    },
    getRows() {
      return this.searchHistoryArray.map(item => {
        const extendedQueryObject = this.getExtendedQueryText(item.analysis);
        return {
          id: item.id,
          noOfHits: new Intl.NumberFormat('de-DE').format(item.noOfHits),
          query: extendedQueryObject.query,
          queryText: extendedQueryObject.queryText,
          isClickable: extendedQueryObject.isClickable,
          availableFilters: extendedQueryObject.availableFilters,
          searchAgainUrl: item.analysis.url,
          source: item.analysis?.source?.join(', ') || item.analysis?.category?.join(', ') || item.analysis?.navigation,
          checkboxObj: {
            element: {
              id: item.id,
              name: '',
              fieldLabel: '',
              field: false,
              error: false,
            },
            passedValue: this.selectedSearchHistory.some(history => history.id === item.id),
          },
          actionBarObject: {
            actionBarButtons: this.actionBarButtons,
            selectedList: [item],
          }
      }})
    },
    currentPage() {
      return this.$store.getters['searchHistoryModule/getCurrentPage'];
    },
    customTableArray() {
      return {
        columns: this.headers,
        rows: this.getRows,
      };
    },
  },
  async created() {
    if (!this.$store.hasModule('searchHistoryModule')) {
      this.$store.registerModule('searchHistoryModule', searchHistoryModule);
    }
  },
  async mounted() {
    const query = this.getQueryParams();
    this.saveQueryParamsToStore(query);
    if (query) {
      await this.loadSearchHistory();
    }

    this.updateRoute();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    this.$store.commit('searchHistoryModule/resetState');
  },
  methods: {
    handleResize() {
      this.cutTextValue = this.getCutTextValue();
    },
    async loadSearchHistory() {
      await this.$store.dispatch('searchHistoryModule/fetchSearchHistory');
    },
    selectAll(event) {
      if (event) {
        this.$store.commit('searchHistoryModule/setSelectedSearchHistory', [...this.selectedSearchHistory, ...this.searchHistoryArray]);
      } else {
        const remainingSelectedSearchHistory = [
          ...this.selectedSearchHistory.filter(item1 => !this.searchHistoryArray.some(item2 => item1.id === item2.id)),
          ...this.searchHistoryArray.filter(item2 => !this.selectedSearchHistory.some(item1 => item1.id === item2.id))
        ]
        this.$store.commit('searchHistoryModule/setSelectedSearchHistory', remainingSelectedSearchHistory);
      }
    },
    removeAll() {
      this.$store.commit('searchHistoryModule/setSelectedSearchHistory', []);
    },
    async updateList() {
      const oldPage = this.currentPage;
      this.$store.commit('searchHistoryModule/setSearchHistory', []);
      this.$store.commit('searchHistoryModule/setSelectedSearchHistory', []);
      this.$store.commit('searchHistoryModule/setCurrentPage', 1);
      this.updateRoute();
      if (oldPage === 1) {
        await this.loadSearchHistory();
      }
    },
    getCutTextValue() {
      const containerWidth = window.innerWidth * 0.4;
      const averageCharWidth = 8;
      return Math.floor(containerWidth / averageCharWidth);
    },
    handleColumnEvent({ row, key, value }) {
      if (key === "checkbox") {
        const selectedSearchHistory = this.$store.getters['searchHistoryModule/getSelectedSearchHistory'];
        const newSelectedSearchHistory = this.searchHistoryArray.find(item => item.id === row.id);
        if (value && newSelectedSearchHistory) {
          this.$store.commit('searchHistoryModule/setSelectedSearchHistory', Array.from(
              new Set([...selectedSearchHistory, newSelectedSearchHistory])));
        } else {
          this.$store.commit('searchHistoryModule/setSelectedSearchHistory', selectedSearchHistory.filter(item => item.id !== row.id));
        }
      }
      if (key === "action") {
        this.updateList();
      }
    },
    async updateCurrentPage(page) {
      if (page !== this.currentPage) {
        this.$store.commit('searchHistoryModule/setCurrentPage', page);
        this.updateRoute();
      }
    },
    getExtendedQueryText(analysis) {
      if (!this.hasExtendedText(analysis)) {
        const queryText = analysis.query || '';
        const isTruncated = queryText.length > this.cutTextValue;
        return {
          query: isTruncated
              ? `<span class="standard-bold">${queryText.substring(0, this.cutTextValue)}</span> ... <span class="simulate-link">${this.$t('meinGenios.searchHistory.more')}</span>`
              : `<span class="standard-bold">${queryText}</span>`,
          isClickable: isTruncated,
          queryText: queryText,
        };
      }
      let queryText = {
          value: [analysis.query],
        };

      const flatResult = {};

      if (Array.isArray(analysis.advanced)) {
        analysis.advanced.forEach(item => {
          if (item.value) {
            flatResult[item.label] = [item.value];
          } else if (item.index !== undefined) {
            flatResult[item.label] = [item.index];
          }
          if (!queryText.value[0] && Object.keys(flatResult).length > 0) {
            queryText = this.addQueryTextValueFromExistingObjects(flatResult);
          }

          if (item.from && item.to) {
            flatResult[item.label] = [{from: item.from, to: item.to}];
          }

          if (!queryText.value[0] && !!(item.from || item.to)) {
            if (item.label) {
              queryText = {
                value: [`${item.from || ''} - ${item.to || ''}`],
                label: item.label
              }
            }
          }
        });
      }

      const stringListFields = ['category', 'source', 'industry', 'company', 'topic', 'region', 'person', 'author', 'instructor'];
      const result = this.extractExtendedSearchObjects(stringListFields, analysis);

      for (const [, values] of Object.entries(result)) {
        values.forEach(entry => {
          for (const [subKey, subValue] of Object.entries(entry)) {
            flatResult[subKey] = flatResult[subKey] || [];
            flatResult[subKey].push(subValue);
          }
        });
      }
      if (!queryText.value[0] && Object.keys(flatResult).length > 0) {
        queryText = this.addQueryTextValueFromExistingObjects(flatResult);
      }

      if (analysis.date && typeof analysis.date === 'object') {
        let from = analysis.date.from;
        let to = analysis.date.to;
        if (from) {
          from = formatDate(analysis.date.from);
        }
        if (to) {
          to = formatDate(analysis.date.to);
        }
        flatResult.date = [{
          from: from || '',
          to: to || '',
        }];
        if (!queryText.value[0] && !!(from || to)) {
          queryText = {
            value: [`${from || ''} - ${to || ''}`],
            label: 'date'
          }
        }
      }
      const isClickable = Object.keys(flatResult).length > 0 || queryText.value[0] !== analysis.query;
      const label = queryText.label ? ` (${this.resolveMessage('genios.' +  queryText.label.toLowerCase(), queryText.label)}) ` : '';
      const text = queryText.value[0].length > this.cutTextValue
          ? `<span class="standard-bold">${queryText.value[0].substring(0, this.cutTextValue)}</span>`.concat(`${label}`).concat(` ... <span class="simulate-link">${this.$t('meinGenios.searchHistory.more')}</span>`)
          : `<span class="standard-bold">${queryText.value[0]}</span>`.concat(`${label}`).concat(isClickable ? ` ... <span class="simulate-link">${this.$t('meinGenios.searchHistory.more')}</span>` : '');
      return {
        query: text,
        queryText: analysis.query,
        isClickable: isClickable,
        availableFilters: flatResult
      };
    },
    handleItemClicked({ row }) {
      this.activeFilters = row.availableFilters;
      this.searchAgainUrl = row.searchAgainUrl;
      this.modalContent = {
        title: this.$t(`meinGenios.searchHistory.searchQueryPopup.title`),
        description: row.queryText,
        primaryButtonText: this.$t(`meinGenios.searchHistory.searchQueryPopup.primaryButton`),
        secondaryButtonText: this.$t(`meinGenios.searchHistory.searchQueryPopup.secondaryButton`),
        standardColor: true,
      };
      this.showFiltersModal = true;
    },
    searchAgain() {
      const url = this.searchAgainUrl;
      this.searchAgainUrl = null;
      this.showFiltersModal = false;
      this.activeFilters = [];
      if (url) {
        window.location.href = url;
      }
    },
    closeInfoOverlay() {
      this.showFiltersModal = false;
      this.activeFilters = [];
    },
    onClickOutside() {
      this.showFiltersModal = false;
      this.activeFilters = [];
    },
    updateRoute() {
      this.syncQueryParams({
        page: this.currentPage,
      });
    },
    hasExtendedText(analysis) {
      return  Object.keys(analysis).some(key =>
          key !== 'query' &&
          key !== 'navigation' &&
          Array.isArray(analysis[key]) &&
          analysis[key].length > 0 ||
          key === 'date' &&
          typeof analysis[key] === 'object'
      );
    },
    extractExtendedSearchObjects(stringListFields, analysis) {
      const result = {};
      stringListFields.forEach(field => {
        if (Array.isArray(analysis[field])) {
          analysis[field].forEach(value => {
            result[field] = result[field] || [];
            result[field].push({[field]: value});
          });
        }
      });
      return result;
    },
    addQueryTextValueFromExistingObjects(flatResult) {
      const firstKey = Object.keys(flatResult)[0];
      if (firstKey) {
        return  {
          value: [`${flatResult[firstKey]}`],
          label: firstKey
        }
      }
    },
    resolveMessage: function (key, fallback) {
      const displayText = i18n.t(key);
      if (displayText === key) {
        return fallback;
      }
      return displayText;
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      async handler(query) {
        if (query && this.$store.hasModule(this.moduleName)) {
          this.saveQueryParamsToStore(query);
          await this.loadSearchHistory();
        }
      },
    },
  },
};
</script>
