<!--suppress HtmlUnknownAttribute -->
<template>
  <div ref="date_filter_chart_container" class="date_filter_chart__container">
    <div class="date_filter_chart__tooltip"
         :class="(toolTipShown ? 'date_filter_chart__tooltip-show' : '')">
      <div class="date_filter_chart__tooltip__key">{{toolTip.key}}</div>
      <div class="date_filter_chart__tooltip__value">{{toolTip.value}} {{ $t('genios.results') }}</div>
    </div>
    <svg :height="chartHeight" :width="chartWidth">
      <line
          v-for="yAxisValue in yAxisValues"
          class="date_filter_chart__axis"
          :x1="yAxisLabelWidth"
          :y1="graphHeight - scaleY(yAxisValue)"
          :x2="yAxisLabelWidth + chartWidth"
          :y2="graphHeight - scaleY(yAxisValue)"
      />
      <g class="date_filter_chart__column"
         v-for="(bar, index) in bars"
         v-on:mouseover="handleMouseOver($event, bar.key, bar.value)"
         @mouseout="handleMouseOut"
      >
        <rect
            class="date_filter_chart__column__background"
            :class="bar.preselected ? 'date_filter_chart__column__background-preselected' : (bar.selected ? 'date_filter_chart__column__background-selected' : '')"
            :x="yAxisLabelWidth + (index * columnWidth)"
            :width="columnWidth"
            :height="graphHeight"
            :data-from="bar.from.format('YYYY-MM-DD')"
            :data-to="bar.to.format('YYYY-MM-DD')"
            v-touch:start="columnClickedStart"
            v-touch:end="columnClickedEnd"
        />
        <path
            :id="bar.key"
            class="date_filter_chart__column__bar"
            :class="bar.preselected ? 'date_filter_chart__column__bar-preselected' : (bar.selected ? 'date_filter_chart__column__bar-selected' : '')"
            :d="bar.barPath"
            :data-from="bar.from.format('YYYY-MM-DD')"
            :data-to="bar.to.format('YYYY-MM-DD')"
            v-touch:start="columnClickedStart"
            v-touch:end="columnClickedEnd"
        >
        </path>
      </g>
      <text
          v-for="yAxisValue in yAxisValues"
          class="date_filter_chart__axis date_filter_chart__axis-text"
          :x="yAxisLabelWidth - 1"
          :y="graphHeight - scaleY(yAxisValue)"
          alignment-baseline="central"
          text-anchor="end"
      >{{yAxisValue.toLocaleString(localeString)}}</text>
    </svg>
    <div class="date_filter_chart__range">
      <div class="date_filter_chart__range__from" :style="'margin-left: ' + yAxisLabelWidth + 'px'">
        {{fullChartRangeDisplay.from}}
      </div>
      <div class="date_filter_chart__range__to">
        {{fullChartRangeDisplay.to}}
      </div>
    </div>
  </div>
</template>
<!--suppress HtmlUnknownAttribute -->

<script>


import moment from "moment";
import vClickOutside from 'v-click-outside'

export default {
  name: 'DateFilterChart',
  data: function () {
    return {
      chartWidth: 0,
      toolTipTimer: null,
      toolTip: {key: '', value: ''},
      toolTipShown: false,
      localeString: window.navigator.language,
      clickStart: null,
      preselect: {from: null, to: null}
    }
  },
  props: {
    chartHeight: Number,
    chartData: Array,
    selectedFrom: String,
    selectedTo: String,
    scale: String,
    fullChartRangeDisplay: Object
  },
  computed: {
    graphHeight: function () {
      return this.chartHeight - 10;
    },
    yAxisLabelWidth: function () {
      return (Math.round(this.maxYAxisLine * 0.75).toLocaleString(this.localeString).length * 6.5) + 3;
    },
    graphWidth: function () {
      return Math.max(0, this.chartWidth - this.yAxisLabelWidth);
    },
    columnWidth: function () {
      return this.graphWidth / this.chartData.length;
    },
    barWidth: function () {
      return Math.min(12, 0.8 * this.columnWidth);
    },
    barRadius: function () {
      return this.barWidth / 2;
    },
    maxCount: function () {
      if (this.chartData && this.chartData.length ) {
        const realMaxCount = Math.max.apply(null, this.chartData.map(el => el.count));
        return Math.max(realMaxCount, 1); // for calculation
      }
      return 1;
    },
    maxY: function () {
      return Math.max(this.maxCount, this.maxYAxisLine);
    },
    yAxisTickRange: function () {
      const range = this.maxCount;
      const tickCount = 4;
      const unroundedTickSize = range/(tickCount);
      const x = Math.ceil(Math.log10(unroundedTickSize)-1);
      const pow10x = Math.pow(10, x);
      return Math.ceil(unroundedTickSize / pow10x) * pow10x;
    },
    maxYAxisLine: function () {
      return Math.max( 4, this.yAxisTickRange * 4);
    },
    yAxisValues: function () {
      return [
          0,
        Math.round(this.maxYAxisLine * 0.25),
        Math.round(this.maxYAxisLine * 0.5),
        Math.round(this.maxYAxisLine * 0.75),
      ]
    },
    bars: function () {
      return this.chartData.map((dataElement, index) => {
        const height = Math.ceil(this.scaleY(dataElement.count));
        const barVertical = Math.max(0, height - this.barRadius );
        const yRadius = Math.min(this.barRadius, height);
        const barPath = this.getBarPath(index, barVertical, yRadius);
        let lowBarPath = this.getBarPath(index, 0, 0);
        const barFromIsInSelectedRange = dataElement.from.isSame(this.selectedFromMoment) || dataElement.from.isAfter(this.selectedFromMoment)
        const barToIsInSelectedRange = dataElement.to.isSame(this.selectedToMoment) || dataElement.to.isBefore(this.selectedToMoment)
        const barFromIsInPreselectedRange = dataElement.from.isSame(this.preselect.from) || dataElement.from.isAfter(this.preselect.from)
        const barToIsInPreselectedRange = dataElement.to.isSame(this.preselect.to) || dataElement.to.isBefore(this.preselect.to)
        return {
          key: dataElement.key,
          value: dataElement.count,
          barPath: barPath,
          lowBarPath: lowBarPath,
          selected: barFromIsInSelectedRange && barToIsInSelectedRange,
          preselected: barFromIsInPreselectedRange && barToIsInPreselectedRange,
          from: dataElement.from,
          to: dataElement.to
        }
      })
    },
    selectedFromMoment: function () {
      if (!this.selectedFrom) return null;
      return moment(this.selectedFrom, "D.M.YYYY");
    },
    selectedToMoment: function () {
      if (!this.selectedTo) {
        // noinspection JSCheckFunctionSignatures
        return moment().endOf(this.scale).startOf('day');
      }
      return moment(this.selectedTo, "D.M.YYYY");
    }

  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        this.chartWidth = this.getContainerWidth();
      }
    });
    const parentElement = this.$el.parentElement;
    if (parentElement) {
      this.resizeObserver.observe(parentElement);
    }
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    getContainerWidth: function () {
      if (!this.$refs.date_filter_chart_container) return 0;
      return  this.$refs.date_filter_chart_container.offsetWidth || 0;
    },
    scaleY: function (count) {
      return (count * 1.0 / this.maxY) * this.graphHeight;
    },
    handleMouseOver: function (evt, key, value) {
      if (!!this.toolTipTimer) {
        clearTimeout(this.toolTipTimer);
      }
      this.toolTipTimer = setTimeout(() => {
        this.toolTip = {
          key :key,
          value : parseInt(value).toLocaleString(this.localeString)
        };
        this.toolTipShown = true;
        this.columnClickedMoving(evt);
      }, 50);
    },
    handleMouseOut: function () {
      if (!!this.toolTipTimer) {
        clearTimeout(this.toolTipTimer);
      }
      this.toolTipTimer = setTimeout(() => {
        this.toolTipShown = false;
      }, 50);
    },
    getBarPath : function (index, barVertical, yRadius) {
      return '' +
          'M ' + (this.yAxisLabelWidth + (index * this.columnWidth + ((this.columnWidth - this.barWidth) / 2))) + ',' + this.graphHeight + ' ' +
          'v-' + barVertical + ' ' +
          'a ' + this.barRadius + ',' + yRadius + ' 0 0 1 ' + this.barWidth + ',0 ' +
          'v' + barVertical + ' ' +
          'z';
    },
    biggestRange: function (from, to) {
      let newFrom = from;
      if (this.clickStart && this.clickStart.from && this.clickStart.from.isBefore(from)) {
        newFrom = this.clickStart.from;
      }
      let newTo = to;
      if (this.clickStart && this.clickStart.to && this.clickStart.to.isAfter(to)) {
        newTo = this.clickStart.to;
      }
      return {newFrom, newTo};
    },
    columnClickedStart: function (event) {
      const from = moment(event.target.getAttribute('data-from'), 'YYYY-MM-DD');
      const to = moment(event.target.getAttribute('data-to'), 'YYYY-MM-DD');
      this.clickStart={from: from, to: to};
    },
    columnClickedEnd: function (event) {
      const from = moment(event.target.getAttribute('data-from'), 'YYYY-MM-DD');
      const to = moment(event.target.getAttribute('data-to'), 'YYYY-MM-DD');

      let {newFrom, newTo} = this.biggestRange(from, to);
      this.clickStart = null;
      this.preselect = {from: null, to: null};
      this.$emit("rangeSelected", {
        from: newFrom,
        to: newTo
      });
    },
    columnClickedMoving: function (event) {
      if (!!this.clickStart) {
        const from = moment(event.target.getAttribute('data-from'), 'YYYY-MM-DD');
        const to = moment(event.target.getAttribute('data-to'), 'YYYY-MM-DD');
        let {newFrom, newTo} = this.biggestRange(from, to);
        this.preselect = {
          from: newFrom,
          to: newTo
        };
      }
    },
    onClickOutside: function() {
      this.clickStart = null;
      this.preselect = {from: null, to: null};
    }
  }
};
</script>

<style scoped>
</style>

