<template>
  <div class="empty-content">
    <div class="empty-content__placeholder loading" v-if="isLoading">
      <div class="loader"></div>
    </div>
    <div v-else>
      <div class="empty-content__icon">
        <ion-icon :name="icon"></ion-icon>
      </div>
      <div class="empty-content__message">
        <p>{{message}}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EmptyContentPlaceholder",
  props: {
    icon: String,
    message: String,
    isLoading: Boolean,
  },
}
</script>

<style scoped>

</style>
