<template>
  <div class="header-layout--sort">
    <dropdown-form-element
        name="sorting"
        :key="filterAndSort.id"
        :element="filterAndSort"
        :passed-value="matchDefaultSelectedWithOptions"
        @modified="$emit('updated:filter-and-sort', $event)"
    >
    </dropdown-form-element>
  </div>
</template>


<script>
import DropdownFormElement from "../../FormElements/DropdownFormElement.vue";
import Button from "../../styled/Button.vue";

export default {
  name: "SortBar",
  components: {Button, DropdownFormElement},
  props: {
    sortingElements: {
      type: Object,
      required: true,
    }
  },
  computed: {
    filterAndSort() {
      return this.sortingElements;
    },
    defaultSelected() {
      return this.sortingElements.defaultSelected;
    },
    matchDefaultSelectedWithOptions() {
      const options = this.filterAndSort.options;
      if (typeof this.defaultSelected === 'object' && this.defaultSelected !== null) {
        return options.find(option => {
          if (!this.defaultSelected.value) {
            return Object.keys(this.defaultSelected).every(field => {
              return option[field] === this.defaultSelected[field];
            });
          } else {
            return option.value === this.defaultSelected.value;
          }
        }) || options[0];
      }
      else {
        return options.find(option => option.value === this.defaultSelected) || options[0];
      }
    }
  },

}
</script>