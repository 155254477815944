<template>
  <div class="dropdown-container" :class="{ disabled }">
    <div
        class="form_element dropdown"
        :class="{error, 'dropdown-opened': expanded}"
        tabindex="0"
        @click="toggleDropdown"
        @blur="clickOutside"
        @focus="setFocus"
    >
      <div class="tooltip">
        <div class="select-header"
             :class="{'dropdown-label': expanded || currentOption?.text?.length > 0}"
        >
          <span v-show="element.fieldLabel && element.fieldLabel.length" class="label">{{ element.fieldLabel }}</span>
          <p class="selected-option" :class="{'dropdown-opened': expanded}" v-show="currentOption?.text?.length">
            {{ currentOption.text }}</p>
          <div v-if="currentOption?.text?.length" class="tooltiptext tooltip-top tooltip-top-icon">
            {{ currentOption.text }}</div>
        </div>
      </div>
      <div class="select-icon-container">
        <Button
            v-if="element.isFilter && currentOption && currentOption.text"
            id="clear_filter_button"
            name="close"
            variant="icon"
            @click.stop="clearOption"
            type="button"
            icon="close-outline"
        />
        <ion-icon
            v-else
            class="dropdown-indicator md hydrated"
            src="/icons/custom/chevron-down-outline.svg"
            :class="this.expanded ? 'rotate-180' : 'rotate-0'"
        />
      </div>
      <ul class="dropdown__options" v-show="expanded">
        <li class="dropdown__option"
            v-for="option in element.options"
            @click="sendData(option)"
            @blur="clickOutside"
            @focus="setFocus"
            :class="{ 'highlight-option': option.text === currentOption.text }"
        >
          {{ option.text }}
        </li>
      </ul>
    </div>
    <div v-if="disabled" class="dropdown-overlay"></div>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";

export default {
  name: "DropdownFormElement",
  components: {Button},
  data() {
    return {
      currentOption: this.passedValue,
      value: '',
      expanded: false
    }
  },
  props: {
    element: Object,
    focus: [String, Number],
    passedValue: Object,
    error: {
      type: Boolean,
      default: false
    },
    scrollIntoView: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    passedValue: function (val) {
      this.currentOption = {...val}
    },
    error(newValue) {
      this.error = newValue
    }
  },
  methods: {
    setFocus() {
      this.$emit('focus', this.element.id)
    },
    sendData(data) {
      this.currentOption = {...data}
      this.$emit('modified', data)
    },
    toggleDropdown() {
      if (this.scrollIntoView) {
        const element = this.$el

        element.parentNode.scrollTop = element.offsetTop - 2 * element.offsetHeight;
      }

      this.expanded = !this.expanded
    },
    clickOutside() {
      setTimeout( ()=> this.expanded = false, 100 ); // hack: blur event cancelled by click event leaving dropdown open in Chrome+IE
    },
    clearOption() {
      this.currentOption = { text: '' }
      this.$emit('modified', this.currentOption)
    }
  }
}
</script>

<style>
.error {
  border-color: #CC1D33 !important;
}
.dropdown-container {
  position: relative;
}

.dropdown-container.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.dropdown-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
  border-radius: inherit;
}
</style>
