import i18n from "../../vue/components/plugins/Translations.vue";

export const downloadCSV = (selectedDocumentList) => {
    const data = selectedDocumentList;
    if (!Array.isArray(data) || data.length === 0) {
        return;
    }
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    const lastSegment =  segments[segments.length - 1];
    const translatedSegment = i18n.t(`genios.downloadCSV.${lastSegment}`);
    const filename = `${formattedDate}-${translatedSegment}.csv`;

    const csvContent = createCSVContent(data);

    const bom = '\uFEFF';
    const fullContent = bom + csvContent;

    const blob = new Blob([fullContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename || 'download.csv');
    link.style.visibility = 'hidden';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const createCSVContent = (data) => {
    return data.map(item => {
        return [
            escapeCSVValue(item.displayRow?.field_H),
            escapeCSVValue(item.displayRow?.field_C),
            escapeCSVValue(item.displayRow?.field_D),
            escapeCSVValue(item.displayRow?.field_G),
            escapeCSVValue(item.displayRow?.field_B),
            escapeCSVValue(item.displayRow?.field_E),
            escapeCSVValue(item.displayRow?.field_A),
            escapeCSVValue(item.displayRow?.field_F)
        ].join(';');
    }).join('\n');
}

const escapeCSVValue = (value) => {
    return `"${(value !== undefined && value !== null ? value.toString().replace(/"/g, '""') : '')}"`;
}