<template>
  <div class="confirm_purchase" v-click-outside="onClickOutside" @keydown.esc="closeModal"
       v-if="expandedConfirmPurchase">
    <p>
      {{ $t('confirmPurchase.header') }}
    </p>

    <spinner v-if="loadingData"/>

    <div class="empty" v-else-if="!documentsList.length">
      {{ $t('confirmPurchase.empty') }}
    </div>

    <div class="empty" v-else>
      <div v-if="documentsToBuy.length">
        {{ $tc('confirmPurchase.text.buy', totalDocumentsToBuy, {totalDocuments: totalDocumentsToBuy, totalPrices}) }}
      </div>
      <div v-if="documentsToShow">
        {{ $tc('confirmPurchase.text.show', documentsToShow, {totalDocuments: documentsToShow}) }}
      </div>
    </div>

    <div class="confirm_purchase__submit_container">

      <Button
          name="close-modal"
          :text="$t('genios.sendEmail.cancelText')"
          type="button"
          @click="closeModal"
          id="close"
          variant="secondary"
          focus="close"
          :toggle-text="false"
      />
      <Button
          :text="needToBuy ? $t('confirmPurchase.button.buy') : $t('confirmPurchase.button.show')"
          variant="primary"
          :class="documentsList.length < 1 ? 'empty-list': ''"
          @click="showOrBuy"
          @keypress.enter="showOrBuy"
          :toggle-text="false"
          :disabled="isLoading || !documentsList.length || loadingData"
          ref="showOrBuyBtn"
          position="left"
      >
        <ButtonLoader
            v-if="isLoading"
            :size="$refs.showOrBuyBtn.$el.clientHeight"
        />
      </Button>
    </div>
  </div>
</template>

<script>
//jabu fido remoteAgency

import vClickOutside from "v-click-outside";

import Button from "../styled/Button.vue";
import Spinner from "../styled/Spinner.vue";
import ButtonLoader from "../styled/ButtonLoader.vue";

import {openFullArticleModal, openMultipleArticleModal} from "../../../functions/opens";
import {getDocumentPrices} from "../../../functions/services";
import {MediaType} from "../../../functions/consts";

export default {
  name: "ConfirmPurchaseComponent",
  components: {ButtonLoader, Button, Spinner},
  props: {
    sharedState: Object,
    selectedList: Array,
    expandedConfirmPurchase: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingData: true,
      isLoading: false,
      focus: null,
      documentsList: this.loadSelectedDocuments(),
      documentsToShow: 0,
      totalPrices: 0,
      totalDocumentsToBuy: 0,
      needToBuy: true,
      documentsToBuy: []
    }
  },
  async mounted() {
    this.$refs.showOrBuyBtn.$el.focus()
    await this.initialSetup()
  },
  directives: {
    clickOutside: vClickOutside?.directive
  },
  methods: {
    onClickOutside() {
      this.closeModal();
    },
    async initialSetup() {
      const {documentsList} = this;

      if (documentsList.length)
        try {

          const data = await getDocumentPrices(documentsList.map((item, index) => {
            const docId = item.docId || item.legacyDocId;
            return {
              ...item,
              database: docId.split('__')[0],
              documentId: docId
            }
          }))
          for (const key in data) {
            const document = data[key]
            if (!["anzeigen", "show"].includes(document.displayText) && !document.paid && document.price > 0) this.documentsToBuy.push(document)
          }
          this.documentsToShow = this.documentsList.length - this.documentsToBuy.length;
          this.totalDocumentsToBuy = Object.keys(this.documentsToBuy).length;
          this.totalPrices = this.toFixed(this.setTotalPrices(this.documentsToBuy))
          this.needToBuy = parseFloat(this.totalPrices) > 0;
        } catch (e) {
          console.log("ConfirmPurchaseComponent:mounted:error => ", e);
          console.dir(e)
        } finally {
          this.loadingData = false
        }
      else this.loadingData = false
    },
    closeModal() {
      this.$emit('closeWidget', false);
    },
    async showOrBuy() {
      this.isLoading = true
      const {documentsList: ids} = this

      if (ids.length === 1) {
        await openFullArticleModal(ids[0].docId);
      } else {
        await openMultipleArticleModal(ids.map(item => item.docId), {idsToBuy: this.documentsToBuy.map(item => item.docId)})
      }
      this.$emit('update-prices');
      this.isLoading = false
      this.closeModal();
    },
    loadSelectedDocuments() {
      let selectedDocuments = [];
      if (this.selectedList) {
        selectedDocuments = this.selectedList.map(item => ({
          ...item,
          docId: item.documentId,
        }));
      } else {
        selectedDocuments = JSON.parse(sessionStorage.getItem("selectedDocuments")) || [];
      }
      const filteredSelectedDocuments = selectedDocuments.filter(item => {
        const docId = item.docId;
        const database = docId.split("__")[0];
        return item.mediaType !== MediaType.VIDEOS && database !== "FIDO";
      }) || [];
      if (filteredSelectedDocuments.length)
        return filteredSelectedDocuments;
      return [];
    },
    setTotalPrices(objects) {
      let total = 0;
      for (const [_, value] of Object.entries(objects)) {
        total += value.price;
      }
      return total;
    },
    toFixed(num) {
      return Number(num).toLocaleString('de-DE', {style: 'currency', currency: 'EUR'})
    }
  }
};
</script>

<style scoped>

</style>