<template>
  <div v-if="searchPurposes.length > 0">
    <div class="gray-background">
      <div class="research-purpose">
        <div class="research-purpose-header" @click.stop="toggleResearchPurpose">
          <div class="text">{{ $t('orderingProcess.searchPurpose.field_1') }}</div>
          <ion-icon :name="isSearchPurposeExpanded ? 'chevron-up-outline' : 'chevron-down-outline'"></ion-icon>
        </div>

        <div v-if="isSearchPurposeExpanded">
          <div class="research-purpose-list">
            <div
                v-for="(purpose, index) in sortedSearchPurpose"
                :key="purpose.id"
                :class="{'first-item': index === 0}"
                class="list-item text"
            >
              <div class="list-item-content">
                <div
                    :class="{'highlighted-text': index === 0, 'primary-text': index === 0}"
                    @click.stop="setSelectedSearchPurpose(purpose.id)"
                >
                  {{ purpose.id === defaultSearchPurpose.id ? defaultSearchPurpose.bookmarkedItem : purpose.bookmarkedItem }}
                </div>
                <div v-if="purpose.id !== defaultSearchPurpose.id && index !== 0" class="icon-container" @click.stop="showDeletePurposePopup(purpose)">
                  <ion-icon name="trash-outline" class="research-icon"></ion-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="add-new" @click.stop="showAddNewPurposePopup">
            <ion-icon name="add-outline" class="research-icon"></ion-icon>
            <div class="text">{{ $t('orderingProcess.searchPurpose.addNew') }}</div>
          </div>
        </div>

        <div v-else class="collapsed-item">
          <div v-if="selectedSearchPurpose" class="highlighted-text primary-text text">
            {{ selectedSearchPurpose.bookmarkedItem || defaultSearchPurpose.bookmarkedItem }}
          </div>
        </div>
      </div>
    </div>

    <ResearchPurposePopUpComponent
        v-if="isAddNewPurposeVisible"
        :message="$t('orderingProcess.searchPurpose.message')"
        :title="$t('orderingProcess.searchPurpose.title')"
        :type="'add'"
        @close-window="closePopups"
        @purpose-added-or-deleted="handlePurposeAddedOrDeleted"
    />

    <ResearchPurposePopUpComponent
        v-if="isDeletePurposeVisible"
        :message="$t('orderingProcess.searchPurpose.deleteMessage')"
        :title="$t('orderingProcess.searchPurpose.delete')"
        :type="'delete'"
        :current-purpose="currentPurpose"
        @close-window="closePopups"
        @purpose-added-or-deleted="handlePurposeAddedOrDeleted"
    />
  </div>
</template>

<script>
import ResearchPurposePopUpComponent from "./ResearchPurposePopUpComponent.vue";
import {noneSearchPurpose} from "../../../../functions/consts";

export default {
  name: 'ResearchPurposeNavigationComponent',
  components: { ResearchPurposePopUpComponent },
  data() {
    return {
      isSearchPurposeExpanded: false,
      isAddNewPurposeVisible: false,
      isDeletePurposeVisible: false,
      currentPurpose: null,
      defaultSearchPurpose: {
        ...noneSearchPurpose,
        bookmarkedItem: this.$t('orderingProcess.searchPurpose.noPurpose')
      }
    };
  },
  computed: {
    searchPurposes() {
      return this.$store.getters.getSearchPurposeList || [];
    },
    selectedSearchPurposeId() {
      return this.$store.getters.getSelectedSearchPurposeId || this.defaultSearchPurpose.id;
    },
    selectedSearchPurpose() {
      return this.searchPurposes.find((purpose) => purpose.id === this.selectedSearchPurposeId) || this.defaultSearchPurpose;
    },
    sortedSearchPurpose() {
      const list = this.searchPurposes;
      const selectedId = this.selectedSearchPurposeId;

      return list.sort((a, b) => {
        if (a.id === selectedId) return -1;
        if (b.id === selectedId) return 1;

        if (a.id === 'none') return selectedId !== 'none' ? -1 : 0;
        if (b.id === 'none') return selectedId !== 'none' ? 1 : 0;

        return 0;
      });
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchSearchPurposeList');
  },
  methods: {
    toggleResearchPurpose() {
      this.isSearchPurposeExpanded = !this.isSearchPurposeExpanded;
    },
    async setSelectedSearchPurpose(searchPurposeId) {
      await this.$store.commit('setSelectedSearchPurposeId', searchPurposeId);
      this.isSearchPurposeExpanded = false;
    },
    showAddNewPurposePopup() {
      this.isAddNewPurposeVisible = true;
    },
    async closePopups() {
      this.isAddNewPurposeVisible = false;
      this.isDeletePurposeVisible = false;
      await this.$store.dispatch('fetchSearchPurposeList');
    },
    showDeletePurposePopup(purpose) {
      this.currentPurpose = purpose;
      this.isDeletePurposeVisible = true;
    },
    handlePurposeAddedOrDeleted() {
      this.isSearchPurposeExpanded = false;
    }
  },
};
</script>
