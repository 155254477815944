import _ from "lodash"

import {buttonLoaderTemplate} from "../components/loaders";

export const styleActionBarPosition = function (scrollTop, stopPosition) {
    let actionBarContainer = document.querySelector(".action_bar_container");
    if (scrollTop >= stopPosition) {
        actionBarContainer.classList.add('sticky_action_bar');
    } else {
        actionBarContainer.classList.remove('sticky_action_bar');
    }
}

export const styleSidenavPosition = function () {//todo: change to only searchresult

    const footer = document.querySelector("footer");
    const sideNavFilters = document.querySelector(".searchResult__filters");

    if (sideNavFilters && footer) {
        const observerCallback = changes => {
            for (const entry of changes) {
                if (!!sideNavFilters) {
                    switch (entry.target.tagName.toLowerCase()) {
                        case "footer":
                            const bottomValue = entry.isIntersecting ? ((175 * entry.intersectionRatio) + 1) : "0";
                            sideNavFilters.style.bottom = bottomValue + "px";
                            break;
                    }
                }
            }
        };

        const observer = new IntersectionObserver(observerCallback, {threshold: Array.apply(null, {length: 100}).map((n, i) => i / 100)});

        observer.observe(footer);
    }
}

export const showMoreLess = function (that) {
    const $container = that.parentElement;
    if ($container) {
        const $content = $container.querySelector('[js-handler*="showMoreLess"]');
        const $showMore = $container.querySelector(".show-more");
        const $showLess = $container.querySelector(".show-less");

        $content.classList.toggle("hideContent");
        $content.classList.toggle("showContent");
        $showMore.classList.toggle("hide");
        $showLess.classList.toggle("hide");
    }
}

export const addLoader = async (event, variant = "link", callback = () => {
}) => {
    const size = 20
    const loader = buttonLoaderTemplate({size})
    const buttonText = event.currentTarget.innerHTML    //also link, but looks like button
    switch (variant) {
        case "link":
            event.currentTarget.style.pointerEvents = "none"
            event.currentTarget.innerHTML = loader + buttonText
            break
        case "icon":
            event.currentTarget.style.pointerEvents = "none"
            event.currentTarget.innerHTML = loader
            break
        //TODO: add logic for "button"
        default:
            event.currentTarget.style.pointerEvents = "none"
            event.currentTarget.innerHTML = loader + buttonText
            break
    }

    await callback()
    event.target.classList.add("paid");
    event.target.classList.replace('button__primary', "button__secondary")
    event.target.innerHTML = buttonText
    event.target.style.pointerEvents = "auto"
}

export const setupScreenWidthObserver = () => {

    const resizeHandler = _.debounce(() => {
        window.sharedState.screenWidth = window.innerWidth
    }, 100)

    window.removeEventListener('resize', resizeHandler)
    window.addEventListener('resize', resizeHandler)
}

