var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"settings_menu_container header-layout--sorting"},[_c('Button',{attrs:{"name":"setting","text":_vm.$t('headerLayout.filterAndSort.fieldLabel'),"variant":"secondary","border":"0px solid black","decoration":"underline","text-style":400,"click-effect":false,"type":"button"},on:{"click":_vm.toggleSettingsPopup}},[_c('ion-icon',{staticClass:"settings_text",class:{ 'rotate-180': _vm.expanded },attrs:{"src":"/icons/custom/chevron-down-outline.svg"}}),_vm._v(" "),_c('span',{staticClass:"settings_icon"},[_c('ion-icon',{attrs:{"name":"settings-outline"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"button_submenu",class:{ hide: !_vm.expanded }},[_c('p',[_vm._v(_vm._s(_vm.$t("genios.customizeView.name")))]),_vm._v(" "),_c('div',{staticClass:"button_submenu__item"},[_c('div',{staticClass:"button_submenu__item__sort-bar"},[(_vm.sizeSettings.options.length > 0)?_c('sort-bar',{attrs:{"sorting-elements":{
              ..._vm.sizeSettings,
              defaultSelected: _vm.selectedSize
            }},on:{"updated:filter-and-sort":function($event){return _vm.updateSize($event)}}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"button_submenu__item"},[_c('div',{staticClass:"button_submenu__item__sort-bar"},[(_vm.sortAndOrderSettings.options.length > 0)?_c('sort-bar',{attrs:{"sorting-elements":{
              ..._vm.sortAndOrderSettings,
              defaultSelected: _vm.selectedSortAndOrder
            }},on:{"updated:filter-and-sort":function($event){return _vm.updateSortAndOrder($event)}}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"button_line"},[_c('Button',{attrs:{"name":"reset","type":"reset","variant":"secondary","text":_vm.$t('genios.close'),"toggle-text":false},on:{"click":_vm.closeMenu}}),_vm._v(" "),_c('Button',{attrs:{"name":"submit","type":"submit","variant":"primary","text":_vm.$t('genios.ok'),"toggle-text":false},on:{"click":_vm.applySettings}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }