<template>
  <div v-click-outside="onClickOutside" class="settings_menu_container header-layout--sorting">
    <Button
        name="setting"
        :text="$t('headerLayout.filterAndSort.fieldLabel')"
        variant="secondary"
        border="0px solid black"
        decoration="underline"
        @click="toggleSettingsPopup"
        :text-style="400"
        :click-effect="false"
        type="button"
    >
      <ion-icon
          src="/icons/custom/chevron-down-outline.svg"
          class="settings_text"
          :class="{ 'rotate-180': expanded }"
      />
      <span class="settings_icon">
        <ion-icon name="settings-outline"></ion-icon>
      </span>
    </Button>

    <div class="button_submenu" :class="{ hide: !expanded }">
      <p>{{ $t("genios.customizeView.name") }}</p>

      <div class="button_submenu__item">
        <div class="button_submenu__item__sort-bar">
          <sort-bar
              v-if="sizeSettings.options.length > 0"
              :sorting-elements="{
                ...sizeSettings,
                defaultSelected: selectedSize
              }"
              @updated:filter-and-sort="updateSize($event)"
          />
        </div>
      </div>

      <div class="button_submenu__item">
        <div class="button_submenu__item__sort-bar">
          <sort-bar
              v-if="sortAndOrderSettings.options.length > 0"
              :sorting-elements="{
                ...sortAndOrderSettings,
                defaultSelected: selectedSortAndOrder
              }"
              @updated:filter-and-sort="updateSortAndOrder($event)"
          />
        </div>
      </div>

      <div class="button_line">
        <Button
            name="reset"
            type="reset"
            variant="secondary"
            :text="$t('genios.close')"
            @click="closeMenu"
            :toggle-text="false"
        />
        <Button
            name="submit"
            type="submit"
            variant="primary"
            :text="$t('genios.ok')"
            @click="applySettings"
            :toggle-text="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import SortBar from "./SortBar.vue";
import vClickOutside from "v-click-outside/src";

export default {
  name: "SettingsBarComponent",
  components: { SortBar, Button },
  props: {
    settings: {
      type: Object,
      required: true,
      default: () => ({
        sizeSelected: null,
        sortAndOrder: {
          optionsArray: [],
          selectedOption: null,
        },
      }),
    },
  },
  data() {
    return {
      expanded: false,
      selectedSize: null,
      selectedSortAndOrder: null,
      sizeSettings: {
        id: "size",
        fieldLabel: this.$i18n.t(
            "meinGenios.basicSettings.searchResultSettings.size.label"
        ),
        options: [
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
        ],
      },
      sortAndOrderSettings: {
        id: "sortAndOrder",
        fieldLabel: this.$i18n.t(
            "meinGenios.basicSettings.searchResultSettings.sortAndOrder.label"
        ),
        options: (this.settings.sortAndOrder?.optionsArray || []).map((item) => ({
          ...item,
          text: this.$t(item.text),
        })),
      },
    };
  },
  mounted() {
    this.selectedSize = this.settings.sizeSelected;
    this.selectedSortAndOrder = this.settings.sortAndOrder.selectedOption;
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    applySettings() {
      this.$emit("updated:filter-and-sort", {
        size: this.selectedSize,
        sort: this.selectedSortAndOrder.sort,
        order: this.selectedSortAndOrder.order,
      });
      this.closeMenu();
    },
    toggleSettingsPopup() {
      this.expanded = !this.expanded;
    },
    closeMenu() {
      this.expanded = false;
    },
    onClickOutside() {
      this.closeMenu();
    },
    updateSize(size) {
      this.selectedSize = size.value;
    },
    updateSortAndOrder(sortAndOrder) {
      this.selectedSortAndOrder = sortAndOrder;
    }
  },
};
</script>
