// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.further-info-content {
  transition: opacity 0.3s ease;
}
#signup li {
  justify-content: flex-start !important;
}

`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/SignUp/SignupContentComponent.vue"],"names":[],"mappings":";AAuCA;EACA,6BAAA;AACA;AAEA;EACA,sCAAA;AACA","sourcesContent":["<script>\nimport axios from \"axios\";\n\nexport default {\n  name: \"SignupContentComponent\",\n  props: {\n    solutionName: {\n      type: String,\n      required: true\n    }\n  },\n  data() {\n    return {\n      signupContent : \"\"\n    };\n  },\n  created() {\n    this.fetchContent()\n  },\n  methods: {\n    async fetchContent() {\n      const response = await axios.get(`/api/signupContent?solutionName=${this.solutionName}`);\n      if(response.status === 200){\n        this.signupContent = response.data;\n      }\n    }\n  }\n}\n</script>\n\n<template>\n  <transition name=\"fade\">\n    <template v-if=\"signupContent\">\n      <div id=\"signup\" v-html=\"signupContent\" class=\"further-info-content\" ></div>\n    </template>\n  </transition>\n</template>\n\n<style>\n.further-info-content {\n  transition: opacity 0.3s ease;\n}\n\n#signup li {\n  justify-content: flex-start !important;\n}\n\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
