<template>

  <div class="extra_navigation_line">
    <div class="extra_navigation_line__right" v-if="hasPermission('show_monitoring')">
      <Button
          id="extra_navigation_line_monitoring"
          name="monitoring"
          :text="$t('genios.setupMonitoring')"
          icon="alarm-outline"
          variant="secondary"
          @click="openMonitoringForm"
          position="left"
          type="button"
      />
    </div>
  </div>

</template>

<script>

import Vue from "vue";
import i18n from "./plugins/Translations.vue";
import MonitoringForm from "./Monitoring/MonitoringForm.vue";
import Button from "./styled/Button.vue";
import {openTransparentPopupModal} from "../../functions/opens";
import {hasPermission} from "../../functions/utils/permission_utils";
import {closeFullPopupModal} from "../../functions/closing";
import {setupLoginComponent} from "../../functions/setups/vue-components";

export default {
  name: "ExtraNavigationLine",
  components: {Button},
  props: {
    sharedState: Object
  },
  computed: {
  },
  data() {
    return {
      isConfirmed: false
    };
  },
  methods: {
    openMonitoringForm: function () {
      const self = this;
      if (hasPermission("use_monitoring")) {
        self.openMonitoringComponent();
      } else {
        closeFullPopupModal(false, false);
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='web_user_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#web_user_login", "genios.login.reasons.webUserLogin", function () {
          this.$nextTick(()=>{
            self.openMonitoringComponent();
          });
        });
      }
    },
    openMonitoringComponent: function () {
      const createMonitoringForm = () => {
        const MonitoringFormClass = Vue.extend(MonitoringForm);
        const monitoringFormInstance = new MonitoringFormClass({
          i18n,
          propsData: {
            sharedState: this.sharedState
          }
        });
        const overlayContent = document.querySelector("#overlay_content");
        overlayContent.innerHTML = '<div id="mf"></div>';
        const div = document.querySelector("#mf");
        monitoringFormInstance.$mount(div);
      };
      openTransparentPopupModal(createMonitoringForm);
    },
    hasPermission: function (name) {
      return hasPermission(name)
    }
  }
};
</script>

<style scoped>

</style>
