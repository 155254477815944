// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.official-company-name[data-v-e3e60df0] {
  padding-right: 5px;
  hyphens: auto;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/video/VideoFooter.vue"],"names":[],"mappings":";AA6BA;EACA,kBAAA;EACA,aAAA;AACA","sourcesContent":["<template>\n  <div class=\"video__footer\">\n    <p><span class=\"moduleDocumentRights\">Alle Rechte vorbehalten: (c) {{ copyright }}</span></p>\n    <div class=\"video__footer__logo\">\n      <img :src=\"geniosLogo\" alt=\"\">\n      <span class=\"official-company-name\">© GBI-Genios Deutsche Wirtschaftsdatenbank GmbH</span>\n    </div>\n  </div>\n</template>\n\n<script>\nexport default {\n  name: \"VideoFooter\",\n  props: {\n    copyright: {\n      type: String,\n      required: true\n    }\n  },\n  data() {\n    return {\n      geniosLogo: `/assets/logos/logo_genios.png`,\n    }\n  }\n}\n</script>\n\n<style scoped>\n\n.official-company-name {\n  padding-right: 5px;\n  hyphens: auto;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
