<template>
  <div id="back-to-top">
    <Button
        name="scrollTop"
        variant="secondary"
        class="scroll-to-top float-right back-to-top"
        @click="scrollToTop"
        :class="{visible: visible}"
        :icon="'chevron-up-outline'"
    />
  </div>
</template>

<script>
import Button from "./Button.vue";
import eventBus from "../../../eventBus";
import scrollMixin from "../../../functions/stylization/scrollMixin";

export default {
  name: 'BackToTop',
  components: { Button },
  mixins: [scrollMixin],
  data() {
    return {
      visible: false,
      threshold: 0
    };
  },
  created() {
    this.threshold = window.innerHeight / 2 - 100;
  },
  mounted() {
    eventBus.$on('scrollEvent', this.onScroll);
  },
  beforeDestroy() {
    eventBus.$off('scrollEvent', this.onScroll);
  },
  methods: {
    onScroll(scrollPosition) {
      this.visible = scrollPosition >= this.threshold;
    },
    scrollToTop() {
      if (window.tileOverlayScrollbar) {
        window.tileOverlayScrollbar.scroll({ y: 0 }, 600);
      } else if (window.pageOverlayScrollbar) {
        window.pageOverlayScrollbar.scroll({ y: 0 }, 600);
      } else {
        const scrollingElement = document.scrollingElement || document.body || document.documentElement;
        scrollingElement.scrollTo({ top: 0, behavior: 'smooth' });

        if (scrollingElement.scrollTop !== 0) {
          scrollingElement.scrollTop = 0;
        }
        if (document.body.scrollTop !== 0) {
          document.body.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (document.documentElement.scrollTop !== 0) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }
  }
}
</script>