<template>
  <div
      class="form_element checkbox"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
      :class="(value && boldOnSelection ? 'checked' : '') + (focus === element.id ? ' focus' : '')"
      tabindex="0"
      @keydown.space.prevent="toggleCheckbox"
      @keydown.enter.prevent="toggleCheckbox"
      @focus="setFocus(element.id)"
      @blur="unsetFocus"
  >
    <label :for="'ad_fo_el_' + element.id" class="form_element__label">
      <span class="form_element__label__text" :class="error && 'error'" v-html="element.fieldLabel" />
      <input
          :id="'ad_fo_el_' + element.id"
          :name="element.field"
          :value="element.field"
          v-model="value"
          @focus="setFocus(element.id)"
          @blur="unsetFocus"
          class="form_element__input-checkbox"
          type="checkbox"
          :placeholder="focus === element.id ? '' : element.fieldLabel"
          hidden
      />
      <span class="checkbox__icon checked" v-if="value">
          <ion-icon name="checkbox-outline"></ion-icon>
        </span>
      <span class="checkbox__icon error" v-else-if="error">
          <ion-icon name="square-outline"></ion-icon>
        </span>
      <span class="checkbox__icon" v-else>
          <ion-icon name="square-outline"></ion-icon>
        </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxFormElement",
  data() {
    return {
      value: (!!this.passedValue) && !(this.passedValue === "false")
    }
  },
  props: {
    element: Object,
    passedValue: [Boolean, String],
    focus: [String, Number],
    error: {
      type: [Boolean, String],
      default: false,
    },
    boldOnSelection: {
      type: [Boolean, String],
      default: false,
    },
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("modified", newValue);
      }
    },
    passedValue(val) {
      this.value = val;
    }
  },
  computed: {
    isSafariMobile() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent) && /AppleWebKit/i.test(navigator.userAgent);
    },
  },
  methods: {
    setFocus() {
      this.$emit("focus", this.element.id);
    },
    unsetFocus() {
      this.$emit("focus", null);
    },
    toggleCheckbox() {
      this.value = !this.value;
      this.$emit("modified", this.value);
    },
    handleTouchStart(event) {
      if (this.isSafariMobile) {
        event.preventDefault();
      }
    },
    handleTouchEnd(event) {
      if (this.isSafariMobile) {
        event.preventDefault();
      }
    },
  },
};
</script>
