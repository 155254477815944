<template>
  <div class="main-container">
    <LeftNavigationDrawerComponent
        v-if="!isMobile"
        class="nav"
        :title="$t('genios.menu')"
        :menu-items="meinGeniosMenuItems"
        :is-mobile="isMobile"
    />
    <router-view></router-view>
  </div>
</template>

<script>
import LeftNavigationDrawerComponent from "./NavigationDrawers/LeftNavigationDrawerComponent.vue";

export default {
  name: 'MeinGeniosMain',
  components: {
    LeftNavigationDrawerComponent,
  },
  computed: {
    isMobile() {
      return this.windowWidth <= 850;
    },
    meinGeniosMenuItems() {
      return this.$store.getters['getMeinGeniosNavItems'];
    }
  },
  data() {
    return {
      isLoading: false,
      windowWidth: window.innerWidth,
      windowHeight: window.windowHeight,
    };
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.main-container {
  display: flex;
}

.nav {
  border-right: 1px solid var(--shade-color);
}
</style>
