<template>
  <EbibLoginComponent
      v-if="isEbibWithSubDomain"
      :shared-state="sharedState"
      :callback="callback"
      :id="id"
      :key="`ebib_${id}`"
  />
  <web-user-login-component
      v-else-if="showGroupLoginForm"
      :key="`group_${id}`"
      :shared-state="sharedState"
      :callback="callback"
      :display-reason="displayReason"
  ></web-user-login-component>
  <StandardLoginComponent
      v-else
      :display-reason="displayReason"
      :show-further-info="showFurtherInfo"
      :shared-state="sharedState"
      :callback="callback"
      :id="id"
      :key="`standard_${id}`"
  />
</template>

<script>
import loginMixin from "../../../functions/mixins/loginMixin";
import StandardLoginComponent from "./StandardLoginComponent.vue";
import EbibLoginComponent from "./EbibLoginComponent.vue";
import eventBus from "../../../eventBus";
import {checkLogin} from "../../../functions/checks";
import WebUserLoginComponent from "./WebUserLoginComponent.vue";

export default {
  name: "LoginComponent",
  mixins: [loginMixin],
  components: {WebUserLoginComponent, StandardLoginComponent, EbibLoginComponent},
  props: {
    displayReason: String,
    showFurtherInfo: {type: Boolean, defaultValue: true},
    sharedState: Object,
    callback: Function,
    id: String
  },
  data() {
    return {
      mySourcesItem: null,
      showGroupLoginForm: false,
    };
  },
  created() {
    this.showGroupLoginForm = window.sharedState.loginStatus.supportsWebUser;
  },
  async mounted() {
    await eventBus.$on("setup-my-sources-item", item => {
      this.mySourcesItem = item;
    });
    await this.$nextTick(async () => {
      window.sharedState.loginStatus = await checkLogin();
      if (window.sharedState.loginStatus.loggedIn && !window.sharedState.loginStatus.supportsWebUser) {
        this.closeModal();
        if (this.callback) {
          this.callback();
        }
      }
    });
  },
  methods: {}
};
</script>
<style scoped>
</style>
