function getPermissionMap(permission) {
    if (permission === "SHOW_PERSONALIZATION_ICONS") {
        return ["source", "show_monitoring", "bookmark", "settings"]
    }
    if (permission === "BACKOFFICE") {
        return ["demeter_settings"]
    }
    if (permission === "ALERTS") {
        return ["use_monitoring"]
    }
    if (permission === "ACCOUNT_REGISTRATION") {
        return ["can_edit_registration_data"]
    }
    if (permission === "ALLOW_PERSONALIZATION") {
        return ["can_create_watch_lists", "can_create_my_sources", "can_save_general_settings"]
    }
    return [];
}

export const hasPermission = function (name) {
    let permissionList = window.sharedState.permissions

    let i = 0;

    while (i < permissionList.length) {
        if(getPermissionMap(permissionList[i]).includes(name))
        {
            return true;
        }

        i++;
    }
    return false;
 }