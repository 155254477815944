import OverlayScrollbars from "overlayscrollbars";

import {checkFullPopupModalPosition, checkLogin} from "../checks";
import {fetchAndInsertDocument, fetchDocumentPreview, fetchFullText} from "../fetching";
import {closeAllPreviews, closeFullPopupModal, closeTransparentPopupModal} from "../closing";
import {
    setupEPaperDownloadButtonComponent,
    setupFidoList,
    setupPaymentFormComponent,
    setupQueryPopupComponent
} from "../setups/vue-components";
import {setupPreview, setupRemoteAgencyDocDownloadButton, setupTableOfContent} from "../setups";
import {changeHistory} from "../utils/history_utils";
import {getNextDocumentId, getPreviousDocumentId} from "../getters";
import Vue from "vue";
import i18n from "../../vue/components/plugins/Translations.vue";
import MultiArticle from "../../vue/components/MultiArticle.vue";
import axios from "axios";
import {PaymentMode} from "../components/payment";
import {buildHistoryState} from "../builds";
import {swipeInPreview} from "../swipe";
import browser from "browser-detect";
import {convertStringToHtml} from "../utils/string_utils";


export const openFullPopupModal = function (callback = function () {
}) {
    if (window.pageOverlayScrollbar) {
        window.pageOverlayScrollbar.options("overflowBehavior.y", "hidden");
    }
    const popup = document.querySelector('[js-handler*="openFullPopupModal"]');
    if (!!popup) {
        popup.style.visibility = 'visible';
        popup.style.opacity = '1';
        checkFullPopupModalPosition();

        document.body.style.overflow = 'hidden';

        if (!!window.popupScrollbar) {
            window.popupScrollbar.destroy();
        }
        window.popupScrollbar = OverlayScrollbars(popup, {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });

        closeTransparentPopupModal();
    }

    // Handle ESC key (key code 27)
    const escapeListener = function (e) {
        if (e.key === 'Escape') {
            closeFullPopupModal(true);
        }
    };
    document.removeEventListener('keyup', escapeListener);
    document.addEventListener('keyup', escapeListener);

    callback();
};

export const openFullArticleModal = async function (documentId, pushHistory = true, forceConfirmPrice = false, token = null, params, callback = function (){}) {
    window.sharedState.loginStatus = await checkLogin(); //always check login when opening docs
    if (window.sharedState.page !== "document") {
        changeHistory({document: documentId}, pushHistory);
    }

    await fetchAndInsertDocument(documentId, getNextDocumentId, getPreviousDocumentId, false, token, {forceConfirmPrice: !!forceConfirmPrice, ...params})
    //  INFO: fix opening of popUp in FUNKTIONEN-11442
    // openFullPopupModal(() => {})

    if (callback) {
        callback()
    }
};

export const openMultipleArticleModal = async function (idsToOpen = [], {idsToBuy, params = {}} = {}) {
    window.sharedState.loginStatus = await checkLogin(); //always check login when opening docs

    if (!idsToOpen.length) idsToOpen = JSON.parse(sessionStorage.getItem('selectedDocuments')).map(item => item.docId);

    const ids = _.union(idsToOpen, idsToBuy);// isUndefined(idsToBuy) ? idsToOpen : idsToBuy

    //Can not use changeHistory method because of quantity of changes that need to be done
    const queryParams = new URLSearchParams(window.location.search);
    const idsFromQueryParams = queryParams.getAll('document');
    if (!idsFromQueryParams.length) {
        idsToOpen.forEach(id => queryParams.append('document', id));
        window.history.pushState(buildHistoryState(), null, "?" + queryParams.toString());
    }

    try {
        const {data} = await axios.post(`/api/retrieveDocuments`, {ids, ...params});

        if (data.code) {
            await setupPaymentFormComponent(data, ids, PaymentMode.multiDocuments, null, {
                ...params
            });
        } else {
            openFullPopupModal(() => {
                let docBody = document.querySelector("#modal_content");
                docBody.innerHTML = "<multi-article :article-ids='" + JSON.stringify(idsToOpen) + "'/>";
                new Vue({
                    name: "multi-article",
                    components: {
                        multiArticle: MultiArticle
                    },
                    i18n,
                    el: docBody
                });
            });
        }
    } catch (e) {
        console.log("MultiArticle:tryToBuyDocuments:error => ", e);
        console.dir(e);
    }
};

export const openPreview = function (docId, pushHistory = true, fromFullPreview = false) {
    closeAllPreviews();
    changeHistory({preview: docId}, pushHistory);
    const previewDiv = document.querySelector('#previewDiv');
    const previewElemToOpen = previewDiv.querySelector('#' + docId.replaceAll(/\+/g, '\\+') + '[js-handler*="openPreview"]');


    if (!!previewElemToOpen) {
        if (fromFullPreview) {
            previewElemToOpen.classList.add('full_preview');
            // previewElemToOpen.setAttribute("style","height:100vh;")
        }
        previewElemToOpen.classList.remove('width_zero');
        if (!previewElemToOpen.querySelector(".media_preview__main_content")) { //if not loaded, add loading div
            previewElemToOpen.querySelector('#' + docId + "__content").innerHTML = "<div class='loading' style='width:100%'><div class='loader'></div></div>";
        }

        if (browser().mobile) {

            const tile = document.querySelector(`.media_tile[data-document-id=${docId}]`);
            // previewElemToOpen.classList.add("media_preview__mobile")
            window.scroll(0, tile.offsetTop - 20);
            previewElemToOpen.querySelector('.media_preview__button_bar');
            const showMoreClickHandler = (event) => {
                event.preventDefault();
                previewElemToOpen.classList.add('full_preview');
                setupPreview(previewElemToOpen, docId);
            };
            const buttonShowMore = previewElemToOpen.querySelector('[js-handler*="extend_preview_mobile"]');


            if (buttonShowMore) {
                buttonShowMore.removeEventListener('click', showMoreClickHandler);
                buttonShowMore.addEventListener('click', showMoreClickHandler);
            }

            previewElemToOpen.removeEventListener('swiped', swipeInPreview);
            previewElemToOpen.addEventListener('swiped', swipeInPreview);
        }

        setupFullPreview(previewElemToOpen, docId);

    }
};

export const openTransparentPopupModal = function (callback = function () {
}) {
    if (window.pageOverlayScrollbar) {
        window.pageOverlayScrollbar.options("overflowBehavior.y", "hidden");
    }
    const popup = document.querySelector("#transparentPopUp");
    if (!!popup) {
        popup.style.height = "100vh";
        popup.style.top = "0";
        popup.style.visibility = 'visible';
        popup.style.opacity = '1';

        document.body.style.overflow = 'hidden';
        if (!!window.popupScrollbar) {
            window.popupScrollbar.destroy();
        }
        window.popupScrollbar = OverlayScrollbars(popup, {
            scrollbars: {
                autoHide: "move",
                clickScrolling: true
            }
        });

        const buttonClose = popup.querySelector("button")

        buttonClose.addEventListener("click", () => {
            closeTransparentPopupModal();
        });

        const overlayContent = popup.querySelector("#overlay_content");
        if (!!overlayContent) {
            overlayContent.addEventListener("click", (e) => {
                e.stopPropagation();
            });
        }
    }

    // Handle ESC key (key code 27)
    const escapeListener = function (e) {
        if (e.key === 'Escape') {
            closeTransparentPopupModal();
        }
    };
    document.removeEventListener('keyup', escapeListener);
    document.addEventListener('keyup', escapeListener);

    callback();
};

export const setupFullPreview = (previewElemToOpen, docId) => {
    const previewDiv = document.querySelector('#previewDiv');
    if (document.querySelector('.main')) {
        document.querySelector('.main').classList.add('preview_opened');
    }
    setupEPaperDownloadButtonComponent(previewElemToOpen);
    setupRemoteAgencyDocDownloadButton(previewElemToOpen);


    if (previewElemToOpen.querySelector(".media_preview__main_content")) {//means that it's not an eBook or has already been loaded

        if (previewElemToOpen.getAttribute('data-database') === 'FIDO') {
            setupFidoList(previewElemToOpen, docId);
            const fidoBuyButton = previewElemToOpen.querySelector('.fido__button');
            if (fidoBuyButton) {
                fidoBuyButton.classList.remove('hide');
            }

        }

        if (window.pageType === 'browse') {
            setupTableOfContent(previewElemToOpen, docId);
        } else if (window.pageType === 'searchResult') {
            const textDiv = previewElemToOpen.querySelector(".media_preview__full_doc__teaser");
            if (textDiv) {
                const documentId = textDiv.getAttribute("documentId");
                fetchFullText(documentId);
            }
        }
        setupPreview(previewElemToOpen, docId);
    } else {
        fetchDocumentPreview(docId, previewDiv, previewElemToOpen).then();
    }
};

export const openRegistrationPage = () => {
    const {isDark, redirectUrl} = getParams();
    let urlParams = new URLSearchParams();
    urlParams.append("isDark", isDark.toString())
    urlParams.append("redirectUrl", redirectUrl)
    window.location.assign(`/externalPopup/register?${urlParams.toString()}`);
};

export const openPasswordForgottenPage = () => {
    window.location.assign(getPasswordForgottenPath());
};

export const getPasswordForgottenPath = () => {
    const {isDark, redirectUrl} = getParams();
    let urlParams = new URLSearchParams();
    urlParams.append("isDark", isDark.toString());
    urlParams.append("redirectUrl", redirectUrl);
    return `/externalPopup/passwordForgotten?${urlParams.toString()}`;
}

export const getParams = () => {
    const mode = localStorage.getItem('mode');
    const {pathname, search} = window.location;
    let currentSearchParams = new URLSearchParams(search);
    let redirectSearch = search;
    if (currentSearchParams.has('targetUrl')) {
        var targetUrl = currentSearchParams.get('targetUrl');
        redirectSearch = targetUrl === '/' ? '' : currentSearchParams.get('targetUrl');
    }
    const redirectUrl = pathname + redirectSearch;
    return {
        isDark: mode === "dark",
        redirectUrl
    };
};


export const openQueryPopup = (docBody) => {
    const queryLinks = (docBody || document).querySelectorAll('[js-handler="setupQueryPopup"]') || []

    const openComponent = (event) => {
        event.preventDefault()
        openTransparentPopupModal()
        const content = document.querySelector('#overlay_content')
        content.innerHTML = '<div id="queryPopup"></div>'

        const popuplabel = convertStringToHtml(event.currentTarget.dataset.popuplabel)

        const dbShortcuts = event.currentTarget.getAttribute("dbshortcut")?.split(",") || [],
            query = event.currentTarget.dataset.query,
            label = popuplabel.textContent

        setupQueryPopupComponent(query, label, dbShortcuts)
    }

    if (queryLinks.length > 0) {
        queryLinks.forEach(link => {
            link.removeEventListener('click', openComponent)
            link.addEventListener('click', openComponent)
        })
    }
}
