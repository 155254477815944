<template>
  <div class="form_element"
       :class="formElementClass">

    <div class="form_element__prefix_icon"
         v-show="prefixIconName">
      <ion-icon :name="prefixIconName"></ion-icon>
    </div>

    <div class="form_element__text_input_container">
      <label
          v-if="showLabelElement"
          :for="'ad_fo_el_' + element.id"
          class="form_element__label">
        <span class="form_element__label__text">{{ element.fieldLabel }}</span>
      </label>
      <input
          ref="input"
          :id="'ad_fo_el_' + element.id"
          :name="element.field"
          v-model="value"
          @focus="setFocus()"
          @blur="unsetFocus()"
          class="form_element__input-text"
          :aria-label="element.fieldLabel"
          :type="isPasswordVisible ? 'text' : htmlInputFieldType"
          :placeholder="focus === element.id ? '' : element.fieldLabel"
          :autocomplete="autocomplete"
          :key="isPasswordVisible ? 'text' : 'password'"
          v-on="$inputListeners"
      />
    </div>

    <div
        v-if="htmlInputFieldType === 'password'"
        class="form_element__toggle-visibility"
        @click="togglePasswordVisibility"
    >
      <ion-icon :name="isPasswordVisible ? 'eye-off-outline' : 'eye-outline'"></ion-icon>
    </div>

    <Button
        tabIndex="-1"
        v-if="value"
        type="button"
        @click="value = null;$refs.input.focus()"
        icon="close-outline"
        variant="icon"
        border="none"
    />
  </div>
</template>

<script>
import Button from "../styled/Button.vue";

export default {
  name: "TextFormElement",
  components: {Button},
  data() {
    return {
      value: this.passedValue,
      showLabelElement: !this.hideLabel,
      isPasswordVisible: false,
    }
  },
  props: {
    element: Object,
    passedValue: String,
    focus: [String, Number],
    error: Boolean,
    hideLabel: Boolean,
    prefixIconName: String,
    htmlInputFieldType: {
      type: String,
      default: "text"
    },
    autocomplete: {
      type: String,
      default: "off"
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.focus === this.element.id) {
        this.focusInput();
      }
    });
  },
  computed: {
    formElementClass: function () {
      return this.element.fieldType.toLowerCase() +
          ' ' + (this.focus === this.element.id ? 'focus' : '') +
          ' ' + (this.value ? 'filled' : '') +
          ' ' + (this.prefixIconName ? 'prefixIcon' : '') +
          ' ' + (this.error ? 'form_element-error' : '');
    },
    $inputListeners() {
      const {focus, blur, ...listeners} = this.$listeners // exclude `click`-listener
      return listeners
    },
  },
  watch: {
    value: function (val) {
      this.$emit("modified", val)
    },
    passedValue: function (val) {
      this.value = val;
    }
  },
  methods: {
    setFocus: function () {
      this.$emit("focus", this.element.id);
    },
    unsetFocus: function () {
      this.$emit("focus", null);
    },
    focusInput() {
      this.$refs.input.focus()
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    }
  }
}
</script>

<style scoped>
.prefixIcon {
  padding-left: 10px;
}

.form_element__toggle-visibility {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  color: var(--shade-dark);
}

.form_element__toggle-visibility ion-icon {
  font-size: 1.2em;
}
</style>
