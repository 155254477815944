import {deleteSearchHistory, fetchUserSearchHistory} from "../../fetching";

const itemsPerPage = 50;

const initialState = {
    searchHistory: [],
    selectedSearchHistory: [],
    totalNumberOfPages: 0,
    currentPage: 1,
    loading: true,
    error: false,
    errorMessage: "",
    ...window.sessionVariables?.['vuexState']?.searchHistoryModule || {}
}

const searchHistoryModule =  {
    namespaced: true,
    state: {...initialState},
    getters: {
        getSearchHistory: (state) => (pageNumber = state.currentPage) => {
            const startIndex = (pageNumber - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            return state.searchHistory.slice(startIndex, endIndex) || [];
        },
        getSelectedSearchHistory: state => state.selectedSearchHistory,
        getTotalNumberOfPages: state => state.totalNumberOfPages,
        getCurrentPage: state => state.currentPage,
        getLoading: state => state.loading,
    },
    mutations: {
        setSearchHistory(state, searchHistory) {
            state.searchHistory = searchHistory;
        },
        setSelectedSearchHistory(state, selected) {
            state.selectedSearchHistory =  Array.from(new Set([...selected]));
        },
        setTotalNumberOfPages(state, payload) {
            state.totalNumberOfPages = payload;
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload;
        },
        resetState(state) {
            Object.assign(state, initialState);
        }
    },
    actions: {
        async fetchSearchHistory({ commit }) {
            commit('setLoading', true);
            try {
                const savedValue = await fetchUserSearchHistory();
                const items = savedValue || [];
                commit('setSearchHistory', items);
                const totalItems = items.length;
                const totalPages = Math.ceil(totalItems / itemsPerPage);

                commit('setTotalNumberOfPages', totalPages);
            } catch (e) {
                commit('setSearchHistory', []);
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteSearchHistory({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await deleteSearchHistory(ids);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        }
    },
}
export default searchHistoryModule;