import {replaceBrowseResult, replaceSearchResult} from "../replacing";

export const toggleSearchResultActiveFilter = function (name, type, sharedState = window.sharedState, replaceResult = true) {
    shiftActiveFilters(name, type, sharedState)
    if (replaceResult) {
        replaceSearchResult();
    }
}

export const toggleBrowseResultActiveFilter = function (name, type, sharedState = window.sharedState, replaceResult = true) {
    if (replaceResult) {
        window.sharedState.offset = null;
        replaceBrowseResult();
    }
}

const shiftActiveFilters = (name, type, sharedState = window.sharedState) => {
    let tempActiveFilters = sharedState.activeFilters[type];
    if (tempActiveFilters === undefined) {
        tempActiveFilters = [name];
    } else if (tempActiveFilters.includes(name)) {
        const index = tempActiveFilters.indexOf(name);
        if (index > -1) {
            tempActiveFilters.splice(index, 1);
        }
    } else {
        tempActiveFilters.push(name);
    }
    sharedState.activeFilters[type] = [...tempActiveFilters] //copy needed for reaction
    sharedState.offset = 0; // reset for getting new results
}