// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.error[data-v-286c7a16] {
  color: #CC1D33;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/styled/Error.vue"],"names":[],"mappings":";AAiBA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACA","sourcesContent":["<template>\n  <div class=\"error\" v-html=\"error\"/>\n</template>\n\n<script>\nexport default {\n  name: \"Error\",\n  props: {\n    error: {\n      type: String,\n      required: true\n    }\n  }\n}\n</script>\n\n<style scoped>\n.error {\n  color: #CC1D33;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 16px;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
