<template>
  <div>
    <div class="header-layout--action-bar--container">
      <template v-for="button in sortedVisibleButtons">
      <div class="tooltip tooltip-icon">
        <Button
            :key="button.name"
            :name="button.name"
            variant="icon"
            :icon="button.icon"
            :aria-label="button.name"
            @click="button.clickFunction"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{ $t(`genios.tooltip.button.${button.name}`) }}
        </div>
      </div>
      </template>
    </div>
    <InfoOverlayComponent
        v-if="showInfoOverlayComponent"
        :type="modalType"
        :modal-data="modalContent"
        :initial-input-value="inputInitialValue"
        @primary-action="modalContent.primaryAction"
        @secondary-action="modalContent.secondaryAction"
    />
    <GenericModal
        v-if="showConfirmationModal"
        :type="modalType"
        :modal-data="modalContent"
        @primary-action="modalContent.primaryAction"
        @secondary-action="modalContent.secondaryAction"
    />
    <SendEmailComponent
        v-if="expandedBookmarkListEmail"
        style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);"
        :expanded-send-email="expandedBookmarkListEmail"
        type="bookmark-list"
        :ids="[]"
        :documents="getDocumentToSend()"
        :class="{'right-unset': !isItemAction}"
        @closeWidget="closeSendEmail('bookmark-list')"
        @update-form="handleBookmarkEmailSubmit"
    />
    <SendEmailComponent
        v-if="expandedGeneralEmail"
        class="send-email-component"
        :expanded-send-email="expandedGeneralEmail"
        type="general"
        :ids="[]"
        :documents="getDocumentToSend()"
        :class="{'right-unset': !isItemAction}"
        @closeWidget="closeSendEmail('general')"
    />
    <ConfirmPurchaseComponent
        v-if="expandedConfirmPurchase"
        :expanded-confirm-purchase=expandedConfirmPurchase
        :selected-list="selectedList"
        @closeWidget="closeConfirmPurchase"
        @update-prices="$emit('update-prices', selectedList.filter(i => i).map(i => i.documentId))"
    />
    <WatchlistAddComponent
        v-if="showWatchlistDialog"
        :shared-state="sharedState"
        :document-ids="getDocumentToAdd()"
        @closeWidget="closeWatchlistDialog"
        @documents-added="$emit('update-list')"
    />
    <WatchlistDeleteDialogComponent
        v-if="showDeleteDialog"
        :shared-state="sharedState"
        :document-id="this.selectedList.map(item => item.bookmarkId)"
        :delete-from-list="true"
        :document-title="getDocumentTitles()"
        @closeWidget="closeDeleteDialog"
        @documents-deleted="bookmarksDeleted()"
    />
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import {openTransparentPopupModal} from "../../../../functions/opens";
import {setupLoginComponent, setupSendEmailComponent} from "../../../../functions/setups/vue-components";
import {closeTransparentPopupModal} from "../../../../functions/closing";
import SendEmailComponent from "../../ActionBar/SendEmailComponent.vue";
import ConfirmPurchaseComponent from "../../ActionBar/ConfirmPurchaseComponent.vue";
import WatchlistDeleteDialogComponent from "../../Watchlist/WatchlistDeleteDialogComponent.vue";
import WatchlistAddComponent from "../../Watchlist/WatchlistAddComponent.vue";
import {checkLogin} from "../../../../functions/checks";
import {downloadCSV} from "../../../../functions/utils/download_documents_as_CSV";
import {convertToZulu} from "../../../../functions/utils/date_utils";
import GenericModal from "../../common/GenericModal.vue";
import {
  addBookmarks,
  deleteBookmarks,
  fetchBookmarksFromApi,
  fetchDocumentsByIds,
  sendBookmarkListEmailApi
} from "../../../../functions/fetching";
import InfoOverlayComponent from "../../common/InfoOverlayComponent.vue";

export default {
  name: "ActionBar",
  components: {
    InfoOverlayComponent,
    WatchlistAddComponent,
    GenericModal,
    WatchlistDeleteDialogComponent,
    ConfirmPurchaseComponent,
    SendEmailComponent,
    Button
  },
  data() {
    return {
      buttons: [
        { name: 'eye', icon: 'eye-outline', clickFunction: this.openDocuments },
        { name: 'open', icon: 'search-outline', clickFunction: this.openLink },
        { name: 'download', icon: 'download-outline', clickFunction: this.downloadDocuments },
        { name: 'mail', icon: 'mail-outline', clickFunction: this.sendEmailWithDocuments },
        { name: 'print', icon: 'print-outline', clickFunction: this.printDocuments },
        { name: 'move', icon: 'move', clickFunction: this.moveBookmarksToBookmarkList },
        { name: 'bookmark', icon: 'bookmark-outline', clickFunction: this.bookmarkDocuments },
        { name: 'remove-bookmark', icon: 'trash-outline', clickFunction: this.deleteBookmarksFromList},
        { name: 'remove', icon: 'trash-outline', clickFunction: this.deleteDocumentsFromList},
        { name: 'remove-history', icon: 'trash-outline', clickFunction: this.deleteSearchHistoryFromList},
        { name: 'clear', icon: 'trash-outline', clickFunction: () => this.showInfoOverlay('clearConfirmation') },
        { name: 'edit', icon: 'create-outline', clickFunction: () => this.showInfoOverlay('editConfirmation') },
        { name: 'delete-list', icon: 'close-circle-outline', clickFunction: () => this.showInfoOverlay('deleteConfirmation') },
        { name: 'mail-list', icon: 'mail-outline', clickFunction: this.sendBookmarkListByEmail },
        { name: 'download-list', icon: 'download-outline', clickFunction: this.downloadBookmarkList },
      ],
      expandedSendEmail: false,
      expandedConfirmPurchase: false,
      showWatchlistDialog: false,
      showDeleteDialog: false,
      sharedState: window.sharedState,
      showDeleteConfirmation: false,
      selectedListToDelete: null,
      showConfirmationModal: false,
      modalContent: {},
      modalType: '',
      expandedGeneralEmail: false,
      expandedBookmarkListEmail: false,
      showInfoOverlayComponent: false,
    };
  },
  props: {
    actionBarButtons: Array,
    isItemAction: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedList: Array,
    allDocumentsOnPage: {
      type: Array,
      required: false,
      default: null
    },
    inputInitialValue: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    sortedVisibleButtons() {
      return this.buttons
        .filter(button => this.actionBarButtons.includes(button.name))
        .sort((a, b) => {
          return this.actionBarButtons.indexOf(a.name) - this.actionBarButtons.indexOf(b.name);
        });
    },
    count() {
      return this.selectedList.length;
    }
  },
  methods: {
    openDocuments() {
      this.expandedConfirmPurchase = true;
    },
    openLink() {
      window.location.href = this.selectedList.map(item => item.analysis?.url)?.[0];
    },
    downloadDocuments() {
      downloadCSV(this.selectedList);
    },
    async downloadBookmarkList() {
      const sort = this.$store.getters['bookmarksModule/getSortBy'];
      const order = this.$store.getters['bookmarksModule/getSortOrder'];
      const id = String(this.selectedList[0].id);

      await fetchBookmarksFromApi({ id, offset: 0, size: 100, sort, order })
          .then(async result => {
            const bookmarkedItemsArray = result.content.map(item => item.bookmarkedItem);
            const res = await fetchDocumentsByIds(bookmarkedItemsArray)
            downloadCSV(res.uniqueData)
          })
          .catch(error => {
            console.error("Error fetching bookmarks:", error);
          });
    },
    getDocumentTitles() {
      return this.selectedList.map(item => item.documentTitle).slice(0, 5).join(' ') + ' ...';
    },
    bookmarksDeleted() {
      this.$store.commit('bookmarksModule/setCurrentPage', 1);
      this.$emit('update-list')
    },
    getDocumentToAdd() {
      return this.selectedList.map(item => {
        const docToAdd = {
          bookmarkedItem: item.documentId,
        }
        if (item.displayRow && item.displayRow.field_H) {
          return {
            ...docToAdd,
            itemDate: convertToZulu(item.displayRow.field_H)
          }
        }
        return docToAdd;
      });
    },
    closeSendEmail(type) {
      if (type === 'bookmark-list') {
        this.expandedBookmarkListEmail = false;
      } else {
        this.expandedGeneralEmail = false;
      }
    },
    handleBookmarkEmailSubmit(formData) {
      const emailSend = {
        bookmarkListId: this.selectedList[0]?.id,
        emailFrom: formData.emailFrom,
        message: formData.message,
        emailsTo: formData.emailsTo,
        sort: this.$store.getters['bookmarksModule/getSortBy'],
        order: this.$store.getters['bookmarksModule/getSortOrder'],
        size: 100,
      };
      sendBookmarkListEmailApi(emailSend)
          .then(response => {
            console.log("Bookmark list email sent successfully:", response);
          })
          .catch(error => {
            console.error("Failed to send bookmark list email:", error);
          });
    },
    async sendBookmarkListByEmail () {
      const {loggedIn} = await checkLogin();
      if (loggedIn) {
        this.expandedBookmarkListEmail = !this.expandedBookmarkListEmail;
      }  else {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#embedded_login", "genios.login.reasons.login", function () {
          closeTransparentPopupModal();
          setupSendEmailComponent(this.getDocumentToSend(), emailBtn.parentNode.parentNode, true);
        });
      }
    },
    async sendEmailWithDocuments({currentTarget: emailBtn}) {
      const {loggedIn} = await checkLogin();
      if (loggedIn) {
        this.expandedGeneralEmail = !this.expandedGeneralEmail;
      } else {
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#embedded_login", "genios.login.reasons.login", function () {
          closeTransparentPopupModal();
          setupSendEmailComponent(this.getDocumentToSend(), emailBtn.parentNode.parentNode, true);
        });
      }
    },
    printDocuments() {
      window.print();
    },
    moveBookmarksToBookmarkList() {
      if (this.selectedList && this.selectedList.length > 0) {
        const selectedBookmarkList = this.$store.getters.getSelectedBookmarkList;
        const bookmarkLists = this.$store.getters.getBookmarkList;
        const dropdownElements = bookmarkLists.filter(bookmark => bookmark.id !== selectedBookmarkList.id);
        this.modalContent = {
          title: this.$t(`meinGenios.bookmarks.move.title`),
          description: this.count > 1 ? this.$t(`meinGenios.bookmarks.move.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.bookmarks.move.description`, {count: this.count}),
          primaryButtonText: this.$t(`meinGenios.bookmarks.move.confirm`),
          secondaryButtonText: this.$t(`meinGenios.bookmarks.move.cancel`),
          dropdownElements: dropdownElements.map(bookmark => {
            return {
              text: bookmark.name,
              value: bookmark.id,
            }
          }),
          primaryAction: this.confirmMoveBookmarks,
          secondaryAction: this.cancelPopup,
        };
        this.modalType = 'dropdown'
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.bookmarks.move.title`),
          description: this.$t(`meinGenios.bookmarks.move.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.bookmarks.move.cancel`),
          primaryAction: this.cancelPopup,
          secondaryAction: this.cancelPopup,
        };
      }
      this.showConfirmationModal = true;
    },
    confirmMoveBookmarks(newBookmarkList) {
      this.moveBookmarks(newBookmarkList);
      this.showConfirmationModal = false;
    },
    async moveBookmarks(newBookmarkList) {
      const ids = this.selectedList.map(item => item.bookmarkId);
      const docsToAdd = this.selectedList.map(doc => {
        const docToAdd = {
          bookmarkedItem: doc.documentId,
        }
        if (doc.itemDate) {
          return {
            ...docToAdd,
            itemDate:convertToZulu(doc.itemDate)
          }
        }
        return docToAdd;
      });
      if (ids.length > 0) {
        deleteBookmarks(ids).then(async () => {
          await addBookmarks([newBookmarkList.value], docsToAdd);
          this.$store.commit('bookmarksModule/setCurrentPage', 1);
          this.$emit('update-list');
        }).catch(errors => {
          this.errorCode = "genios.watchlist.errorDelete";
          console.error("Move-Watchlist-Bookmarks " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
        });
      } else {
        console.log("No selected documents to move.");
      }
    },
    bookmarkDocuments() {
      this.showWatchlistDialog = true;
    },
    deleteBookmarksFromList() {
      this.showDeleteDialog = true;
    },
    deleteDocumentsFromList() {
      this.modalContent = {
        title: this.$t(`meinGenios.documentHistory.delete.title`),
        description: this.count > 1 ? this.$t(`meinGenios.documentHistory.delete.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.documentHistory.delete.description`, {count: this.count}),
        primaryButtonText: this.$t(`meinGenios.documentHistory.delete.confirm`),
        secondaryButtonText: this.$t(`meinGenios.documentHistory.delete.cancel`),
        primaryAction: this.confirmDeleteDocuments,
        secondaryAction: this.cancelPopup,
      };

      this.showConfirmationModal = true;
    },
    deleteSearchHistoryFromList() {
      if (this.selectedList && this.selectedList.length > 0) {
        this.modalContent = {
          title: this.$t(`meinGenios.searchHistory.delete.title`),
          description: this.count > 1 ? this.$t(`meinGenios.searchHistory.delete.descriptionPlural`, {count: this.count}) : this.$t(`meinGenios.searchHistory.delete.description`, {count: this.count}),
          primaryButtonText: this.$t(`meinGenios.searchHistory.delete.confirm`),
          secondaryButtonText: this.$t(`meinGenios.searchHistory.delete.cancel`),
          primaryAction: this.confirmDeleteSearchHistory,
          secondaryAction: this.cancelInfoOverlay,
        };
      } else {
        this.modalContent = {
          title: this.$t(`meinGenios.searchHistory.delete.title`),
          description: this.$t(`meinGenios.searchHistory.delete.emptySelection`),
          secondaryButtonText: this.$t(`meinGenios.searchHistory.delete.cancel`),
          primaryAction: this.cancelInfoOverlay,
          secondaryAction: this.cancelInfoOverlay,
        };
      }

      this.showInfoOverlayComponent = true;
    },
    showInfoOverlay(type) {
      let primaryAction;
      if (type === 'deleteConfirmation') {
        const hasStandardMerkliste = this.selectedList.some(item => item.name === "Standard-Merkliste");
        if (hasStandardMerkliste) {
          this.$emit('standard-merkliste-selected');
          return;
        }
        primaryAction = this.confirmDeleteBookmarkList;
      } else if (type === 'clearConfirmation') {
        primaryAction = this.confirmClearBookmarkList;
      } else if (type === 'editConfirmation') {
        primaryAction = this.confirmEditBookmarkList;
      }

      this.modalContent = {
        title: this.$t(`genios.bookmarkList.${type}.title`),
        description: type !== 'editConfirmation' ? this.$t(`genios.bookmarkList.${type}.description`) : null,
        primaryButtonText: this.$t(`genios.bookmarkList.${type}.confirm`),
        secondaryButtonText: this.$t(`genios.bookmarkList.${type}.cancel`),
        inputPlaceholder: this.$t(`genios.bookmarkList.${type}.description`),
        primaryAction: primaryAction,
        secondaryAction: this.cancelInfoOverlay,
      };

      this.showInfoOverlayComponent = true;

      this.modalType = type === 'editConfirmation' ? 'input' : '';
    },
    cancelPopup() {
      this.showConfirmationModal = false;
    },
    cancelInfoOverlay() {
      this.showInfoOverlayComponent = false;
    },
    confirmDeleteBookmarkList() {
      this.deleteBookmarkList();
      this.showInfoOverlayComponent = false;
    },
    confirmClearBookmarkList() {
      this.clearBookmarkList();
      this.showInfoOverlayComponent = false;
    },
    confirmEditBookmarkList(newName) {
      if (this.selectedList.length === 1) {
        const id = this.selectedList[0].id;
        this.editBookmarkList(id, newName);
      } else {
        console.log("No list selected or multiple lists selected.");
      }
      this.showInfoOverlayComponent = false;
    },
    confirmDeleteSearchHistory() {
      this.deleteSearchHistory();
      this.showInfoOverlayComponent = false;
    },
    async deleteSearchHistory() {
      const ids = this.selectedList.map(item => item.id);
      if (ids.length > 0) {
        await this.$store.dispatch('searchHistoryModule/deleteSearchHistory', ids);
        this.$emit('update-list');
      } else {
        console.log("No selected search to delete.");
      }
    },
    confirmDeleteDocuments() {
      this.deleteDocuments();
      this.showConfirmationModal = false;
    },
    async deleteDocuments() {
      const ids = this.selectedList.map(item => item.bookmarkId);
      if (ids.length > 0) {
        await this.$store.dispatch('documentHistoryModule/deleteDocumentHistory', ids);
        this.$emit('update-list');
      } else {
        console.log("No selected documents to delete.");
      }
    },
    async deleteBookmarkList() {
      const ids = this.selectedList.map(item => item.id);
      if (ids.length > 0) {
        await this.$store.dispatch('deleteBookmarkList', ids);
      } else {
        console.log("No selected bookmark lists to delete.");
      }
    },
    async clearBookmarkList() {
      const ids = this.selectedList.map(item => item.id);
      if (ids.length > 0) {
        await this.$store.dispatch('clearBookmarkList', ids);
      } else {
        console.log("No selected bookmark lists to clear.");
      }
    },
    async editBookmarkList(id, name) {
      if (name) {
        await this.$store.dispatch('updateBookmarkList', {id: id, name: name});
      } else {
        console.log("No name given to update.");
      }
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    closeWatchlistDialog() {
      this.showWatchlistDialog = false;
    },
    closeConfirmPurchase() {
      this.$nextTick(this.expandedConfirmPurchase = false);
    },
    getDocumentToSend() {
      if (this.selectedList.length > 0) {
        return this.selectedList.map(item => {
          return {
            documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
          }
        });
      }
      return this.allDocumentsOnPage?.length ? this.allDocumentsOnPage.map(item => {
        return {
          documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
        };
      }) : [];
    },
  }
}
</script>

<style scoped>

</style>