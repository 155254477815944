<template>
  <div
      class="form_element"
      :class="element.fieldType.toLowerCase() +
                ' ' + (inFocus ? 'focus' : '') +
                ' ' + ((value.to || value.from) ? 'filled' : '')"
      @focusin="setFocus()"
      @focusout="unsetFocus()">

    <!--suppress XmlInvalidId -->
    <label :for="'ad_fo_el_' + element.id"
           class="form_element__label">
      <span>{{element.fieldLabel}}</span>
    </label>
    <div class="form_element__range_line">
      <div
          class="form_element__range_line__element"
          :class="value.from ? 'form_element__range_line__element-filled' : ''"
      >
        <label :for="'ad_fo_el_' + element.id + '_from'"
               class="form_element__label-range">
          <span>{{ $t("genios.advancedSearch.range.from") }}</span>
        </label>
        <div class="form_element__range_input_container">
          <input
              ref="from"
              :id="'ad_fo_el_' + element.id + '_from'"
              :name="element.field"
              v-model="from"
              class="form_element__input-text"
              type="text"
              :inputmode="inputType === 'date' ? 'search' : ''"
              :autocomplete="inputType === 'date' ? 'off' : 'on'"
              :placeholder="((focus === element.id && subFocus === 'from') || value.from) ? '' : ($t('genios.advancedSearch.range.from'))"
              @blur="verifyFrom()"
              @focusin="setSubFocus('from')"
              @focusout="setSubFocus()"
              @keypress.enter="handleEnterFrom"
          >
          <button type="button"
              v-if="from"
              @click="clearFrom()"
          ><ion-icon name="close-outline"/></button>
        </div>
      </div>
      <div
          class="form_element__range_line__element"
          :class="value.to ? 'form_element__range_line__element-filled' : ''"
      >
        <label :for="'ad_fo_el_' + element.id + '_to'"
               class="form_element__label-range">
          <span>{{ $t("genios.advancedSearch.range.to") }}</span>
        </label>
        <div class="form_element__range_input_container">
          <input
              ref="to"
              :id="'ad_fo_el_' + element.id + '_to'"
              :name="element.field"
              v-model="to"
              class="form_element__input-text"
              type="text"
              :inputmode="inputType === 'date' ? 'search' : ''"
              :autocomplete="inputType === 'date' ? 'off' : 'on'"
              :placeholder="((focus === element.id &&  subFocus === 'to')  || value.to) ? '' : ($t('genios.advancedSearch.range.to'))"
              @blur="verifyTo()"
              @focusin="setSubFocus('to')"
              @focusout="setSubFocus()"
              @keypress.enter="handleEnterTo"
          >
          <button type="button"
              v-if="to"
              @click="clearTo()"
          ><ion-icon name="close-outline"/></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from 'moment';
import {buildDatePicker} from "../../../functions/builds";

export default {
  name: "RangeFormElement",
  data() {
    return {
      idBase: Date.now(),
      from: (!!this.passedValue && !!this.passedValue.from) ? this.passedValue.from :'',
      to: (!!this.passedValue && !!this.passedValue.to) ? this.passedValue.to :'',
      fromPicker: '',
      toPicker: '',
      subFocus: null
    }
  },
  props: {
    element: Object,
    passedValue: Object,
    focus: [String, Number],
    maxDate: Date
  },
  computed: {
    value: function () {
      if (this.inputType === 'date') {
        return {
          from: this.verifyDate(this.from) ? this.formatDateString(this.from) : '',
          to: this.verifyDate(this.to) ? this.formatDateString(this.to, false) : ''
        }
      }
      return {
        from: this.from || '',
        to: this.to || ''
      }
    },
    inFocus: function () {
      return this.focus === this.element.id;
    },
    inputType: function () {
      if (this.element.dataType === 'DATE') {
        return 'date';
      }
      return 'text';
    }
  },
  watch: {
    passedValue:function (val) {
      if (!!val) {
        if (!!val.from) {
          if (val.from !== this.from) {
            this.from = val.from;
          }
        } else {
          this.from = '';
        }
        if (!!val.to) {
          if (val.to !== this.to) {
            this.to = val.to;
          }
        } else {
          this.to = '';
        }
      } else {
        this.from = '';
        this.to = '';
      }
    },
    from: function (from) {
      if (this.inputType === 'date') {
        try {
          this.fromPicker.setDateFromString(from);
        } catch (error) {
          console.log("swallowing picker error")
        }
      }
      this.emitIfValid();
    },
    to: function (to) {
      if (this.inputType === 'date') {
        try {
          this.toPicker.setDateFromString(to, false);
        } catch (error) {
          console.log("swallowing picker error")
        }
      }
      this.emitIfValid()
    }
  },
  mounted() {
    if (this.inputType === 'date') {
      if (this.fromPicker) {
        this.fromPicker.remove();
        this.toPicker.remove();
        this.idBase = Date.now();
      }
      this.fromPicker = this.buildPicker(this.$refs['from'], true);
      this.fromPicker.setDateFromString(this.from);
      this.toPicker = this.buildPicker(this.$refs['to'], false);
      this.toPicker.setDateFromString(this.to, false);
    }
  },
  methods: {
    verifyFrom: function () {
      if (this.inputType === 'date') {
        if (!this.verifyDate(this.from)) this.from = '';
      }
    },
    verifyTo: function () {
      if (this.inputType === 'date') {
        if (!this.verifyDate(this.to)) this.to = '';
      }
    },
    verifyDate: function (dateString) {
      const inputDate = moment(dateString, ['DD.MM.YYYY', 'D.M.YYYY', 'M.YYYY', 'YYYY'], true);
      return inputDate.isValid();
    },
    emitIfValid() {
      if (this.inputType === 'date') {
        if ((this.from === '' || this.verifyDate(this.from)) &&
            (this.to === '' || this.verifyDate(this.to))) {
          this.emitNoDuplicate();
        }
      } else {
        this.emitNoDuplicate();
      }
    },
    emitNoDuplicate: function () {
      if (_.isEqual(this.lastEmitted, this.value)) {
        return;
      }
      this.lastEmitted = _.cloneDeep(this.value);
      this.$emit("modified", this.value);
    },
    formatDateString: function (dateString, from = true) {
      let returnMoment;
      if (from) {
        returnMoment = moment(dateString, ['DD.MM.YYYY', 'D.M.YYYY', 'M.YYYY', 'YYYY'], true);
      } else {
        const newFormatDateMoment = moment(dateString, 'DD.MM.YYYY', true);
        const dateMoment = moment(dateString, 'D.M.YYYY', true);
        const monthMoment = moment(dateString, 'M.YYYY', true);
        const yearMoment = moment(dateString, 'YYYY', true);
        if (newFormatDateMoment.isValid()) {
          returnMoment = newFormatDateMoment;
        } else {
          returnMoment = dateMoment.isValid() ? dateMoment :
              monthMoment.isValid() ? monthMoment.endOf('month') :
                  yearMoment.isValid() ? yearMoment.endOf('year') : null;
        }
      }
      if (returnMoment) {
        return returnMoment.format('DD.MM.YYYY');
      }
      return '';
    },
    setFocus: function () {
      this.$emit("focus", this.element.id);
    },
    unsetFocus: function () {
      this.$emit("focus", null);
    },
    setSubFocus: function(fromOrTo) {
      this.subFocus = fromOrTo;
    },
    clearFrom: function () {
      this.from = '';
    },
    clearTo: function () {
      this.to = '';
    },
    handleEnterFrom: function () {
      if (this.fromPicker) {
        this.fromPicker.hide();
      }
    },
    handleEnterTo: function () {
      if (this.toPicker) {
        this.toPicker.hide();
      }
    },
    buildPicker: function (element, isFrom) {
      let selectCallback;
      const myself = this;
      if (isFrom) {
        selectCallback = function (dateString) {
          myself.from = dateString;
        }
      } else {
        selectCallback = function (dateString) {
          myself.to = dateString;
        }
      }
      return buildDatePicker(this.element.id + '-' + this.idBase, element, selectCallback, this.maxDate || null);
    }
  }
}
</script>

<style scoped>
</style>
