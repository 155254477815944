<template>
  <div class="warning-box">
    <ion-icon name="alert-circle-outline" class="alert-icon"></ion-icon>
    <span class="warning-text">
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'WarningAlertComponent',
  props: {
    messageType: {
      type: String,
      required: true
    }
  },
  computed: {
    message() {
      switch (this.messageType) {
        case 'standard-list-deletion':
          return this.$t('meinGenios.bookmarks.warning');
        default:
          return this.$t('meinGenios.bookmarks.defaultWarning');
      }
    }
  },
  mounted() {
    this.$emit("register-click-listener");
  },
}
</script>

<style scoped>
.warning-box {
  display: flex;
  align-items: center;
  background-color: #fbe4e4;
  color: #3a3a3a;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
}

.alert-icon {
  margin-right: 10px;
  color: #3a3a3a;
  font-size: 20px;
}

.warning-text {
  font-size: 14px;
  flex: 1;
}
</style>
