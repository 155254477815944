<template>
  <div :id="'wbt_'+documentId" class="watchlist-toggle-container">
    <div class="tooltip tooltip-icon" v-if="hasPermission('bookmark')">
      <Button
          type="button"
          @click="checkBookmarkAction"
          :icon="isSelected?'bookmark':'bookmark-outline'"
          aria-label="bookmark"
          variant="icon"
          v-bind:class="(isSelected)?'bookmarked' : 'only-on-hover'"
      />
      <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{$t("genios.tooltip.icons.bookmark")}}</div>
    </div>

    <WatchlistAddComponent
        v-if="showWatchlistDialog"
        :shared-state="sharedState"
        :document-ids="[documentToAdd]"
        @closeWidget="closeWatchlistDialog"
    />
    <WatchlistDeleteDialogComponent
        v-if="showDeleteDialog"
        :shared-state="sharedState"
        :document-id="deleteBookmarkId"
        :document-title="documentTitle"
        @closeWidget="closeDeleteDialog"
    />
  </div>
</template>

<script>
import WatchlistAddComponent from './WatchlistAddComponent.vue'
import {runFunctionAfterLoginDialog} from "../../../functions/builds";
import WatchlistDeleteDialogComponent from "./WatchlistDeleteDialogComponent.vue";
import Button from "../styled/Button.vue";
import {hasPermission} from "../../../functions/utils/permission_utils";
import {convertToZulu} from "../../../functions/utils/date_utils";

export default {
  name: "WatchlistBookmarkToggleComponent",
  components: {
    Button,
    WatchlistDeleteDialogComponent,
    WatchlistAddComponent
  },
  props: {
    sharedState: Object,
    documentId: String,
    documentTitle: String,
    documentItemDate: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showWatchlistDialog: false,
      showDeleteDialog: false,
    };
  },
  computed: {
    isSelected() {
      if (this.sharedState.watchlistDocumentIds === null || typeof (this.sharedState.watchlistDocumentIds) === 'undefined') {
        return false;
      } else {
        return this.sharedState.watchlistDocumentIds.filter(item => item).findIndex(item => item.documentId === this.documentId) !== -1;
      }
    },
    deleteBookmarkId() {
      return this.sharedState.watchlistDocumentIds.filter(item => item).filter(item => item.documentId === this.documentId).map(item => item.bookmarkId);
    },
    documentToAdd() {
      const docToAdd = {
        bookmarkedItem: this.documentId,
      }
      if (this.documentItemDate) {
        return {
          ...docToAdd,
          itemDate:convertToZulu(this.documentItemDate)
        }
      }
      return docToAdd;
    }
  },
  methods: {
    checkBookmarkAction() {
      if (!this.readOnly) {
        if (this.isSelected) {
          this.showWatchlistDialog = false;
          this.showDeleteDialog = true;
          this.actionsOnOpen();
        } else {
          this.openAddToWatchlist();
        }
      }
    },

    openAddToWatchlist() {
      const self = this;
      runFunctionAfterLoginDialog("genios.login.reasons.openWatchlist", function () {
        self.showWatchlistDialog = true;
        self.showDeleteDialog = false;
        self.actionsOnOpen();
      })
    },

    closeWatchlistDialog() {
      this.showWatchlistDialog = false;
      this.actionsOnClose();
    },

    closeDeleteDialog() {
      this.showDeleteDialog = false;
      this.actionsOnClose();
    },


    actionsOnOpen() {
      // workaround for hiding icons in action-tool-bar
      document.querySelectorAll(".containsDialogMarker .icon").forEach(icon => {
        icon.style.display = 'none';
      });
    },

    actionsOnClose() {
      document.querySelectorAll(".containsDialogMarker .icon").forEach(icon => {
        icon.style.display = 'inherit';
      });
    },
    hasPermission: function (name) {
      return hasPermission(name)
    }

  }
}
</script>

<style scoped>
.watchlist-toggle-container {
  position: relative;
}
</style>
