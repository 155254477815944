<template>
  <div class="enter">
    <!--suppress HtmlUnknownTarget -->
    <form class="enter__form login_form"
          v-if="!loginStatus.loggedIn"
          :aria-label="solution"
          @submit="checkAndSendForm($event, submitData, {invalidUsernameError:ebibSelected.errorText})">
      <div class="enter__header ebib">
        <div v-html="$t('genios.login.reasons.ebib')"></div>
        <div>
          <span v-if="!!ebibSelected.welcomeText && ebibSelected.welcomeText !== ''" v-html="ebibSelected.welcomeText"/>
          <span v-else-if="!!ebibFields.options[0].welcomeText && ebibFields.options[0].welcomeText !== ''" v-html="ebibFields.options[0].welcomeText"/>
          <DropdownFormElement
              v-if="ebibFields.options.length > 1"
              :element="ebibFields"
              :focus="focus"
              v-on:focus="$emit('focus', $event)"
              @modified="updateEbibFields($event)"
              :passedValue="initialLibrary"
              :scroll-into-view="true"
              ref="ebibDropdown"
              :error="(!!ebibFields.error)"
          />
          <div class="enter__error">{{ ebibFields.error }}</div>
          <span v-if="!!ebibSelected.comment && ebibSelected.comment !== ''" v-html="ebibSelected.comment"/>
          <span v-else-if="!!ebibFields.options[0].comment && ebibFields.options[0].comment !== ''" v-html="ebibFields.options[0].comment"/>
        </div>
      </div>

      <TextFormElement
          :element="inputUsername"
          :focus="focus"
          :passed-value="inputUsername.value"
          :error="(!!inputUsername.error || !!anyErrors)"
          v-on:focus="focus = $event"
          v-on:modified="onModifiedUsername($event); submitData[isEbibWithSubDomain ? 'externalUserId':'username'] = $event;"
      />
      <div class="enter__error">{{ inputUsername.error }}</div>

      <TextFormElement
          htmlInputFieldType="password"
          :element="inputPassword"
          :focus="focus"
          :passed-value="inputPassword.value"
          :error="(!!inputPassword.error || !!anyErrors)"
          v-on:focus="focus = $event"
          v-on:modified="onModifiedPassword($event); submitData.password = $event"
      />
      <div class="enter__error">{{ inputPassword.error }}</div>


      <div class="enter__error">{{ anyErrors }}</div>

      <a :href="getPasswordForgottenPath()" class="password_forgotten">{{
          $t('genios.login.labels.passwordForgot')
        }}</a>

      <CheckboxFormElement
          :element="termsAndConditions"
          :error="(!!termsAndConditions.error)"
          :passed-value="termsAndConditions.value"
          v-on:modified="termsAndConditions.value = $event"
      />
      <div class="enter__error">{{ termsAndConditions.error }}</div>

      <CheckboxFormElement
          :element="privacyPolicy"
          :error="(!!privacyPolicy.error)"
          :passed-value="privacyPolicy.value"
          v-on:modified="privacyPolicy.value = $event"
      />
      <div class="enter__error">{{ privacyPolicy.error }}</div>

      <CheckboxFormElement :element="rememberMeCheckboxObj"
                           :focus="focus"
                           v-on:focus="focus = $event"
                           @blur="focus = null"
                           @modified="rememberMeCheckboxObj.passedValue = $event; submitData.rememberMe = $event"/>
      <div class="enter__section">
        <span class="mandatory">*{{ $t('orderingProcess.mainForm.termsAndConditions.mandatory') }}</span>
      </div>

      <div class="enter__form__submit_container">
        <Button
            type="button"
            @click="closeModal"
            :text="$t('genios.login.labels.cancel')"
            variant="secondary"
            :toggle-text="false"
            style="margin-right: 15px;"
        />
        <Button
            name="submit"
            id="submit"
            type="submit"
            variant="primary"
            :loading="loading"
            :text='$t("genios.login.buttons.login")'
            :toggle-text="false"
            style="margin: 0;"
        />
      </div>
    </form>
    <div
        class="login_form loading"
        v-else-if="!!callback"
    >
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import i18n from "../plugins/Translations.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import Button from "../styled/Button.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import loginMixin from "../../../functions/mixins/loginMixin";

export default {
  name: "EbibLoginComponent",
  mixins: [loginMixin],
  components: {Button, TextFormElement, CheckboxFormElement, DropdownFormElement},
  props: {
    sharedState: Object,
    callback: Function,
  },
  data() {
    return {
      focus: null,
      submitData: {
        username: "",
        password: "",
        embedded: true,
        rememberMe: false
      },
      selectedEbibName: '',
      ebibFields: {
        id: '',
        fieldValue: '',
        options: [{
          text: '', // object can be fulfilled with other fields, but these 2 are mandatory
          value: ''
        }],
        fieldLabel: i18n.t('genios.login.labels.dropdown') + '*'
      },
      initialLibrary: {
        text: '',
        value: ''
      }
    };
  },
  created() {
    if (this.sharedState?.ebibList?.length) {
      if (this.sharedState.ebibList.length === 1) {
        this.submitData = {
          ...this.submitData,
          username: this.sharedState.ebibList[0].loginAccount
        }
      }
      if (this.sharedState.ebibList[0].usernamePlaceholder) {
        this.inputUsername.fieldLabel = this.sharedState.ebibList[0].usernamePlaceholder
      }
    }
    this.ebibFields.options = this.options
  },
  computed: {
    options() {
      if (this.sharedState.ebibList?.length === 1) {
        return this.sharedState.ebibList;
      } else {
        return [{text: "", value: ""}].concat(this.sharedState.ebibList?.map((item) => {
          return {
            ...item,
            text: item.externalName,
            value: item.loginAccount
          }
        }));
      }
    },
    ebibSelected() {
      return this.sharedState.ebibList?.find((element) => element.externalName === this.selectedEbibName) || {};
    }
  },
  async mounted() {
    let urlParams = new URLSearchParams(window.location.search)
    let libraryUrlParam = urlParams.get('library')
    const previousLibrary = libraryUrlParam ? libraryUrlParam : localStorage.getItem("chosenLibrary")
    this.initialLibrary = this.ebibFields?.options?.find(()=>true) || { text: '', value: '' }
    if (previousLibrary) {
      const newInitialLibrary = this.ebibFields?.options?.find(item => item.externalName === previousLibrary)
      if (newInitialLibrary) {
        this.initialLibrary = newInitialLibrary
      }
    }
    this.selectedEbibName = this.initialLibrary?.externalName || '';
    this.$refs['ebibDropdown']?.$emit('modified', this.initialLibrary);
  },
  methods: {
    updateEbibFields(event) {
      localStorage.setItem("chosenLibrary", event.externalName)
      if (!!event?.loginAccount) {
        if (event.usernamePlaceholder) {
          this.inputUsername.fieldLabel = event.usernamePlaceholder
        } else {
          this.inputUsername.fieldLabel = i18n.t("genios.login.labels.username") + '*'
        }
        this.submitData = {
          ...this.submitData,
          username: event.loginAccount
        }
        this.selectedEbibName = event.text;
      } else {
        this.inputUsername.fieldLabel = i18n.t("genios.login.labels.username") + '*'
        this.submitData = {
          ...this.submitData,
          username: ""
        }
        this.selectedEbibName = ''
      }
      this.ebibFields.value = event.value;
    }
  }
};
</script>
<style scoped>
.ebib {
  flex-direction: column;
}
</style>