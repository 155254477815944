import {fetchBookmarksFromApi, updateDocumentPrices} from "../../fetching";
import {SortingBy, SortingOrder} from "../../consts";


const initialState = {
    bookmarks: [],
    selectedBookmarks: [],
    totalNumberOfBookmarks: 0,
    sortSize: 10,
    sortBy: SortingBy.BY_DATE_CREATED,
    sortOrder: SortingOrder.DESCENDING,
    currentPage: 1,
    loading: true,
    error: false,
    errorMessage: "",
    ...window.sessionVariables?.['vuexState']?.bookmarksModule || {}
}

const bookmarksModule =  {
    namespaced: true,
    state: {...initialState},
    getters: {
        getBookmarks: state => state.bookmarks,
        getSelectedBookmarks: state => state.selectedBookmarks,
        getTotalNumberOfBookmarks: state => state.totalNumberOfBookmarks,
        getSortSize: state => state.sortSize,
        getSortBy: state => state.sortBy,
        getSortOrder: state => state.sortOrder,
        getCurrentPage: state => state.currentPage,
        getLoading: state => state.loading,
    },
    mutations: {
        setBookmarks(state, bookmarks) {
            state.bookmarks = bookmarks;
        },
        setSelectedBookmarks(state, bookmarks) {
            state.selectedBookmarks =  Array.from(new Set([...bookmarks]));
        },
        setTotalNumberOfBookmarks(state, noOfBookmarks) {
            state.totalNumberOfBookmarks = noOfBookmarks;
        },
        setSortSize(state, payload) {
            state.sortSize = payload;
        },
        setSortBy(state, payload) {
            state.sortBy = payload;
        },
        setSortOrder(state, payload) {
            state.sortOrder = payload;
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload
        },
        resetState(state) {
            Object.assign(state, initialState);
        }
    },
    actions: {
        async fetchBookmarks({ commit, dispatch, getters }, bookmarkListId) {
            commit('setLoading', true);
            const params = {
                id: bookmarkListId,
                offset: getters.getCurrentPage - 1,
                size: getters.getSortSize,
                sort: getters.getSortBy,
                order: getters.getSortOrder,
            };
            try {
                commit('setBookmarks', []);
                const bookmarksForBookmarkList = await fetchBookmarksFromApi(params);
                commit('setTotalNumberOfBookmarks', bookmarksForBookmarkList?.totalPages || 0);
                const items = bookmarksForBookmarkList?.content || [];

                const allBookmarkDocumentIds = items.map(item => item.bookmarkedItem);
                const docIds = [...allBookmarkDocumentIds];
                const bookmarks = await updateDocumentPrices(docIds, items);
                commit('setBookmarks', bookmarks || []);
            } catch (e) {
                commit('setBookmarks', []);
                commit('setTotalNumberOfBookmarks', 0);
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async createBookmarkList({ commit }, bookmarkList) {
            commit('setLoading', true);
            try {
                commit('setBookmarkList', bookmarkList);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
    },
}
export default bookmarksModule;