<template>
  <div class="table-container" v-if="isValidTable">
    <table class="custom-table">
      <colgroup v-for="(column, index) in customTableArray.columns" :key="index" :style="`width: ${column.columnWidth};`">
        <col :class="`${column.key}Column`" />
      </colgroup>

      <thead>
      <tr>
        <th v-for="column in customTableArray.columns" :key="column.key">
          {{ column.label }}
        </th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="(row, rowIndex) in customTableArray.rows" :key="rowIndex" :class="{ odd: rowIndex % 2 !== 0, even: rowIndex % 2 === 0 }">
        <td v-for="(column, colIndex) in customTableArray.columns" :key="colIndex">
          <template v-if="column.render">
            <component
                :is="column.render"
                :key="row.id"
                v-bind="getComponentProps(row, column)"
                @modified="handleColumnEvent(row, column.key, $event)"
                @update-list="handleColumnEvent(row, column.key, $event)"
            ></component>
          </template>
          <template v-else>
            <span
                v-if="row.isClickable && column.isClickable"
                :class="{'clickable__td-item': row.isClickable && column.isClickable}"
                @click="column.isClickable && row.isClickable && $emit('item-clicked', { row: row, key: column.key })"
                v-html="getValue(row, column.key)"
            ></span>
            <span v-else v-html="getValue(row, column.key)"></span>
          </template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import ActionBar from "../MeinGenios/common/ActionBar.vue";

export default {
  name: "CustomTableComponent",
  components: {
    CheckboxFormElement,
    ActionBar
  },
  props: {
    customTableArray: {
      type: Object,
      default: () => ({
        columns: [],
        rows: [],
      }),
      required: true,
    },
  },
  computed: {
    isValidTable() {
      return (
          Array.isArray(this.customTableArray.columns) &&
          Array.isArray(this.customTableArray.rows) &&
          this.customTableArray.columns.length > 0 &&
          this.customTableArray.rows.length > 0
      );
    },
  },
  methods: {
    getValue(row, key) {
      const keys = key.split(".");
      return keys.reduce((acc, curr) => (acc ? acc[curr] : null), row) || "-";
    },
    getComponentProps(row, column) {
      return column.props ? column.props(row) : {};
    },
    handleColumnEvent(row, key, event) {
      this.$emit("column-event", { row, key, value: event });
    },
  },
};
</script>

<style scoped>
.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table thead th {
  background-color: var(--shade-dark);
  color: var(--background-color);
  text-align: left;
  height: 48px;
}

.custom-table tbody tr {
  background-color: var(--shade-light);
}

.custom-table tbody tr.even {
  background-color: transparent;
}

.custom-table td,
.custom-table th {
  border: none;
}

.custom-table td span {
  margin-right: 5px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding: 5px 0;
}

</style>