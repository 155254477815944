<template>
  <overlay-scrollbar
      :options="scrollBarOptions"
      id="emailSendForm"
  >
    <form @submit.prevent="submitForm" @keydown.esc="closeModal" v-click-outside="onClickOutside"
          v-if="expandedSendEmailData">
      <div class="send_email__form">
        <p class="send_email__form__header">{{ $t('genios.sendEmail.header') }}</p>
        <p>{{ $t('genios.sendEmail.link') }}</p>
        <div class="send_email__form__line_1">
          <div class="send_email__form__emailsTo" v-for="(email, index) in emailForm.emailsTo">
            <div class="send_email__form__emailsTo__input-item">
              <text-form-element
                  :passed-value="email.value"
                  :element="email"
                  :focus="focus"
                  :error="!!error[`email_${index}`]"
                  @focus="focus = $event"
                  @modified="email.value = $event"
              />
              <Button
                  type="button"
                  variant="icon"
                  v-if="emailForm.emailsTo.length > 1"
                  @click="emailForm.emailsTo.splice(index, 1)"
                  icon="trash-outline"
              />
              <Button
                  :style="index !== emailForm.emailsTo.length-1 && 'visibility:hidden'"
                  type="button"
                  variant="icon"
                  @click="emailForm.emailsTo.splice(index + 1, 0, {
                id: 'emailTo' + index+1,
                field: 'emailTo'+index+1,
                fieldType: 'text',
                fieldLabel: $t('genios.sendEmail.recipients'),
                value: ''
          })"
                  icon="add-outline"
              />
            </div>
            <Error v-if="error[`email_${index}`]" :error="error[`email_${index}`]"/>
          </div>
        </div>
        <div class="send_email__form__body">
          <TextFormElement
              :passed-value="emailForm.emailFrom.value"
              :element="emailForm.emailFrom"
              :focus="focus"
              :error="!!error.email_emailFrom"
              @focus="focus = $event"
              @modified="emailForm.emailFrom.value = $event"
          />
          <Error v-if="error.email_emailFrom" :error="error.email_emailFrom"/>
          <div class="send_email__form__message">
            <TextAreaFormElement
                :passed-value="emailForm.message.value"
                :element="emailForm.message"
                :focus="focus"
                @focus="focus = $event"
                @modified="emailForm.message.value = $event"
                ref="textAreaForm"
            />
          </div>
          <p class="send_email__form__privacy">
            {{ $t('genios.sendEmail.privacy') }}
          </p>
        </div>
        <p class="send_email__form__mandatory">
          {{ $t('genios.sendEmail.mandatory') }}
        </p>
        <div class="send_email__form__submit_container">
          <Button
              name="close-modal"
              :text="$t('genios.sendEmail.cancelText')"
              type="button"
              @click="closeModal"
              variant="secondary"
              :toggle-text="false"
          />
          <Button
              :text="$t('genios.sendEmail.submitText')"
              variant="primary"
              id="submit1"
              name="submit1"
              position="left"
              ref="submitBtn"
              :toggle-text="false"
              :disabled="isLoading"
          >
            <ButtonLoader
                v-if="isLoading"
                :size="$refs.submitBtn.$el.clientHeight"
            />
          </Button>
        </div>
      </div>
    </form>
  </overlay-scrollbar>
</template>

<script>
import axios from "axios";
import vClickOutside from "v-click-outside";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';

import TextFormElement from "../FormElements/TextFormElement.vue";
import TextAreaFormElement from "../FormElements/TextAreaFormElement.vue";
import i18n from "../plugins/Translations.vue";
import Button from "../styled/Button.vue";
import Error from "../styled/Error.vue";
import ButtonLoader from "../styled/ButtonLoader.vue";
import {closeTransparentPopupModal} from "../../../functions/closing";

export default {
  name: "SendEmailComponent",
  components: {
    Error,
    TextFormElement,
    Button,
    TextAreaFormElement,
    ButtonLoader,
    'overlay-scrollbar': OverlayScrollbarsComponent
  },
  props: {
    sharedState: Object,
    type: {
      type: String,
      required: false,
      default: "general",
    },
    ids: {
      type: Array,
      required: true
    },
    documents: {
      type: Array,
      required: false,
      default: null
    },
    expandedSendEmail: {
      type: Boolean,
      default: false
    },
    destroy: {
      type: Boolean,
      default: false
    },
    bookmarkListName: {
      type: String,
      required: false
    }
  },
  directives: {
    clickOutside: vClickOutside?.directive
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.submitBtn.$el.focus();
      window.addEventListener('resize', this.setElementHeight);
      const ro = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.setElementHeight(true);
        }
      });

      ro.observe(this.$refs.textAreaForm.$el);
    })
  },
  methods: {
    onClickOutside() {
      this.closeModal();
    },
    setElementHeight(scroll = false) {
      this.$nextTick(() => {
        const el = document.querySelector('#emailSendForm');
        if (!!el) {
          const {scrollHeight: sentEmailFormHeight} = el.querySelector('.send_email__form');
          const {top} = el.getBoundingClientRect();
          const {innerHeight} = window;
          let availableHeight = innerHeight - top - 10;
          if (scroll) {
            if (availableHeight >= sentEmailFormHeight) {
              availableHeight = sentEmailFormHeight;
            }
          }
          el.style.height = availableHeight >= el.clientHeight ? `${sentEmailFormHeight}px` : `${availableHeight}px`;
          const footer = document.querySelector('footer');
          if (el && footer) {
            const formBottom = el.getBoundingClientRect().bottom;
            const footerTop = footer.getBoundingClientRect().top;
            if (formBottom > footerTop) {
              const spaceNeeded = formBottom - footerTop;
              const newMarginBottom = Math.max(0, spaceNeeded);
              el.style.transform = `translateY(-${newMarginBottom}px)`;
            }
          }
          el.scrollIntoView({
            behavior: 'instant',
            block: 'end',
            inline: 'nearest',
          });
        }
      })
    },
    updateParent() {
      this.$emit("update-form", {
        emailFrom: this.emailForm.emailFrom.value,
        emailsTo: this.emailForm.emailsTo.map(item => item.value),
        message: this.emailForm.message.value,
      });
    },
    closeModal() {
      this.expandedSendEmailData = false;
      this.$emit('closeWidget', false);
      if (this.destroy) {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      }
    },
    async submitForm() {
      this.validate();

      if (Object.keys(this.error).length < 1) {
        if (this.type === "bookmark-list") {
          this.updateParent();
          this.closeModal();
        } else {
          this.isLoading = true;

          const { sendObject } = this;

          if (this.documents) {
            sendObject.requestList = this.documents.filter(i => i);
          } else {
            sendObject.requestList = this.ids?.map(id => {
              let item = window.sharedState?.searchResult?.find(doc => doc.documentId === id);
              if (!item) return null;
              return {
                documentId: item.legacyDocId, database: item.database, priceCategory: item.priceCategory
              };
            }).filter(i => !!i);
          }

          sendObject.emailFrom = this.emailForm.emailFrom.value;
          sendObject.emailsTo = this.emailForm.emailsTo.map(item => item.value);
          sendObject.message = this.emailForm.message.value;
          sendObject.bookmarkListName = this.bookmarkListName;

          const emailEndpoint = this.bookmarkListName ? "/api/sendBookmarkListEmail" : "/api/sendEmail";

          try {
            const { data } = await axios.post(emailEndpoint, sendObject);
            if (!data.ok) {
              this.error = { general: i18n.t('genios.monitoring.error.' + data.responseCode) };
            }
          } catch (error) {
            this.error = { general: i18n.t('genios.monitoring.error.connection') };
            console.error("sendEmail:submitForm:error =>", error);
          } finally {
            this.isLoading = false;
          }

          if (Object.keys(this.error).length < 1) {
            this.closeModal();
            closeTransparentPopupModal();
          }
        }
      }
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    validate: function () {
      this.error = {};
      const {emailsTo} = this.emailForm;

      if (emailsTo.length) {
        emailsTo.forEach((item, index) => {
          if (!this.emailIsValid(item.value)) {
            this.error['email_' + index] = i18n.t('genios.monitoring.error.emailNotValid');
          }
        });
      }

      if (!this.emailForm.emailFrom.value || !this.emailIsValid(this.emailForm.emailFrom.value)) {
        this.error['email_emailFrom'] = i18n.t('genios.monitoring.error.emailNotValid');
      }
    }
  },
  data() {
    return {
      error: {},
      scrollBarOptions: {
        scrollbars: {
          autoHide: 'move',
          clickScrolling: true,
          autoHideDelay: 200
        },
        overflowBehavior: {x: 'hidden'}
      },
      focus: null,
      expandedSendEmailData: this.expandedSendEmail,
      isLoading: false,
      emailForm: {
        emailsTo: [0].map((item, index) => {
              return {
                id: 'emailTo' + index,
                field: 'emailTo',
                fieldLabel: i18n.t('genios.sendEmail.recipients'),
                fieldType: 'text',
                value: ""
              };
            }
        ),
        emailFrom: {
          id: 'emailFrom',
          field: 'emailFrom',
          fieldLabel: i18n.t('genios.sendEmail.sender'),
          fieldType: 'text',
          value: window.sharedState.defaultUserEmail || ""
        },
        message: {
          id: 'message',
          field: 'message',
          fieldLabel: i18n.t('genios.sendEmail.messageLabel'),
          value: ""
        }
      },
      sendObject: {
        emailFrom: "",
        emailsTo: [],
        message: "",
        documents: [{document: this.document}]
      },
      document: {
        documentId: "",
        publisher: "",
        year: "",
        price: "",
        title: "",
        image: "",
        type: ""
      }
    };
  },
  watch: {
    'emailForm.emailsTo'() {
      this.setElementHeight();
    }

  }
};
</script>