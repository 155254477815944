import i18n from "../vue/components/plugins/Translations.vue";

const BASE_URL = "https://www.gbi-genios.de/de"

const linkTargetType = Object.freeze({
  self: "_self",
  blank: "_blank",
  parent: "_parent",
  top: "_top",
})

export const menuNavigationLinks = (solution) => [ //TODO: use solution settings in future: FUNKTIONEN-11613
  {
    labelCode: "genios.help",
    src: solution === "ebib" ? `/de/hilfe/ebib/genios-ebib-hilfe?solution=genios+ebib` : solutionsList.includes(solution) ? `/de/hilfe/${solution}/genios-hilfe?solution=${solution}` : `/de/hilfe/firmensolution/genios-hilfe?solution=firmensolution`,
    target: linkTargetType.self
  },
  {
    labelCode: "genios.faq",
    src: solution === "ebib" ? `/corporate/de/${solution}/faq` : `${BASE_URL}/faq-haeufig-gestellte-fragen`,
    target: linkTargetType.self
  },
  "line",
  {
    labelCode: "genios.companyWebsiteLink.text",
    src: `${BASE_URL}`,
    target: linkTargetType.blank
  },
  {
    labelCode: "genios.contact",
    src: solution === "ebib" ? `/corporate/de/${solution}/kontakt` : "/corporate/de/genios/kontakt",
    target: linkTargetType.self
  },
  {
    labelCode: "genios.imprint",
    src: `${BASE_URL}/impressum-geniosde`,
    target: linkTargetType.self
  },
  {
    labelCode: "genios.agb",
    src: `${BASE_URL}/allgemeine-geschaeftsbedingungen-geniosde`,
    target: linkTargetType.self
  },
  {
    labelCode: "genios.privacy",
    src: `${BASE_URL}/datenschutzerklaerung-geniosde`,
    target: linkTargetType.self
  }
];


export const passwordValidationCriteria = (validationObject) => [
  {
    text: i18n.t('passwordValidation.length'),
    validation: () => validationObject?.length >= 8,
  },
  {
    text: i18n.t('passwordValidation.upperCaseLowerCase'),
    validation: () =>
        /[A-Z]/.test(validationObject) && /[a-z]/.test(validationObject),
  },
  {
    text: i18n.t('passwordValidation.number'),
    validation: () => /\d/.test(validationObject),
  },
  {
    text: i18n.t('passwordValidation.specialCharacter'),
    validation: () => /[!@#$%^&*(),.?":{}|<>§$%&/*!?#+_-]/.test(validationObject),
  },
];

export const AttachmentType = Object.freeze({
  VIDEO: "VIDEO"
})

export const MediaType = Object.freeze({
  VIDEOS: "Videos",
  EBOOK: "eBook"
})

export const PaymentType = Object.freeze({
  CARD: "card",
  PAYPAL: "paypal",
  SOFORT: "sofort"
})

export const SortingOrder = Object.freeze({
  DESCENDING: "desc",
  ASCENDING: "asc",
})

export const SortingBy = Object.freeze({
  BY_DATE: "BY_DATE",
  BY_RELEVANCE: "BY_RELEVANCE",
  BY_RELEASE_DATE: "itemDate",
  BY_DATE_CREATED: "creationDate",
})
export const solutionsList = ["genios", "wiso"];

export const SearchResultSortAndOrder = Object.freeze([
  {
    sort: SortingBy.BY_RELEVANCE,
    order: SortingOrder.DESCENDING,
    value: 0,
    text: "meinGenios.basicSettings.filterAndSort.searchResult.relevantDesc"
  },
  {
    sort: SortingBy.BY_RELEVANCE,
    order: SortingOrder.ASCENDING,
    value: 1,
    text: "meinGenios.basicSettings.filterAndSort.searchResult.relevantAsc"
  },
  {
    sort: SortingBy.BY_DATE,
    order: SortingOrder.DESCENDING,
    value: 2,
    text: "meinGenios.basicSettings.filterAndSort.searchResult.dateDesc"
  },
  {
    sort: SortingBy.BY_DATE,
    order: SortingOrder.ASCENDING,
    value: 3,
    text: "meinGenios.basicSettings.filterAndSort.searchResult.dateAsc"
  }
])

export const BookmarksSortAndOrder = Object.freeze([
  {
    sort: SortingBy.BY_DATE_CREATED,
    order: SortingOrder.DESCENDING,
    value: 0,
    text: "meinGenios.basicSettings.filterAndSort.bookmarks.createDateDesc"
  },
  {
    sort: SortingBy.BY_DATE_CREATED,
    order: SortingOrder.ASCENDING,
    value: 1,
    text: "meinGenios.basicSettings.filterAndSort.bookmarks.createDateAsc"
  },
  {
    sort: SortingBy.BY_RELEASE_DATE,
    order: SortingOrder.DESCENDING,
    value: 2,
    text: "meinGenios.basicSettings.filterAndSort.bookmarks.releaseDesc"
  },
  {
    sort: SortingBy.BY_RELEASE_DATE,
    order: SortingOrder.ASCENDING,
    value: 3,
    text: "meinGenios.basicSettings.filterAndSort.bookmarks.releaseAsc"
  }
])

export const DocumentHistorySortAndOrder = Object.freeze([
  {
    sort: SortingBy.BY_DATE_CREATED,
    order: SortingOrder.DESCENDING,
    value: 0,
    text: "meinGenios.basicSettings.filterAndSort.documentHistory.viewedDesc"
  },
  {
    sort: SortingBy.BY_DATE_CREATED,
    order: SortingOrder.ASCENDING,
    value: 1,
    text: "meinGenios.basicSettings.filterAndSort.documentHistory.viewedAsc"
  },
  {
    sort: SortingBy.BY_RELEASE_DATE,
    order: SortingOrder.DESCENDING,
    value: 2,
    text: "meinGenios.basicSettings.filterAndSort.documentHistory.releaseDesc"
  },
  {
    sort: SortingBy.BY_RELEASE_DATE,
    order: SortingOrder.ASCENDING,
    value: 3,
    text: "meinGenios.basicSettings.filterAndSort.documentHistory.releaseAsc"
  }
])


export const ActionBarType = Object.freeze({
  DROPDOWN_BOOKMARK: ['eye', 'mail', 'download', 'print', 'move', 'remove-bookmark'],
  BOOKMARK: ['mail', 'download', 'move', 'remove-bookmark'],
  DROPDOWN_DOCUMENT_HISTORY: ['eye', 'mail', 'download', 'print', 'remove', 'bookmark'],
  DOCUMENT_HISTORY: ['mail', 'download', 'remove'],
  DROPDOWN_SEARCH_HISTORY: ['remove-history'],
  SEARCH_HISTORY: ['remove-history', 'open'],
})

export const noneSearchPurpose = {
  id: 'none'
}

export const SearchPurposeSplit = {
  sign: '|'
}

export const PopupType =  Object.freeze({
  ADD: 'add',
  DELETE: 'delete',
})

export const ResearchPurposeOrderCodes =    ['REQUEST_SEARCH_PURPOSE', 'ERROR_SEARCH_PURPOSE', 'ERROR_SEARCH_PURPOSE_1', 'ERROR_SEARCH_PURPOSE_2']