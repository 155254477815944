// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#documentLoader[data-v-4794b53e] {
  width: 100%
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/styled/DocumentLoader.vue"],"names":[],"mappings":";AAaA;EACA;AACA","sourcesContent":["<template>\n  <div class='loading' id=\"documentLoader\">\n    <div class='loader'/>\n  </div>\n</template>\n\n<script>\nexport default {\n  name: \"DocumentLoader\"\n}\n</script>\n\n<style scoped>\n#documentLoader {\n  width: 100%\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
