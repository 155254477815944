import {
    buildPath,
    buildSearchParametersFromCurrentState,
    buildSourceParametersFromCurrentState
} from "./utils/url_utils";
import {closeAllPreviews, closeFullPopupModal} from "./closing";
import axios from "axios";
import qs from "qs";
import {setupBrowsePagingFunctionality, setupFullDocumentFunctionality, setupPreviewFunctionality} from "./setups";
import {setupChangeViewHandlers, setupCheckDocumentHandlers, setupSearchPagingHandlers} from "./setups/handlers";
import {checkLogin, checkStoredSelectedItems} from "./checks";
import {horizontalScrollRecommendations, setupNotImplementedAlert} from "./temp";
import {changeHistory} from "./utils/history_utils";
import updatePrices from "./updatePrices";
import {changeSortingOrderHistory} from "./components/searchSettingComponent";
import {setupFullDocumentComponent, setupResultVueComponents} from "./setups/vue-components";
import {watchlistAddAllButtons} from "./components/watchlist";
import {buildHistoryState} from "./builds";
import {fetchHigherResultListDocumentIds, fetchLowerResultListDocumentIds, fetchNavigationTree} from "./fetching";
import {openFullArticleModal, openMultipleArticleModal, openPreview, openQueryPopup} from "./opens";
import {isUndefined} from "lodash";
import {setupSendEmailButtons} from "./components/sendEmailComponent";
import {setInnerHTML, setOuterHTML} from "./utils/tricks";
import browser from "browser-detect";
import {rememberCheckedSources} from "./components/selectionComponent";
import {setupFirstLoadModuleOfMySourcesFunctionality} from "./components/mySourceComponent";

export const replaceBrowseResult = function () {
    const sourceListDiv = document.querySelector("#partialSourceListContainer");
    if (!sourceListDiv || typeof (sourceListDiv) === 'undefined') {
        return;
    }
    sourceListDiv.scrollTop = 0;
    let path = buildPath('/browse');
    const offset = window.sharedState.offset;
    const sort = window.sharedState.sort;
    let params = {
        size: 25,
        partial: true,
        offset,
        sort
    };

    let currentStateParams = buildSourceParametersFromCurrentState();
    params = {...currentStateParams, ...params, ...window.sharedState.browseActiveFilters};
    closeAllPreviews(true);
    if (!!window.tileOverlayScrollbar) {
        window.tileOverlayScrollbar.destroy();
        delete window.tileOverlayScrollbar;
    }
    if (window.cancelTokenSource) {
        window.cancelTokenSource.cancel();
    }
    window.cancelTokenSource = axios.CancelToken.source();
    updateUrlSearchParams(true)
    const browseFiltersFromSession = window.sharedState.browseFilters || []
    params.hasFilterList = browseFiltersFromSession.length > 0
    sourceListDiv.innerHTML = "<div class=\"loading\"><div class=\"loader\"></div></div>";
    axios
        .get(path, {
            params,
            paramsSerializer: params => qs.stringify(params, {arrayFormat: "repeat"}),
            cancelToken: window.cancelTokenSource.token
        })
        .then(response => {
            //sourceListDiv.innerHTML = response.data;
            setInnerHTML(sourceListDiv, response.data)
            setupFirstLoadModuleOfMySourcesFunctionality(false).then()
            replaceReloadedPreviewDiv();
            setupPreviewFunctionality();
            setupBrowsePagingFunctionality();
            autoSelectedFilteredSource();
            setupCheckDocumentHandlers();
            checkStoredSelectedItems(window.sharedState.selectedSources);
            setupNotImplementedAlert(sourceListDiv);
            window.pageOverlayScrollbar?.scroll(0);
            //changeHistory({sourceFilterQuery: window.sharedState.browseFilter.sourceFilterQuery}, true);
            changeHistory({offset: window.sharedState.offset}, false);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            window.cancelTokenSource = undefined
        });
};

export const autoSelectedFilteredSource = () => {
    const selectedSources = JSON.parse(sessionStorage.getItem("selectedSources")) || []
    if (selectedSources.length > 0) {
        const inputs = document.querySelectorAll('[js-handler*="articleCheckboxClickHandler"]') || []
        if (inputs.length > 0) {
            inputs.forEach(input => {
                const isTileExist = !!selectedSources.find(item => item.docId === input.value)
                rememberCheckedSources(input.value, isTileExist, input.getAttribute("data-source"));
                if (isTileExist) {
                    input.checked = isTileExist
                    const tile = input.closest(".media_tile.element")
                    tile.classList.add("selected")
                }
            })
        }
    }
}

function replaceReloadedPreviewDiv() {
    let reloadedPreviewDiv = document.querySelector("#reloadedPreviewDiv");
    let existingPreviewDiv = document.querySelector("#previewDiv");
    if (reloadedPreviewDiv === null || typeof (reloadedPreviewDiv) === 'undefined' || existingPreviewDiv === null || typeof (existingPreviewDiv) === 'undefined') return;
    existingPreviewDiv.innerHTML = reloadedPreviewDiv.innerHTML;
    reloadedPreviewDiv.remove();
}

export const replaceSearchResult = function (pushHistory = true, callbackFunction = function () {
}) {
    const searchResultDiv = document.getElementById("partialSearchResult");
    if (searchResultDiv === null || typeof (searchResultDiv) === 'undefined') {
        return;
    }
    if (!browser().mobile) {
        window.pageOverlayScrollbar.scroll(0);
    } else {
        document.body.scrollTop = 0
    }
    document.querySelector('.searchResult').scrollTop = 0;
    document.documentElement.scrollTop = 0;
    let path = '/searchResult';
    path = buildPath(path);

    let params = {
        getFilters: false,
        partial: true
    };
    let currentStateParams = buildSearchParametersFromCurrentState();
    params = {...currentStateParams, ...params};
    if (window.cancelTokenSource) {
        window.cancelTokenSource.cancel();
    }
    window.cancelTokenSource = axios.CancelToken.source();
    searchResultDiv.parentElement.classList.add("loading");
    updateUrlSearchParams(pushHistory);

    axios
        .get(path, {
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: "repeat"});
            },
            cancelToken: cancelTokenSource.token
        })
        .then(response => {
            if (!!window.tileOverlayScrollbar) {
                window.tileOverlayScrollbar.destroy();
                delete window.tileOverlayScrollbar;
            }
            const searchResultDiv = document.getElementById("partialSearchResult");
            searchResultDiv.parentElement.classList.remove("loading");
            setOuterHTML(response.data, "partialSearchResult");
            updatePrices();

            setupChangeViewHandlers();
            setupCheckDocumentHandlers();
            changeSortingOrderHistory();
            setupPreviewFunctionality();
            setupSearchPagingHandlers();
            setupNotImplementedAlert(document.getElementById("partialSearchResult"));
            setupFullDocumentFunctionality();
            setupResultVueComponents();
            setupSendEmailButtons();
            checkStoredSelectedItems();
            watchlistAddAllButtons();
            callbackFunction();
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            window.cancelTokenSource = undefined;
        });
};

const updateUrlSearchParams = function (addToHistory) {
    let params = window.sharedState.page === "browse" ? setupUrlParams() : buildSearchParametersFromCurrentState();
    if (addToHistory) {
        window.history.pushState(buildHistoryState(), null, "?" + qs.stringify(params, {
            indices: false,
            skipNulls: true
        }));
    } else {
        window.history.replaceState(buildHistoryState(), null, "?" + qs.stringify(params, {
            indices: false,
            skipNulls: true
        }));
    }
};

const setupUrlParams = () => {
    return {
        ...buildSourceParametersFromCurrentState(),
        ...window.sharedState.browseActiveFilters
    }
}

export const restorePageFromPagingState = async function (e) {
    const queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    const savedState = e.state;
    if (savedState?.sharedState) {
        for (const [key, value] of Object.entries(savedState.sharedState)) {
            if (!isUndefined(key)) {
                if (key !== 'loginStatus') {
                    window.sharedState[key] = value;
                }
            }
        }
    }
    const isDocumentPopupOpen = urlParams.has('document');
    if (!isDocumentPopupOpen) {
        closeFullPopupModal(false, false);
    }
    const isPreviewPanelOpen = urlParams.has('preview');
    if (!isPreviewPanelOpen) {
        closeAllPreviews(true);
    }

    const paymentPopup = document.querySelector(".payment");
    if (!!paymentPopup)
        document.body.removeChild(paymentPopup);
    if (!!window.tileOverlayScrollbar) {
        window.tileOverlayScrollbar.destroy();
        delete window.tileOverlayScrollbar;
    }

    if (savedState?.mode === "search") {
        const searchResultDiv = document.getElementById("partialSearchResult");
        if (!searchResultDiv) {
            location.reload();
            return;
        }
        searchResultDiv.parentElement.classList.remove("loading");
        setOuterHTML(savedState.results, "partialSearchResult");
        //scroll to stored position
        if (!!window.pageOverlayScrollbar && !!savedState.mainScrollPosition) {
            window.pageOverlayScrollbar.scroll(savedState.mainScrollPosition);
        }
    } else if (savedState?.mode === "browse") {
        const browseDiv = document.querySelector("#partialSourceListContainer");
        if (!browseDiv) {
            location.reload();
            return;
        }
        browseDiv.parentElement.classList.remove("loading");
        browseDiv.outerHTML = savedState.browse;
        setupPreviewFunctionality();
        setupBrowsePagingFunctionality();
        //scroll to stored position
        if (!!window.pageOverlayScrollbar && !!savedState.mainScrollPosition) {
            window.pageOverlayScrollbar.scroll(savedState.mainScrollPosition);
        }
    }
    setupPreviewFunctionality();
    setupSearchPagingHandlers();
    setupBrowsePagingFunctionality();
    horizontalScrollRecommendations();
    setupResultVueComponents();
    setupChangeViewHandlers();
    setupCheckDocumentHandlers();
    changeSortingOrderHistory();
    setupFullDocumentFunctionality();
    await checkLogin();
    updatePrices();

    fetchNavigationTree();

    if (window.sharedState.page === "browse") {
        setupFirstLoadModuleOfMySourcesFunctionality().then()
    }

    //check if we should open a preview on page load
    if (isPreviewPanelOpen) {
        const previewToOpen = urlParams.get('preview');
        openPreview(previewToOpen, false);
    }
    //check if we need to open a document from url
    if (isDocumentPopupOpen) {
        const documentsToOpen = urlParams.getAll('document');
        console.log("replacing:documentsToOpen => ", documentsToOpen);
        if (documentsToOpen.length > 1) {
            await openMultipleArticleModal(documentsToOpen);
        } else {

            const documentToOpen = urlParams.get('document');
            if (savedState?.mode === "search") {
                window.fetchHigherDocumentIds = fetchHigherResultListDocumentIds;
                window.fetchLowerDocumentIds = fetchLowerResultListDocumentIds;
            }
            if (!!documentToOpen) {
                openFullArticleModal(documentToOpen, false).then(() => {
                });
            }
        }
    }

    if (window.sharedState.page === 'document' || window.sharedState.view === 'grid') {
        openQueryPopup()

        const queryPopupId = urlParams.get('queryPopupId')
        await setupFullDocumentComponent(queryPopupId)
    }

};
