<template>
  <div class="payment">
    <div class="payment__popup">
      <Button
          type="button"
          variant="icon"
          icon="close-outline"
          class="close"
          @click="closeWindow"
      />
      <ErrorPaymentCode
          v-if="[paymentForms.ERROR_REMOTE_AGENCY, paymentForms.ERROR_UNZER].includes(orderResponse.code) || orderResponse.errorMessage"
          :order-response="orderResponse"
          @close-window="closeWindow"
      />
      <LoginButtons :shared-state="sharedStateObj" class="payment__person"
                    v-if="loggedIn && ['SHOW_PAYMENT_MAIN_FORM', 'SHOW_LIGHT_REGISTRATION_FORM', 'TERMS_AND_CONDITIONS_FORM', 'ERROR_REGISTRATION_USER_EXISTS', 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED','ERROR_TRANSACTION_NOT_SUCCESS', 'TRANSACTION_IN_PROGRESS'].includes(orderResponse.code)"/>
      <MainFormComponent
          v-if="['SHOW_PAYMENT_MAIN_FORM', 'SHOW_LIGHT_REGISTRATION_FORM', 'TERMS_AND_CONDITIONS_FORM', 'ERROR_REGISTRATION_USER_EXISTS', 'ERROR_REGISTRATION_USER_EXISTS_NOT_CONFIRMED', 'ERROR_TRANSACTION_NOT_SUCCESS', 'TRANSACTION_IN_PROGRESS'].includes(orderResponse.code)"
          v-bind="orderResponse"
          :previewMode="mode"
          :document-ids="documents"
          :order-data-id="orderResponse.orderDataId"
          :orderResponse="orderResponse"
          :shared-state="sharedStateObj"
      />

      <OrderRequestComponent
          v-if="['SHOW_ORDER_REQUEST'].includes(orderResponse.code)"
          v-bind="orderResponse"
          :previewMode="mode"
          :document-ids="documents"
          :order-data-id="orderResponse.orderDataId"
          :orderResponse="orderResponse"
          @close-window="closeWindow"
      />
      <ConfirmPaidFormComponent
          v-else-if="['REQUEST_CONFIRMATION_PAID_APPROVAL', 'ERROR_CONFIRM_PAID_VALIDATION'].includes(orderResponse.code)"
          v-bind="orderResponse"
          :document-id="documents[0]"
          @close-window="closeWindow"
      />
      <NoProductFormComponent
          v-else-if="paymentForms[orderResponse.code] === 'ERROR_USER_NOT_AUTHORIZED'"
          :message="$t(orderResponse.description)"
          @close-window="closeWindow"
      />
      <NoCouponWarningFormComponent
          v-else-if="paymentForms[orderResponse.code] === 'REQUEST_BILL_APPROVAL'"
          @close-window="closeWindow"
          v-bind="orderResponse"
      />
      <SearchPurposeFormComponent
          v-else-if="['REQUEST_SEARCH_PURPOSE', 'ERROR_SEARCH_PURPOSE', 'ERROR_SEARCH_PURPOSE_1', 'ERROR_SEARCH_PURPOSE_2'].includes(orderResponse.code)"
          :code="orderResponse.code"
          :orderDataId="orderResponse.orderDataId"
          :document-id="documents[0]"
          :searchPurposeStepModel="orderResponse.searchPurposeStepModel"
          :message="$t('orderingProcess.searchPurpose.message')"
          @close-window="closeWindow"
      />
      <LimitUsageFormComponent
          v-else-if="['MESSAGE_IP_LOCKED', 'MESSAGE_INVALID_IP_DOORWAY', 'MESSAGE_DELIVERY_LOCKED_LIMIT', 'REQUEST_CAPTCHA', 'ERROR_INVALID_CAPTCHA'].includes(orderResponse.code)"
          v-bind="orderResponse"
          @close-window="closeWindow"
      />
      <CompanyGoodnessComponent
          v-else-if="paymentForms[orderResponse.code] === 'ERROR_COUNTRY_FORBIDDEN'"
          v-bind="orderResponse"
          @close-window="closeWindow"
      />
      <RequestReasonFormComponent
          v-else-if="['REQUEST_SEARCH_REASON', 'ERROR_INVALID_SEARCH_REASON', 'ERROR_INVALID_PRIMARY_EMAIL', 'ERROR_REMOTE_AGENCY_DOCUMENT_VALIDATION'].includes(orderResponse.code)"
          :order-response="orderResponse"
          @close-window="closeWindow"
          :shared-state="sharedStateObj"
      />
    </div>

  </div>
</template>

<script>
import axios from "axios";

import Vue from "vue";
import VShowSlide from "v-show-slide";
import {Plugin} from 'vue-fragment'

import ConfirmPaidFormComponent from "./ConfirmPaidFormComponent.vue";
import NoProductFormComponent from "./NoProductFormComponent.vue";
import SearchPurposeFormComponent from "./SearchPurposeFormComponent.vue";
import MainFormComponent from "./mainForm/MainFormComponent.vue";
import OrderRequestComponent from "./orderRequestForm/OrderRequestComponent.vue";
import LimitUsageFormComponent from "./limitUsageForm/LimitUsageFormComponent.vue";
import CompanyGoodnessComponent from "./CompanyGoodnessComponent.vue";
import Button from "../styled/Button.vue";
import LoginButtons from "../Login/LoginButtons.vue";
import NoCouponWarningFormComponent from "./NoCouponWarningFormComponent.vue";
import RequestReasonFormComponent from "./requestReasonForm/RequestReasonFormComponent.vue";
import ErrorPaymentCode from "./errorCodes/ErrorPaymentCode.vue"
import i18 from "../plugins/Translations.vue"

import eventBus from "../../../eventBus";

import {paymentForms, PaymentMode} from "../../../functions/components/payment";
import {closeFullPopupModal} from "../../../functions/closing";
import {fetchAndInsertDocumentInPreview, fetchAttachment, fetchRemoteAgencyDocument} from "../../../functions/fetching";
import {openFullArticleModal, openMultipleArticleModal} from "../../../functions/opens";
import {convertStringToHtml} from "../../../functions/utils/string_utils";
import isIOSDevice from "../../../functions/utils/isIOSDevice";
import {isUndefined} from "lodash";
import {setupFullDocumentComponent, setupPaymentFormComponent} from "../../../functions/setups/vue-components";
import {setupVideoFrame} from "../../../functions/setups";

Vue.use(VShowSlide)
Vue.use(Plugin)

export default {
  name: "PaymentComponent",
  components: {
    LoginButtons,
    RequestReasonFormComponent,
    OrderRequestComponent,
    LimitUsageFormComponent,
    MainFormComponent,
    NoProductFormComponent,
    ConfirmPaidFormComponent,
    SearchPurposeFormComponent,
    NoCouponWarningFormComponent,
    CompanyGoodnessComponent,
    Button,
    ErrorPaymentCode
  },
  props: {
    orderResponse: Object,
    sharedState: Object,
    documentIds: {
      type: [Array, String],
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sharedStateObj: this.sharedState,
      paymentForms,
      documents: !Array.isArray(this.documentIds) ? [this.documentIds] : this.documentIds,
      loggedIn: this.sharedState.loginStatus.loggedIn
    }
  },
  mounted() {
    eventBus.$on('update-login-status', state => {
      this.sharedStateObj = {...state}
      this.loggedIn = this.sharedStateObj.loginStatus.loggedIn
    })

    if (!this.orderResponse?.errorMessage && (!this.orderResponse?.code // e.g. for preview html
        || this.orderResponse.code === "REDIRECT_DOWNLOAD_E_PAPER"
        || this.orderResponse.code === "REDIRECT_DOWNLOAD_REMOTE_AGENCIES"
        || this.orderResponse.code === "VIDEO_PAYMENT_SUCCESS"
    )) {
      let params = {};
      if (this.orderResponse?.code) {
        params.resultCode = this.orderResponse.code;
        params.redirectUrl = this.orderResponse.downloadAttachmentModel?.redirectUrl;
      }
      this.buy({params})
    }

    //moving payment component outside all element. Body should be a parentNode
    let elem = this.$el;
    let overlay = elem?.parentNode?.classList.contains("document__overlay");
    if (overlay) {
      elem.parentNode.parentNode.removeChild(elem.parentNode);
      document.body.appendChild(elem);
    }
  },
  methods: {
    closeWindow() {
      if (this.sharedState.page === "document") {
        const returnUrl = window.location.protocol + "//" + window.location.host
        window.location.replace(returnUrl)
      } else {
        if (this.mode === PaymentMode.fullDocument) {
          closeFullPopupModal()
        }
        this.closeDocument()
        eventBus.$emit("disable")
      }
    },
    async closeDocument() {
      this.$destroy()
      this.$el.parentNode.removeChild(this.$el);
    },
    buy({documentId = this.documents[0], token = null, callback = false, params = {}}) {
      const {mode: paramsMode} = params
      const currentMode = isUndefined(paramsMode) ? this.mode : paramsMode
      if (currentMode === PaymentMode.remoteAgency) {
        const fetchAttachmentButton = convertStringToHtml(sessionStorage.getItem('attachmentButton'))
        fetchRemoteAgencyDocument(fetchAttachmentButton, this.orderResponse.orderDataId, token, params);
      } else if (currentMode === PaymentMode.attachment) {
        const fetchAttachmentButton = convertStringToHtml(sessionStorage.getItem('attachmentButton'))
        fetchAttachment(fetchAttachmentButton, this.orderResponse.orderDataId, token, params);
        sessionStorage.setItem('attachmentButton', '')
      } else if (currentMode === PaymentMode.previewDocument) {
        const isFIDO = documentId.split("__")[0] === "FIDO";
        fetchAndInsertDocumentInPreview(documentId, token, (callback || !isFIDO ? false : async () => {
          eventBus.$emit("documentBought", documentId);
        }), params);
      } else if (currentMode === PaymentMode.fullDocument) {
        openFullArticleModal(documentId, false, false, token, params, async () => {
          eventBus.$emit("documentBought", documentId);
        })
      } else if (currentMode === PaymentMode.multiDocuments) {
        openMultipleArticleModal(this.documents, {params, idsToBuy: this.documents})
      } else if (currentMode === PaymentMode.queryPopup) {
        setupFullDocumentComponent(documentId, params)
      } else if (currentMode === PaymentMode.video) {
        setupVideoFrame({documentId, videoId: this.orderResponse.videoId, clientType: "Beta", type: "-1000"}, {...params})
      } else if (currentMode === PaymentMode.ePaper) {
        const url = `/api/orderEPaper`
        params.issueId = this.documentIds[0]
        params.issueDate = this.orderResponse.issueDate
        params.gRecaptchaResponse = token;

        axios({
          method: "POST",
          url,
          data: params
        }).then(({data}) => {
          if (data.code === "REDIRECT_DOWNLOAD_E_PAPER") {
            this.fetchStream(data.downloadAttachmentModel.redirectUrl, this.documentIds[0], this.orderResponse.issueDate).then(() => {
              eventBus.$emit("disable")
            })
          } else {
            setupPaymentFormComponent({...data, issueDate: params.issueDate}, params.issueId, currentMode);
          }
        })
      }

      this.closeDocument();
    },
    async fetchStream(url, issueId, issueDate) {
      const {data} = await axios.get(url, {responseType: "blob"})
      this.download(data);
      await this.fetchDetails(issueId, issueDate)
    },
    download(response) {
      let url = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = url;
      if (!isIOSDevice()) {
        link.target = "_blank";
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    fetchDetails: async function (issueId, issueDate) {
      axios.get("/api/getEPaperInfo", {
        params: {
          issueId,
          issueDate
        }
      }).then(response => {
        if (response.data.error) {
          console.log(response.data.errorMessage);
          return;
        }
        const selector = `button[data-e-paper-id="${this.documentIds[0]}"]`
        const ePaperButton = document.querySelector(selector) || null
        if (ePaperButton) {
          const text = Array.from(ePaperButton.children).find(item => item.children.length === 0);
          if (response.data.paid) {
            ePaperButton.classList.remove("button__primary");
            ePaperButton.classList.add("button__secondary")
            text.innerText = i18.t("genios.search.button.ePaper.paid");
          } else {
            ePaperButton.classList.add("button__primary");
            ePaperButton.classList.remove("button__secondary")
          }
        }
      }).catch(error => {
        console.error(error);
      });
    },
  }
}
</script>
