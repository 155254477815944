export const queryParamHandler = {
    methods: {
        syncQueryParams(queryParams) {
            const query = {};
            Object.keys(queryParams).forEach(key => {
                if (queryParams[key] !== undefined && queryParams[key] !== null) {
                    query[key] = queryParams[key];
                }
            });

            if (query) {
                this.$router.push({query}).catch(error => {
                    if (error.name !== 'NavigationDuplicated') {
                        throw error;
                    }
                });
            }
        },

        getQueryParams() {
            return this.$route.query;
        },
    }
};
