<template>
  <div class="mein-genios-template">
    <div class="mein-genios-template__header">
      <div class="mein-genios-template__header--title" v-if="pageTitle">
        {{pageTitle}}
      </div>
      <div v-if="pageInfo" class="mein-genios-template__header__info">{{pageInfo}}</div>
    </div>
    <div class="mein-genios-template__body">
      <div class="mein-genios-template__variants">
        <slot name="variants"></slot>
      </div>
      <div class="mein-genios-template__filters">
        <slot name="filters"></slot>
      </div>
    </div>
    <div class="mein-genios-template__content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "MeinGeniosTemplate.vue",
  props: {
    pageTitle: String,
    pageInfo: String,
  },
}
</script>

<style scoped>
</style>
