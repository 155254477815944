<template>
  <div class="research-purpose-popup">
    <div class="research-purpose-popup__main">
      <form class="research-purpose-popup__template" action="/" @submit.prevent="submitSearchPurpose">
        <h1 class="research-purpose-popup__title">{{ title }}</h1>
        <p class="research-purpose-popup__message" v-html="message"/>
        <div v-if="type === PopupType.ADD" class="research-purpose-popup__item">
          <TextFormElement
              :passedValue="searchPurpose.fieldValue"
              :focus="searchPurpose.fieldLabel"
              :element="searchPurpose"
              @modified="searchPurpose.fieldValue = $event"
          />
        </div>

        <div v-if="type === PopupType.DELETE">
          <div>{{ currentPurpose.bookmarkedItem }}</div>
        </div>
        <Error v-show="searchPurpose.error" :error="searchPurpose.error"/>

        <div class="research-purpose-popup__actions">
          <Button
              type="button"
              variant="secondary"
              :text="$t('genios.close')"
              :toggle-text="false"
              @click.stop="$emit('close-window')"
              text-style="600"
          />
          <Button
              type="submit"
              ref="submitButton"
              :autofocus="true"
              variant="primary"
              :text="buttonText"
              :toggle-text="false"
              text-style="600"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import TextFormElement from "../../FormElements/TextFormElement.vue";
import i18m from "../../plugins/Translations.vue"
import Error from "../../styled/Error.vue";
import { addSearchPurpose, deleteSearchPurpose} from "../../../../functions/fetching";
import {PopupType} from "../../../../functions/consts";

export default {
  name: "ResearchPurposePopUpComponent",
  props: {
    message: String,
    title: String,
    type: String,
    currentPurpose: {
      type: Object,
      default: null
    },
  },
  components: {
    Error,
    TextFormElement,
    Button,
    i18m
  },
  data() {
    return {
      searchPurpose: {
        id: 'purpose',
        fieldLabel: i18m.t("orderingProcess.searchPurpose.field_1"),
        fieldType: 'text',
        fieldValue: '',
        error: '',
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.submitButton.$el.focus();
      }, 100);
    });
  },
  methods: {
    submitSearchPurpose() {
      this.searchPurpose.error = '';
      if (this.type === PopupType.ADD) {
        this.addResearchPurpose();
      } else if (this.type === PopupType.DELETE) {
        this.deleteResearchPurpose();
      }
    },
    async addResearchPurpose() {
      this.searchPurpose.error ='';
      if (this.isValid()) {
        const newSearchPurpose = await addSearchPurpose(this.searchPurpose.fieldValue);
        if (newSearchPurpose?.error) {
          this.searchPurpose.error = newSearchPurpose.message;
        } else {
          if (newSearchPurpose) {
            await this.$store.commit('setSelectedSearchPurposeId', newSearchPurpose[0].id);
          }
          this.$emit('purpose-added-or-deleted');
          this.$emit('close-window');
        }
      }
    },
    async deleteResearchPurpose() {
      this.searchPurpose.error ='';
      if (this.currentPurpose) {
        const response = await deleteSearchPurpose(this.currentPurpose.id);
        if (response?.error) {
          this.searchPurpose.error = response.message;
        } else {
          this.$emit('purpose-added-or-deleted');
          this.$emit('close-window');
        }
      }
    },
    isValid() {
      this.searchPurpose.error = '';
      let isValid = true
        if (!this.searchPurpose.fieldValue.trim()) {
          this.searchPurpose.error = i18m.t("orderingProcess.searchPurpose.error", {fieldName: this.searchPurpose.fieldLabel})
          isValid = false
        }
      return isValid
    }
  },
  computed: {
    PopupType() {
      return PopupType
    },
    buttonText() {
      return this.type === PopupType.ADD ? this.$t('genios.ok') : this.$t('genios.delete');
    }
  },
}
</script>
