import { render, staticRenderFns } from "./SearchPurposeFormComponent.vue?vue&type=template&id=4a0537a8"
import script from "./SearchPurposeFormComponent.vue?vue&type=script&lang=js"
export * from "./SearchPurposeFormComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports