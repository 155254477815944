<template>
  <article
      class="article element"
      :class="{'list-view': isListView, 'grid-view': isGridView}"
      :id="'list__' + document.documentId"
      :data-document-id="document.documentId"
      tabindex="0"
      role="article"
      :aria-labelledby="'articleTextTitle__' + document.documentId"
  >
    <template v-if="isListView">
      <div class="article__img">
        <CheckboxFormElement
          :element="{
              ...defaultCheckboxElement,
              id: document.documentId,
              name: document.documentTitle,
            }"
          :key="document.documentId"
          :passed-value="isSelected"
          @focus="focus = $event"
          @modified="checkDocument($event)"
          class="article__checkbox article__checkbox__label"
      />
      </div>
      <div class="article__source">
        <div v-if="document.creationDate">
          <div class="article__source__date article__date--icon"  :class="{'bold': isSortByAddedOn}">
            <ion-icon v-if="document.isBookmark" name="bookmark" aria-label="bookmark icon" role="img"/>
            <ion-icon v-if="document.isDocumentHistory" name="eye-outline" aria-label="document icon" role="img"/>
            <div v-html="document.creationDate"/>
          </div>
        </div>
        <div class="article__source__date article__date--icon" v-if="document.displayRow.field_H" :class="{'bold': isSortByCreatedOn}">
          <ion-icon name="earth-outline" aria-label="created on" role="img"/>
          <div v-html="document.displayRow.field_H"/>
        </div>
      </div>
      <div class="article__text">
        <a
            :href="`/document/${document.documentId}`"
            class="article__text__panelHeader"
            tabindex="-1"
            :data-document-id="document.documentId"
        >
          <div class="article__text__container">
            <div class="article__img__source">
              <img
                  alt=""
                  loading="lazy"
                  class="article__text__source-icon"
                  :src="`/resource/logos-tiny/${document.database.toLowerCase()}.png`"
              />
            </div>
            <template v-if="document.displayRow.field_A">
              <div class="article__text__source">{{document.displayRow.field_A}}</div>
            </template>
          </div>
          <template v-if="document.isDocumentHistory && document.searchpurpose">
            <div class="article__text__source search-purpose">{{ $t('genios.searchPurpose') }} {{document.searchpurpose}}</div>
          </template>
          <template v-if="document.displayRow.field_B">
            <div class="article__text__supertitle">{{document.displayRow.field_B}}</div>
          </template>
        </a>
        <div class="article__text__panelBody">
          <a
              :href="`/document/${document.documentId}`"
              class="article__text__panelBody__details js_open_full_document_modal"
              tabindex="-1"
              :data-document-id="document.documentId"
              @click="openArticleOnClick($event, document.documentId)"
          >
            <template v-if="document.displayRow.field_C">
              <div class="article__text__title tooltip">
                <div id="articleTextTitle" v-html="document.displayRow.field_C"></div>
                <template v-if="document.mediaType.includes('Firmeninformationen') && document.displayRow.field_C_tooltip">
                  <div
                      class="tooltiptext tooltip-top"
                      v-html="formatTooltip(document.displayRow.field_C_tooltip)"
                  ></div>
                </template>
              </div>
            </template>
            <template v-if="document.displayRow.field_D">
              <div class="article__text__subtitle" v-html="document.displayRow.field_D"></div>
            </template>
            <template v-if="document.displayRow.field_E">
              <div class="article__text__text" v-html="document.displayRow.field_E"></div>
            </template>
            <template v-if="document.displayRow.field_F">
              <div class="article__text__trefferumgebung" v-html="document.displayRow.field_F"></div>
            </template>
          </a>
          <div class="article__text__bar">
            <div class="article__text__bar__number_pages">
              <div class="article-type__container">
                <div v-if="document.itemType" class="refresh-solution">{{ document.itemType }}</div>
                <template v-if="document.itemType.includes('eBook')">
                  {{ document.displayRow.field_G }} {{ $t('genios.document.pages') }} {{ document.displayRow.field_G_1 }} {{ $t('genios.document.words') }}
                </template>
                <template v-else>
                  <span v-if="document.displayRow.field_G">{{ document.displayRow.field_G }} {{ $t('genios.document.words') }}</span>
                </template>
              </div>
              <div class="refresh-solution__text__bar">
                <div v-for="fileType in document.fileTypes" :key="fileType"
                     class="article__text__bar__attachment media_preview__main_content__button_bar__attachment"
                >
                  <TagComponent :tag-name="fileType"/>
                </div>
              </div>
            </div>
            <div class="article__text__bar__actions">
              <div class="article__functions containsDialogMarker" id="action_bar_marker">
                <div class="flex">
                  <div class="only-on-hover">
                    <action-bar
                        :action-bar-buttons="actionBarConfig.actionBarButtons"
                        :selected-list="[document]"
                        :all-documents-on-page="actionBarConfig.allDocumentsOnPage"
                        :is-item-action="true"
                        @update-prices="$emit('update-prices', $event)"
                        @update-list="$emit('update-list')"
                    ></action-bar>
                  </div>
                  <div v-if="!(document.isBookmark)"
                       class="watchlistBookmarkToggleButton"
                       :data-document-id="document.documentId"
                       :data-document-title="document.documentTitle"
                       :data-document-item-date="document.displayRow.field_H"
                  ></div>
                </div>
                <div class="tooltip tooltip-button">
                  <a
                      :href="`/document/${document.documentId}`"
                      :data-document-id="document.documentId"
                      :data-legacy-doc-id="document.legacyDocId"
                      data-view-type="list"
                      data-link-type="button"
                      @click="openArticleOnClick($event, document.documentId)"
                      :class="[
                    { 'button__secondary': document.paid || document.visited,
                     'button__primary': !document.paid || !isLoggedIn
                     },
                    'buy_button',
                    'js_open_full_document_modal',
                    document.infoPrice || document.displayPrice ? '' : 'hide',
                    {
                      'paid': document.paid,
                      'visited': document.visited
                    }
                  ]"
                  >
                    {{ document.infoPrice ? $t('genios.search.button.infosAndPrices') : document.displayText || '...' }}
                  </a>
                  <template v-if="document.displayRow.field_C_tooltip">
                    <div
                        class="tooltiptext tooltip-top tooltip-top-button"
                        v-html="formatTooltip(document.displayRow.field_C_tooltip)"
                    ></div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isGridView">

    </template>
    <template v-else>
      <template v-if="document.itemType.includes('Videos')">
        <div class="article-element__img__video fake_video img-container">
          <img alt="" :loading="'lazy'" :src="`/resource/logos-small/${document.database.toLowerCase()}.png`"/>
          <div class="article-element__img__video__tag"><p>{{document.itemType}}</p></div>
        </div>
      </template>
      <template v-else>
        <div class="article__img">
          <img
              alt=""
              class="preview-image"
              :loading="index > 5 ? 'lazy' : 'eager'"
              :src="document.coverUri"
          />
          <CheckboxFormElement
              :element="{
              ...defaultCheckboxElement,
              id: document.documentId,
              name: document.documentTitle,
            }"
              :key="document.documentId"
              :passed-value="isSelected"
              @focus="focus = $event"
              @modified="checkDocument($event)"
              class="article__checkbox article__checkbox__label"
          />
          <div class="article__img__tag_row">{{document.itemType}}</div>
        </div>
      </template>
      <div class="article__text">
        <div class="article__img">
          <img
              alt=""
              :loading="index > 5 ? 'lazy' : 'eager'"
              class="preview-image"
              :src="document.coverUri"
          />
          <CheckboxFormElement
              :element="{
              ...defaultCheckboxElement,
              id: document.documentId,
              name: document.documentTitle,
            }"
              :key="document.documentId"
              :passed-value="isSelected"
              @focus="focus = $event"
              @modified="checkDocument($event)"
              class="article__checkbox article__checkbox__label"
          />
          <div class="article__img__tag_row">{{document.itemType}}</div>
        </div>
        <a
            :href="`/document/${document.documentId}`"
            class="article__text__panelHeader"
            tabindex="-1"
            :data-document-id="document.documentId"
        >
          <div class="article__img__source">
            <img
                alt=""
                loading="lazy"
                class="article__text__source-icon"
                :src="`/resource/logos-tiny/${document.database.toLowerCase()}.png`"
            />
            <span>{{ document.sourceName }}</span>
          </div>
          <template v-if="document.displayRow.field_A">
            <div class="article__text__source">{{document.displayRow.field_A}}</div>
          </template>
          <template v-if="document.displayRow.field_B">
            <div class="article__text__supertitle">{{document.displayRow.field_B}}</div>
          </template>
        </a>
        <div class="article__text__panelBody">
          <a
              :href="`/document/${document.documentId}`"
              class="article__text__panelBody__details js_open_full_document_modal"
              tabindex="-1"
              :data-document-id="document.documentId"
              @click="openArticleOnClick($event, document.documentId)"
          >
            <template v-if="document.displayRow.field_C">
              <div class="article__text__title tooltip">
                <div id="articleTextTitle" v-html="document.displayRow.field_C"></div>
                <template v-if="document.mediaType.includes('Firmeninformationen') && document.displayRow.field_C_tooltip">
                  <div
                      class="tooltiptext tooltip-top"
                      v-html="formatTooltip(document.displayRow.field_C_tooltip)"
                  ></div>
                </template>
              </div>
            </template>
            <template v-if="document.displayRow.field_D">
              <div class="article__text__subtitle" v-html="document.displayRow.field_D"></div>
            </template>
            <template v-if="document.displayRow.field_E">
              <div class="article__text__text" v-html="document.displayRow.field_E"></div>
            </template>
            <template v-if="document.displayRow.field_F">
              <div class="article__text__trefferumgebung" v-html="document.displayRow.field_F"></div>
            </template>
          </a>
          <div class="article__text__bar">
            <div class="article__text__bar__number_pages">
              <template v-if="document.itemType.includes('eBook')">
                {{ document.displayRow.field_G }} {{ $t('genios.document.pages') }} {{ document.displayRow.field_G_1 }} {{ $t('genios.document.words') }}
              </template>
              <template v-else>
                <span v-if="document.displayRow.field_G">{{ document.displayRow.field_G }} {{ $t('genios.document.words') }}</span>
              </template>
            </div>
            <div class="refresh-solution__text__bar" v-for="fileType in document.fileTypes" :key="fileType">
              <div class="article__text__bar__attachment media_preview__main_content__button_bar__attachment"
              >
                <TagComponent :tag-name="fileType"/>
              </div>
            </div>
            <div class="article__img__tag_row">{{ document.itemType }}</div>
            <div class="article__text__bar__actions">
              <div class="tooltip tooltip-button">
                <a
                    :href="`/document/${document.documentId}`"
                    :data-document-id="document.documentId"
                    :data-legacy-doc-id="document.legacyDocId"
                    data-view-type="list"
                    data-link-type="button"
                    @click="openArticleOnClick($event, document.documentId)"
                    :class="[
                    { 'button__secondary': document.paid || document.visited,
                     'button__primary': !document.paid || !isLoggedIn
                     },
                    'buy_button',
                    'js_open_full_document_modal',
                    document.infoPrice || document.displayPrice ? '' : 'hide',
                    {
                      'paid': document.paid,
                      'visited': document.visited
                    }
                  ]"
                >
                  {{ document.infoPrice ? $t('genios.search.button.infosAndPrices') : document.displayText || '...' }}
                </a>
                <template v-if="document.displayRow.field_C_tooltip">
                  <div
                      class="tooltiptext tooltip-top tooltip-top-button"
                      v-html="formatTooltip(document.displayRow.field_C_tooltip)"
                  ></div>
                </template>
              </div>
              <div class="article__functions containsDialogMarker" id="action_bar_marker">
                <action-bar
                    class="only-on-hover"
                    :action-bar-buttons="actionBarConfig.actionBarButtons"
                    :selected-list="[document]"
                    :all-documents-on-page="actionBarConfig.allDocumentsOnPage"
                    :is-item-action="true"
                    @update-prices="$emit('update-prices', $event)"
                    @update-list="$emit('update-list')"
                ></action-bar>
                <div v-if="!(document.isBookmark)"
                     class="watchlistBookmarkToggleButton"
                     :data-document-id="document.documentId"
                     :data-document-title="document.documentTitle"
                     :data-document-item-date="document.displayRow.field_H"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="article__source">
        <div class="article__source__date article__date--icon" v-if="document.displayRow.field_H">
          <div v-html="document.displayRow.field_H"/>
        </div>
        <div class="article__source__image">
          <img
              alt=""
              :loading="index > 5 ? 'lazy' : 'eager'"
              class="article__text__source-icon"
              :src="`/resource/logos-big/${document.database.toLowerCase()}.png`"
          />
        </div>
      </div>
    </template>
  </article>
</template>

<script>

import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import TagComponent from "./TagComponent.vue";
import Button from "./Button.vue";
import {openFullArticleModal} from "../../../functions/opens";
import {setupPaymentFormComponent} from "../../../functions/setups/vue-components";
import axios from "axios";
import {PaymentMode} from "../../../functions/components/payment";
import {fakeVisitedLink} from "../../../functions/utils/history_utils";
import {watchlistAddAllButtons} from "../../../functions/components/watchlist";
import ActionBarLayout from "../MeinGenios/common/ActionBarLayout.vue";
import ActionBar from "../MeinGenios/common/ActionBar.vue";
import {SortingBy} from "../../../functions/consts";

export default {
  name: 'ArticleElementComponent',
  components: {ActionBar, ActionBarLayout, Button, CheckboxFormElement, TagComponent},
  data() {
    return {
      focus: '',
      defaultCheckboxElement: {
        fieldLabel: '',
        field: false,
        error: false,
      },
      isLoggedIn: false,
    }
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    view: {
      type: String,
      default: 'listView'
    },
    sortBy: {
      type: String,
      default: SortingBy.BY_RELEASE_DATE
    },
    actionBarConfig: {
      actionBarButtons: {
        type: Array,
        required: true,
        default: []
      },
      selectedArticles: {
        type: Array,
        required: false,
        default: []
      },
      allDocumentsOnPage: {
        type: Array,
        required: false,
        default: []
      }
    },
    isSelected: Boolean
  },
  mounted() {
    this.isLoggedIn = window.sharedState.loginStatus.loggedIn;
    watchlistAddAllButtons();
  },
  computed: {
    isListView() {
      return this.view === 'listView';
    },
    isGridView() {
      return this.view === 'gridView';
    },
    isSortByCreatedOn() {
      return this.sortBy === SortingBy.BY_RELEASE_DATE;
    },
    isSortByAddedOn() {
      return this.sortBy === SortingBy.BY_DATE_CREATED;
    }
  },
  methods: {
    checkDocument(event) {
      this.$emit('update-selected', { documentId: this.document.documentId, selected: event });
    },
    formatTooltip(tooltip) {
      return tooltip
          .replace(/"/g, '&quot;')
          .replace(/'/g, '&#39;')
          .replace(/&amp;/g, '&');
    },
    async openArticleOnClick(event, documentId) {
      if (sharedState.page !== 'document') {
        event.preventDefault();

        const forceConfirmPrice = event.currentTarget.hasAttribute("data-force-confirm-price");
        const linkHref = event.currentTarget.href;

        const { data } = await axios.post(`/api/retrieveDocuments`, {
          ids: [documentId],
          forceConfirmPrice: !!forceConfirmPrice,
        });

        if (data?.code) {
          await setupPaymentFormComponent(data, documentId, PaymentMode.fullDocument);
        } else {
          fakeVisitedLink({ current_url: window.location.href, desired_url: linkHref });
          window.sharedState.fullArticleModalList = [documentId];
          openFullArticleModal(documentId, true, forceConfirmPrice).then(() => {
          });
        }
        this.$nextTick(() => {
          this.$emit('update-prices', [this.document.documentId]);
        });
      }
    },
  },
};
</script>