<template>
  <div class="left-drawer-container" :style="{ minWidth: isCollapsed ? 'auto' : '254px' }">
  <div v-if="!isCollapsed || isMobile" class="left-drawer">
      <div v-if="menuItems.length > 0">
        <div class="drawer-header">
          <div class="drawer-title">{{ title }}</div>
          <div v-if="isMobile" class="collapse-icon" @click="closeNav">
            <ion-icon name="close-outline"></ion-icon>
          </div>
        </div>
        <div
            v-for="(item, index) in menuItems"
            :key="index"
            class="menu-item-wrapper"
        >
          <div
              class="navigation-options"
              @click.stop="handleItemClick(item)"
              :class="{ 'selected': isParentOrSelfSelected(item), 'hovered': hoverItem === item.label || hoverItem && item.children && item.children.includes(hoverItem) }"
              @mouseover="hoverItem = item.label"
              @mouseleave="hoverItem = null"
          >
            <div v-if="item.url" class="nav-link" @click.stop="navigateToNavItem(item.url)">{{ $t(`meinGenios.navigation.${item.label}`) }}</div>
            <div v-else>{{ $t(`meinGenios.navigation.${item.label}`) }}</div>
            <ion-icon v-if="item.children" :name="item.expanded ? 'chevron-up-outline' : 'chevron-down-outline'" @click.stop="toggleItem(item)"></ion-icon>
          </div>
          <div v-if="item.expanded && item.children" class="sub-items">
            <div
                class="navigation-sub-options"
                v-for="(subItem, subIndex) in item.children"
                :key="subIndex"
                @click.stop="selectSubItem(item, subItem)"
                :class="{ 'selected': selectedItem === subItem.label, 'hovered': hoverItem === subItem, 'last-sub-item': subIndex === item.children.length - 1 }"
                @mouseover="hoverItem = subItem"
                @mouseleave="hoverItem = null"
            >
              <div v-if="subItem.url" class="nav-link" @click.stop="navigateToNavItem(subItem.url)">{{ $t(`meinGenios.navigation.${subItem.label}`) }}</div>
              <div v-else>{{ $t(`meinGenios.navigation.${subItem.label}`) }}</div>
            </div>
          </div>
        </div>
      </div>
      <Button
          v-if="!isMobile"
          type="button"
          class="back-button"
          :text="$t('meinGenios.backToSearch')"
          variant="secondary"
          @click="navigateToLastWebUrl"
      />
      <ResearchPurposeNavigationComponent v-if="isMobile"/>
    </div>
    <div v-else-if="!isMobile && isCollapsed" class="collapsed-drawer">
    </div>
    <Button
        v-if="!isMobile"
        type="button"
        variant="secondary"
        :icon="isCollapsed ? 'chevron-forward-outline' : 'chevron-back-outline'"
        :toggle-text="false"
        class="expand-button"
        aria-label="toggle navigation"
        @click="toggleNav"
    />
  </div>
</template>

<script>
import ResearchPurposeNavigationComponent from "../ResearchPurpose/ResearchPurposeNavigationComponent.vue";
import Button from "../../styled/Button.vue";

export default {
  name: 'LeftNavigationDrawerComponent',
  components: {Button, ResearchPurposeNavigationComponent },
  props: {
    title: {
      type: String,
      required: true
    },
    menuItems: {
      type: Array,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isCollapsed: false,
      selectedItem: '',
      hoverItem: null,
      parentSelectedItem: null
    };
  },
  methods: {
    toggleNav() {
      this.isCollapsed = !this.isCollapsed;
    },
    selectItem(item) {
      this.selectedItem = item.label;
      this.parentSelectedItem = null;
      this.$emit('item-selected', { selectedItem: item.label });
    },
    selectSubItem(parentItem, subItem) {
      this.selectedItem = subItem.label;
      this.parentSelectedItem = parentItem.label;
      this.$emit('item-selected', { selectedItem: subItem.label, parentItem: parentItem.label });
    },
    handleItemClick(item) {
      if (item.children) {
        this.toggleItem(item);
      } else {
        this.selectItem(item);
      }
    },
    toggleItem(item) {
      this.$set(item, 'expanded', !item.expanded);
      this.$forceUpdate();
    },
    closeNav() {
      this.$emit('close-nav');
    },
    isParentOrSelfSelected(item) {
      if (this.selectedItem === item.label || this.parentSelectedItem === item.label) {
        return true;
      }
      if (item.children) {
        return item.children.some(subItem => subItem.label === this.selectedItem);
      }
      return false;
    },
    findInitialSelectedItem() {
      const currentRoute = window.location.pathname;
      if (this.menuItems) {
        for (let item of this.menuItems) {
          if (item.url && item.url === currentRoute) {
            this.selectItem(item);
            return;
          }
          if (item.children) {
            for (let subItem of item.children) {
              if (subItem.url === currentRoute) {
                this.selectSubItem(item, subItem);
                item.expanded = true;
                return;
              }
            }
          }
        }
      }
    },
    navigateToNavItem(path) {
      window.location.href = path;
    },
    onPopState() {
      this.findInitialSelectedItem();
    },
    navigateToLastWebUrl() {
      window.location.href = this.$store.getters.getLastWebUrl;
    },
  },
  watch: {
    menuItems(newItems) {
      if (newItems && newItems.length > 0) {
        this.findInitialSelectedItem();
      }
    }
  },
  beforeMount() {
    window.addEventListener('popstate', this.onPopState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.onPopState);
  },
  mounted() {
    this.findInitialSelectedItem();
  }
};
</script>
