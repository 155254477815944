// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.warning-box[data-v-b893a332] {
  display: flex;
  align-items: center;
  background-color: #fbe4e4;
  color: #3a3a3a;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
}
.alert-icon[data-v-b893a332] {
  margin-right: 10px;
  color: #3a3a3a;
  font-size: 20px;
}
.warning-text[data-v-b893a332] {
  font-size: 14px;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/common/WarningAlertComponent.vue"],"names":[],"mappings":";AAmCA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;AACA;AAEA;EACA,kBAAA;EACA,cAAA;EACA,eAAA;AACA;AAEA;EACA,eAAA;EACA,OAAA;AACA","sourcesContent":["<template>\n  <div class=\"warning-box\">\n    <ion-icon name=\"alert-circle-outline\" class=\"alert-icon\"></ion-icon>\n    <span class=\"warning-text\">\n      {{ message }}\n    </span>\n  </div>\n</template>\n\n<script>\nexport default {\n  name: 'WarningAlertComponent',\n  props: {\n    messageType: {\n      type: String,\n      required: true\n    }\n  },\n  computed: {\n    message() {\n      switch (this.messageType) {\n        case 'standard-list-deletion':\n          return this.$t('meinGenios.bookmarks.warning');\n        default:\n          return this.$t('meinGenios.bookmarks.defaultWarning');\n      }\n    }\n  },\n  mounted() {\n    this.$emit(\"register-click-listener\");\n  },\n}\n</script>\n\n<style scoped>\n.warning-box {\n  display: flex;\n  align-items: center;\n  background-color: #fbe4e4;\n  color: #3a3a3a;\n  padding: 10px 20px;\n  border-radius: 5px;\n  position: relative;\n}\n\n.alert-icon {\n  margin-right: 10px;\n  color: #3a3a3a;\n  font-size: 20px;\n}\n\n.warning-text {\n  font-size: 14px;\n  flex: 1;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
