<template>
  <div class="simple-popup__overlay">
    <div class="simple-popup__container">
      <div class="simple-popup__header">
        <h2 class="simple-popup__title">{{ title }}</h2>
        <Button
            icon="close-outline"
            area-lable="collapse"
            variant="icon"
            @click="closePopup"
            class="simple-popup__close-button"
        />
      </div>
      <div class="simple-popup__content">
        <p>{{ content }}</p>
      </div>
      <Button
          type="primary"
          @click="closePopup"
          :text="'Ok'"
      />
    </div>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";

export default {
  name: "SimplePopupComponent",
  components: {Button},
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
  },
};
</script>

<style scoped>
.simple-popup__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.simple-popup__container {
  background-color: var(--shade-light);
  box-shadow: var(--dropdown-shadow);
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
  white-space: break-spaces;
}

.simple-popup__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.simple-popup__title {
  margin: 0;
  font-size: 1.5em;
}

.simple-popup__close-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
}

.simple-popup__content {
  margin-top: 20px;
  font-size: 1em;
}
</style>
