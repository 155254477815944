<template>
  <form class="payment__template" action="/" @submit.prevent="submitSearchPurpose">
    <h1 class="payment__title">{{ $t("orderingProcess.searchPurpose.title") }}</h1>
    <p class="payment__message" v-html="message"/>
    <div v-if="this.singlePurpose" class="payment__item">
      <TextFormElement
          :passedValue="searchPurpose.fieldValue"
          :focus="searchPurpose.fieldLabel"
          :element="searchPurpose"
          :error="searchPurpose.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE'"
          @modified="searchPurpose.fieldValue = $event"
      />
      <Error v-show="searchPurpose.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE'" :error="searchPurpose.error"/>
    </div>
    <div class="payment__item" v-if="this.doublePurposes">
      <TextFormElement
          :passed-value="searchPurpose1.fieldValue"
          :focus="searchPurpose1.fieldLabel"
          :element="searchPurpose1"
          :error="searchPurpose1.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE_1'"
          @modified="searchPurpose1.fieldValue = $event"
      />
      <Error v-show="searchPurpose1.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE_1'"
             :error="searchPurpose1.error"/>
      <TextFormElement
          :passedValue="searchPurpose2.fieldValue"
          :focus="searchPurpose2.fieldLabel"
          :element="searchPurpose2"
          :error="searchPurpose2.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE_2'"
          @modified="searchPurpose2.fieldValue = $event"
      />
      <Error v-show="searchPurpose2.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE_2'"
             :error="searchPurpose2.error"/>
    </div>
    <DropdownFormElement
        v-if="this.multiPurpose && searchPurposeDropdown.options.length > 0"
        :element="searchPurposeDropdown"
        :passedValue="searchPurposeDropdown.options[0]"
        @modified="searchPurposeDropdown.fieldValue = $event.text"
    />


    <div class="payment__actions">
      <Button
          type="button"
          variant="secondary"
          :text="$t('genios.close')"
          :toggle-text="false"
          @click="$emit('close-window')"
          text-style="600"
      />
      <Button
          type="submit"
          ref="submitButton"
          :autofocus="true"
          variant="primary"
          :text="$t('genios.ok')"
          :toggle-text="false"
          text-style="600"
      />
    </div>
  </form>
</template>

<script>
import Button from "../styled/Button.vue";
import TextFormElement from "../FormElements/TextFormElement.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import i18m from "../plugins/Translations.vue"
import Error from "../styled/Error.vue";
import {SearchPurposeSplit} from "../../../functions/consts";
import {addSearchPurpose} from "../../../functions/fetching";
import store from "../../../functions/store/modules";

export default {
  name: "SearchPurposeFormComponent",
  components: {
    Error,
    DropdownFormElement,
    TextFormElement,
    Button,
    i18m
  },
  data() {
    return {
      searchPurpose: {
        id: 'purpose',
        fieldLabel: i18m.t("orderingProcess.searchPurpose.field_1"),
        fieldType: 'text',
        fieldValue: '',
        error: '',
        isError: this.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE'
      },
      searchPurpose1: {
        id: 'purpose_1',
        fieldLabel: i18m.t("orderingProcess.searchPurpose.field_1"),
        fieldType: 'text',
        fieldValue: '',
        error: '',
        isError: this.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE_1'
      },
      searchPurpose2: {
        id: 'purpose_2',
        fieldLabel: i18m.t("orderingProcess.searchPurpose.field_2"),
        fieldType: 'text',
        fieldValue: '',
        error: '',
        isError: this.error !== '' || this.code === 'ERROR_SEARCH_PURPOSE_2'
      },
      searchPurposeDropdown: {
        id: 'purpose_dropdown',
        fieldValue: '',
        options: this.searchPurposeStepModel.searchPurposes?.map(item => {
          return {text: item, value: item}
        }) || [],
        fieldLabel: i18m.t("orderingProcess.searchPurpose.field_1")
      },
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.submitButton.$el.focus();
    });
    if (this.multiPurpose) {
      this.searchPurposeDropdown.fieldValue = this.searchPurposeDropdown.options[0].text;
    }
  },
  props: {
    orderDataId: [String, Number],
    searchPurposeStepModel: Object,
    code: String,
    message: String,
    documentId: String,
  },
  methods: {
    async submitSearchPurpose() {
      const isValid = this.validate()
      if (isValid) {
        let bodyParams = {orderDataId: this.orderDataId};
        if (this.doublePurposes) {
          bodyParams.searchPurpose1 = this.searchPurpose1.fieldValue;
          bodyParams.searchPurpose2 = this.searchPurpose2.fieldValue;
        } else if (this.singlePurpose) {
          bodyParams.searchPurpose = this.searchPurpose.fieldValue;
        } else if (this.multiPurpose) {
          bodyParams.searchPurpose = this.searchPurposeDropdown.fieldValue;
        }

        let purposesToSave = bodyParams.searchPurpose;
        if (!purposesToSave) {
          purposesToSave = bodyParams.searchPurpose1 ? bodyParams.searchPurpose1 + SearchPurposeSplit.sign + bodyParams.searchPurpose2 : null;
        }
        if (purposesToSave) {
          await this.saveNewSearchPurpose(purposesToSave);
        }

        const {buy} = this.$parent

        buy({params: bodyParams})
      }
    },
    validate() {
      let isValid = true
      if (this.doublePurposes) {
        if (!this.searchPurpose1.fieldValue.trim()) {
          this.searchPurpose1.error = i18m.t("orderingProcess.searchPurpose.error", {fieldName: this.searchPurpose1.fieldLabel})
          isValid = false
        }
        if (!this.searchPurpose2.fieldValue.trim()) {
          this.searchPurpose2.error = i18m.t("orderingProcess.searchPurpose.error", {fieldName: this.searchPurpose2.fieldLabel})
          isValid = false
        }
      } else if (this.singlePurpose) {
        if (!this.searchPurpose.fieldValue.trim()) {
          this.searchPurpose.error = i18m.t("orderingProcess.searchPurpose.error", {fieldName: this.searchPurpose.fieldLabel})
          isValid = false
        }
      }
      return isValid
    },
    async saveNewSearchPurpose(purposesToSave) {
      try {
        const newSearchPurpose = await addSearchPurpose(purposesToSave);
        if (newSearchPurpose && !newSearchPurpose.error) {
          await store.dispatch('fetchSearchPurposeList');
          await store.commit('setSelectedSearchPurposeId', newSearchPurpose[0].id);
          const persistForRefresh = window.sessionVariables?.['vuexState'];
          let state = {};
          if (persistForRefresh) {
            state = { ...persistForRefresh }
          }
          state = {
            ...state,
            selectedSearchPurposeId: newSearchPurpose[0].id
          }
          // this a workaround as there are pages that trigger a new navigation after the searchPurpose is saved
          await fetch('/api/sessionVariables/vuexState', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(state)
          });
        } else {
          console.error('Error saving new search purpose');
        }
      } catch (error) {
        console.error('Failed to save search purpose:', error);
      }
    }
  },
  computed: {
    singlePurpose() {
      return this.searchPurposeStepModel.searchPurposeType === 1
    },
    doublePurposes() {
      return this.searchPurposeStepModel.searchPurposeType === 2
    },
    multiPurpose() {
      return this.searchPurposeStepModel.searchPurposeType === 3
    },
    searchPurposes() {
      return this.searchPurposeStepModel.searchPurposes
    }
  }
}
</script>
