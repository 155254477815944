// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.password-criteria[data-v-71e88e31] {
  font-size: 16px;
  padding: 0;
}
.password-criteria li[data-v-71e88e31]:not(.valid) {
  margin-left: 19px;
}
.password-criteria li.valid[data-v-71e88e31] {
  list-style: none;
  color: #10A100;
}
.checkmark-icon[data-v-71e88e31] {
  color: #10A100;
  font-size: 16px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/common/PasswordCriteriaComponent.vue"],"names":[],"mappings":";AAsCA;EACA,eAAA;EACA,UAAA;AACA;AAEA;EACA,iBAAA;AACA;AAEA;EACA,gBAAA;EACA,cAAA;AACA;AAEA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AACA","sourcesContent":["<template>\n  <ul class=\"password-criteria\">\n    <li\n        v-for=\"(criterion, index) in criteriaList\"\n        :key=\"index\"\n        :class=\"{ valid: criterion.valid }\"\n    >\n      <ion-icon\n          v-if=\"criterion.valid\"\n          name=\"checkmark-outline\"\n          class=\"checkmark-icon\"\n      ></ion-icon>\n    {{ criterion.text }}\n    </li>\n  </ul>\n</template>\n\n<script>\nexport default {\n  name: \"PasswordCriteriaComponent\",\n  props: {\n    criteria: {\n      type: Array,\n      required: true,\n    },\n  },\n  computed: {\n    criteriaList() {\n      return this.criteria.map((criterion) => ({\n        text: criterion.text,\n        valid: criterion.validation(),\n      }));\n    },\n  },\n};\n</script>\n\n<style scoped>\n.password-criteria {\n  font-size: 16px;\n  padding: 0;\n}\n\n.password-criteria li:not(.valid) {\n  margin-left: 19px;\n}\n\n.password-criteria li.valid {\n  list-style: none;\n  color: #10A100;\n}\n\n.checkmark-icon {\n  color: #10A100;\n  font-size: 16px;\n  font-weight: bold;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
