<template>

  <div v-click-outside="onClickOutside" class="watchlist_dialog delete_dialog" v-if="showIt">

    <p class="watchlist_dialog__header">
      {{ $t('genios.watchlist.deleteTitle') }}
    </p>

    <div v-if="noSelection" class="watchlist_dialog__errorBox">

      {{ $t("genios.watchlist.noSelection") }}

    </div>
    <div v-else>
      <div v-if="deleteFromList" v-html="$t(documentId && documentId.length > 1 ? 'genios.watchlist.deleteFromListTextPlural' : 'genios.watchlist.deleteFromListText',
    { 'docTitle': documentTitle })">
      </div>
      <div v-else v-html="$t(documentId && documentId.length > 1 ? 'genios.watchlist.deleteTextPlural' : 'genios.watchlist.deleteText',
    { 'docTitle': documentTitle })">
      </div>
    </div>

    <div v-if="errorCode !== null"
         class="watchlist_dialog__errorBox">

      {{ $t(errorCode) }}

    </div>


    <spinner v-if="deleting === true && errorCode === null"/>

    <div  v-if="deleting === false"
          class="watchlist_dialog__button_line">
      <Button
          type="reset"
          variant="secondary"
          @click="hideDialog"
          :text="$t('genios.login.labels.cancel')"
          :toggle-text="false"
      />
      <Button
        type="submit"
        variant="primary"
        @click="deleteBookmarks"
        :text="$t('genios.watchlist.delete')"
        :disabled="noSelection"
        :toggle-text="false"
      />
    </div>

  </div>

</template>


<script>
import vClickOutside from "v-click-outside";

import Spinner from "../styled/Spinner.vue";
import Button from "../styled/Button.vue";
import {deleteBookmarks} from "../../../functions/fetching";
import eventBus from "../../../eventBus";

export default {
  name: "WatchlistDeleteDialogComponent",
  components: {Button, Spinner},
  props: {
    sharedState: Object,
    documentId: Array,
    documentTitle: Array | String,
    deleteFromList: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showIt: true,
      deleting: false,
      errorCode: null
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    noSelection: function () {
      return !this.documentId || this.documentId.length < 1;
    }
  },
  methods: {
    removeBookmarkFromList(documentId) {
      const bookmarks = this.sharedState.watchlistDocumentIds;
      if (bookmarks !== null && this.sharedState.loginStatus.loggedIn) {
        const index = bookmarks.findIndex(item => item.bookmarkId === documentId);
        if (index > -1) {
          bookmarks.splice(index, 1);
          sessionStorage.setItem('watchlistDocumentIds', JSON.stringify(bookmarks));
        }
      }
    },
    deleteBookmarks() {
      this.deleting = true;
      deleteBookmarks(this.documentId).then(() => {
        const deletedIds = [...this.documentId];
        deletedIds.forEach(id => this.removeBookmarkFromList(id));
        this.$emit("documents-deleted");
        eventBus.$emit("update-list");
        this.hideDialog();
      }).catch(errors => {
        this.errorCode = "genios.watchlist.errorDelete";
        console.error("Delete-Watchlist-Bookmarks " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      }).finally(() => {
        this.deleting = false;
      });
    },
    onClickOutside() {
      this.hideDialog();
    },
    hideDialog() {
      this.showIt = false;
      this.$emit('closeWidget', true);
    }

  }
}

</script>


<style scoped>


</style>
