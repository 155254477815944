// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/FormElements/radio/RadioGroupComponent.vue"],"names":[],"mappings":";AA6CA;EACA,mBAAA;AACA;AAEA;EACA,sBAAA;AACA","sourcesContent":["<template>\n  <div class=\"radio__group\" :class=\"direction\">\n    <RadioFormComponent\n        v-for=\"radio in group\"\n        @modified=\"updateRadioValue\"\n        :group-value=\"value\"\n        :element=\"radio\"\n        :key=\"radio.id\"\n    />\n  </div>\n\n</template>\n\n<script>\nimport RadioFormComponent from \"./RadioFormComponent.vue\"\n\nexport default {\n  name: \"RadioGroupComponent\",\n  components: {RadioFormComponent},\n  props: {\n    group:{\n      type: Array,\n      required: true\n    },\n    direction: {\n      type: String,\n      default: 'column'\n    },\n    initialValue: String\n  },\n  data() {\n    return {\n      value: this.initialValue\n    }\n  },\n  methods: {\n    updateRadioValue(value) {\n      this.value = value;\n      this.$emit('update', value);\n    }\n  }\n}\n</script>\n\n<style>\n.row {\n  flex-direction: row;\n}\n\n.column {\n  flex-direction: column;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
