<template>
  <div class="modal-overlay" @click="handleOverlayClick" v-click-outside="onSecondaryAction">
    <div class="modal-container" ref="modalContainer" @click.stop>
      <div class="modal-header">
        <h3 class="modal-title">{{ modalData.title }}</h3>
      </div>
      <div class="modal-body">
        <p class="modal-description">{{ modalData.description }}</p>
        <div v-if="type === 'input'" class="input-container">
          <input
              v-model="inputValue"
              type="text"
              class="input-field"
          />
        </div>
        <div v-if="type === 'dropdown' && modalData.dropdownElements" class="input-container">
          <DropdownFormElement
              :element="{
                options: modalData.dropdownElements
              }"
              :passed-value="modalData.dropdownElements[0]"
              @modified="inputValue = $event"
          />
        </div>
      </div>
      <div class="modal-footer">
        <Button
            v-if="modalData.secondaryButtonText"
            :text="modalData.secondaryButtonText"
            variant="secondary"
            name="secondary"
            :toggle-text="false"
            :loading="modalData.loaderSecondary"
            @click="onSecondaryAction"
        />
        <Button
            v-if="modalData.primaryButtonText"
            :text="modalData.primaryButtonText"
            variant="primary"
            name="primary"
            :toggle-text="false"
            :loading="modalData.loaderPrimary"
            @click="onPrimaryAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/styled/Button.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";

export default {
  components: {
    DropdownFormElement,
    Button,
  },
  props: {
    modalData: {
      type: Object,
      required: true,
      default: () => ({
        title: '',
        description: '',
        primaryButtonText: '',
        secondaryButtonText: '',
        loaderPrimary: false,
        loaderSecondary: false,
        dropdownElements: null
      }),
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      inputValue: this.modalData.dropdownElements?.[0] || '',
    };
  },
  methods: {
    onPrimaryAction() {
      this.$emit('primary-action', this.inputValue);
    },
    onSecondaryAction() {
      this.$emit('secondary-action');
    },
    handleOverlayClick(event) {
      if (this.$refs.modalContainer && !this.$refs.modalContainer.contains(event.target)) {
        this.onSecondaryAction();
      }
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: absolute;
  top: 40px;
  left: auto;
  right: 0;
  min-width: fit-content;
  max-width: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 120;
}

@media screen and (max-width: 1000px) {
  .modal-overlay {
    left: 0;
    right: auto;
  }
}

.modal-container {
  background-color: var(--background-color);
  box-shadow: var(--active-shadow);
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
}

.modal-header {
  margin-bottom: 10px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-description {
  font-size: 1rem;
  color: var(--foreground-color);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.input-container {
  margin-top: 15px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid var(--foreground-color);
  font-size: 14px;
  background-color: var(--background-color);
  color: var(--foreground-color);
}
</style>
