<template>
  <div class="media_preview" :id="itemId" :data-db-shortcut="this.getSelectedDocumentDb">
    <div class="media_preview__scroll_padding"></div>
    <div class="spinner-container" v-if="this.updating">
      <spinner/>
    </div>
    <div v-else v-html="this.html"/>
  </div>
</template>

<script>

import axios from "axios";
import Spinner from "../styled/Spinner.vue";
import {setupFullDocumentFunctionality} from "../../../functions/setups";
import {fetchAndInsertDocumentInPreview, fetchFullText} from "../../../functions/fetching";
import {getDbFromUid} from "../../../functions/utils/string_utils";
import updatePrices from "../../../functions/updatePrices";
import {setupNotImplementedAlert} from "../../../functions/temp";
import {setupEbookAttachmentDownloadHandler} from "../../../functions/setups/handlers";

export default {
  name: "BundleDocumentComponent",
  components: {
    Spinner
  },
  props: {
    sharedState: Object,
    itemId: String,
    itemPaid: Boolean,
    delay: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      html: "<spinner/>",
      updating: true
    };
  },

  mounted() {
    if (this.index < 5 || !('IntersectionObserver' in window)) {
      this.updateDocumentPreview(this.itemId, this.delay);
      return;
    }

    // use intersection observer to decide if to load if our index is higher than 5
    this.observeAndLazyLoad();
  },

  methods: {
    updateDocumentPreview(id, delay = 0) {
      if (!id) {
        return;
      }
      this.updating = true;
      let path = "/getDocumentPreview";
      setTimeout( () => {
        axios.get(path, {
          params: {
            documentId: id,
            viewType: "document"
          }
        }).then((response) => {
          this.html = response.data;
          this.updating = false;
        }).catch(error => {
          console.log(error);
        });
      }, delay ? delay * 500 : 0);
    },
    updatePreviewPricesAndContent(docId) {
      let elementById = document.getElementById(docId);
      updatePrices(elementById, docId, function () {
        fetchFullText(docId);
      });
    },
    observeAndLazyLoad() {
      this.observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0) return;
        this.stopObservation();
        this.updateDocumentPreview(this.itemId);
      });
      this.observer.observe(this.$el);
    },
    stopObservation() {
      if (this.observer) {
        this.observer.unobserve(this.$el);
        this.observer = undefined;
      }
    }
  },

  computed: {
    getSelectedDocumentDb() {
      return getDbFromUid(this.itemId)
    }
  },

  watch: {
    html(newValue) {
      if (newValue) {
        this.$nextTick(()=>{
          let elementById = document.getElementById(this.itemId);
          if (this.itemPaid) {
            fetchAndInsertDocumentInPreview(this.itemId);
          } else {
            this.updatePreviewPricesAndContent(this.itemId)
            setupFullDocumentFunctionality(elementById);
          }
          setupNotImplementedAlert(elementById, this.$root);
          setupEbookAttachmentDownloadHandler(elementById);
        });
      }
    }
  },

  beforeDestroy() {
    this.stopObservation();
  }

}
</script>


<style scoped>
</style>
