<template>
  <div class="filter__title">
    <p @click.stop="toggleShow()">{{ title }}</p>
    <Button
        name="toggle"
        type="button"
        class="filter__title__button"
        v-if="hasToggle"
        @click.stop="toggleShow()"
        :aria-label="title"
        :aria-expanded="show ? 'true' : 'false'"
        variant="secondary"
        border="none"
        :toggle-text="false"
    >
      <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="{'rotate-180': show}"/>
    </Button>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";

export default {
  name: "FilterTitle",
  components: {Button},
  props: {
    title: String,
    show: Boolean,
    hasToggle: Boolean
  },
  methods: {
    toggleShow: function () {
      this.$emit('toggle-show');
    }
  }
}
</script>

<style scoped>

</style>
