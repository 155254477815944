export const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}

export const convertToZulu = (dateString) => {
    const [day, month, year] = dateString.split('.').map(Number);

    const date = new Date(Date.UTC(year, month - 1, day));

    return date.toISOString();
}