<template>
  <Button
      id="select-articles-toggler"
      class="select-toggler"
      name="select-articles-toggler"
      :text="text"
      variant="secondary"
      border="none"
      text-style="400"
      :style="buttonStyle"
      :toggle-text="false"
      @click="$emit('update:expanded', !expanded)"
  >
    <ion-icon v-if="type === 'bookmarks'" src="/icons/custom/chevron-down-outline.svg" :class="{expanded: 'rotate-180'}"/>
  </Button>
</template>

<script>
import Button from "../../../styled/Button.vue";

export default {
  name: "SelectedArticlesToggler",
  components: {Button},
  emits: ["update:expanded"],
  props: {
    amount: {
      type: Number,
      required: true
    },
    expanded: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: "bookmarks"
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    text() {
      return this.windowWidth <= 680
          ? `${this.amount}`
          : `${this.amount} ${this.type !== 'bookmarks' ? this.$t('genios.lists') : this.$t('genios.selected')}`;
    },
    buttonStyle() {
      return this.type !== 'bookmarks' ? { backgroundColor: 'transparent' } : {};
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  }
}
</script>