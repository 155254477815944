<template>
  <div>
    <div class="right-drawer">
      <div v-if="localMenuItems.length > 0">
        <div
            v-for="(item, index) in localMenuItems"
            :key="index"
            class="menu-item-wrapper"
        >
          <div
              class="navigation-options"
              @click="handleItemClick(item)"
              :class="{
                'selected': isParentOrSelfSelected(item),
                'hovered': hoverItem === item.label || (hoverItem && item.children && item.children.includes(hoverItem))
              }"
              @mouseover="hoverItem = item.label"
              @mouseleave="hoverItem = null"
          >
            <div v-if="item.url" class="nav-link" @click.stop="navigateToNavItem(item.url)">
              {{ $t(`meinGenios.navigation.${item.label}`) }}
            </div>
            <div v-else>{{ $t(`meinGenios.navigation.${item.label}`) }}</div>
            <ion-icon
                v-if="item.children"
                :name="item.expanded ? 'chevron-up-outline' : 'chevron-down-outline'"
                @click.stop="toggleItem(item)"
            ></ion-icon>
          </div>
          <div v-if="item.expanded && item.children" class="sub-items">
            <div
                class="navigation-sub-options"
                v-for="(subItem, subIndex) in item.children"
                :key="subIndex"
                @click.stop="selectSubItem(item, subItem)"
                :class="{
                  'selected': selectedItem === subItem.label,
                  'hovered': hoverItem === subItem,
                  'last-sub-item': subIndex === item.children.length - 1
                }"
                @mouseover="hoverItem = subItem"
                @mouseleave="hoverItem = null"
            >
              <div v-if="subItem.url" class="nav-link" @click="navigateToNavItem(subItem.url)">
                {{ $t(`meinGenios.navigation.${subItem.label}`) }}
              </div>
              <div v-else>{{ $t(`meinGenios.navigation.${subItem.label}`) }}</div>
            </div>
          </div>
        </div>
      </div>
      <ResearchPurposeNavigationComponent/>
    </div>
  </div>
</template>


<script>
import ResearchPurposeNavigationComponent from "../ResearchPurpose/ResearchPurposeNavigationComponent.vue";

export default {
  name: 'RightNavigationDrawerComponent',
  components: { ResearchPurposeNavigationComponent },
  props: {
    menuItems: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selectedItem: '',
      hoverItem: null,
      parentSelectedItem: null,
    };
  },
  computed: {
    localMenuItems() {
      return this.menuItems.map(item => ({ ...item }));
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item.label;
      this.parentSelectedItem = null;
      this.$emit('item-selected', { selectedItem: item.label });
    },
    selectSubItem(parentItem, subItem) {
      this.selectedItem = subItem.label;
      this.parentSelectedItem = parentItem.label;
      this.$emit('item-selected', { selectedItem: subItem.label, parentItem: parentItem.label });
    },
    handleItemClick(item) {
      if (item.children) {
        this.toggleItem(item);
      } else {
        this.selectItem(item);
      }
    },
    toggleItem(item) {
      this.$set(item, 'expanded', !item.expanded);
      this.$forceUpdate();
    },
    isParentOrSelfSelected(item) {
      if (this.selectedItem === item.label || this.parentSelectedItem === item.label) {
        return true;
      }
      if (item.children) {
        return item.children.some(subItem => subItem.label === this.selectedItem);
      }
      return false;
    },
    findInitialSelectedItem() {
      const currentRoute = window.location.pathname;
      for (let item of this.localMenuItems) {
        if (item.url && item.url === currentRoute) {
          this.selectItem(item);
          return;
        }
        if (item.children) {
          for (let subItem of item.children) {
            if (subItem.url === currentRoute) {
              this.selectSubItem(item, subItem);
              item.expanded = true;
              return;
            }
          }
        }
      }
    },
    navigateToNavItem(path) {
      window.location.href = path;
    },
    onPopState() {
      this.findInitialSelectedItem();
    }
  },
  beforeMount() {
    window.addEventListener('popstate', this.onPopState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.onPopState);
  },
  mounted() {
    this.findInitialSelectedItem();
  }
};
</script>
