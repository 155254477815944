<template>
  <div class="header-layout--action-bar--layout">
    <selected-articles-toggler
        ref="selectArticlesToggler"
        v-show="selectedArticles.length"
        :expanded="expanded"
        :amount="selectedArticles.length"
        @update:expanded="expanded = $event"
        v-click-outside="onClickOutside"
    ></selected-articles-toggler>
    <action-bar
        :action-bar-buttons="actionBarButtons"
        :selected-list="selectedArticles"
        :all-documents-on-page="allDocumentsOnPage"
        @update-prices="$emit('update-prices', $event)"
        @update-list="$emit('update-list')"
    ></action-bar>
    <selected-view
        v-show="expanded && selectedArticles.length > 0"
        :selected-articles="selectedArticles"
        @remove-item="removeItem"
        @remove-all="removeAll"
    ></selected-view>
  </div>
</template>

<script>
import Button from "../../styled/Button.vue";
import SelectedView from "./components/SelectedView.vue";
import SelectedArticlesToggler from "./components/SelectedArticlesToggler.vue";
import ActionBar from "./ActionBar.vue";

export default {
  name: "ActionBarLayout",
  components: {SelectedArticlesToggler, SelectedView, ActionBar, Button},
  data() {
    return {
      expanded: false,
    }
  },
  props: {
    actionBarButtons: {
      type: Array,
      required: true,
      default: []
    },
    selectedArticles: {
      type: Array,
      required: false,
      default: []
    },
    allDocumentsOnPage: {
      type: Array,
      required: false,
      default: []
    }
  },
  methods: {
    removeItem({index}) {
      this.$delete(this.selectedArticles, index);
    },
    removeAll() {
      this.expanded = false;
      this.$emit('remove-all')
    },
    onClickOutside() {
      this.expanded = false;
    },
  }
}
</script>