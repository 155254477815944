<template>
  <div class="enter">
    <!--suppress HtmlUnknownTarget -->
    <form class="enter__form login_form"
          :aria-label="solution"
          @submit="checkAndSendForm($event, submitData, {invalidUsernameError: emailError})">
      <div class="enter__header">
        <div v-html="$t(displayReason)"></div>
      </div>

      <TextFormElement
          :element="inputUsername"
          :focus="focus"
          :passed-value="inputUsername.value"
          :error="(!!inputUsername.error || !!anyErrors)"
          v-on:focus="focus = $event"
          v-on:modified="onModifiedUsername($event); submitData['username'] = $event"
      />
      <div class="enter__error">{{ inputUsername.error }}</div>


      <TextFormElement
          htmlInputFieldType="password"
          :element="inputPassword"
          :focus="focus"
          :passed-value="inputPassword.value"
          :error="(!!inputPassword.error || !!anyErrors)"
          v-on:focus="focus = $event"
          v-on:modified="onModifiedPassword($event); submitData.password = $event"
      />
      <div class="enter__error">{{ inputPassword.error }}</div>


      <div class="enter__error">{{ anyErrors }}</div>

      <a :href="getPasswordForgottenPath()" class="password_forgotten">{{
          $t('genios.login.labels.passwordForgot')
        }}</a>

      <CheckboxFormElement :element="rememberMeCheckboxObj"
                           :focus="focus"
                           v-on:focus="focus = $event"
                           @blur="focus = null"
                           @modified="rememberMeCheckboxObj.passedValue = $event; submitData.rememberMe = $event"/>

      <div class="enter__form__submit_container">
        <Button
            type="button"
            :text="$t('genios.login.labels.register')"
            variant="secondary--filled"
            :toggle-text="false"
            @click="openWebUserRegistration"
            style="margin-right: auto;"
        />
        <Button
            type="button"
            @click="closeModal"
            :text="$t('genios.login.labels.cancel')"
            variant="secondary"
            :toggle-text="false"
            style="margin-right: 15px;"
        />
        <Button
            name="submit"
            id="submit"
            :loading="loading"
            type="submit"
            variant="primary"
            :text='$t("genios.login.buttons.login")'
            :toggle-text="false"
            style="margin: 0;"
        />
      </div>
    </form>
  </div>
</template>

<script>
import TextFormElement from "../FormElements/TextFormElement.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import Button from "../styled/Button.vue";
import loginMixin from "../../../functions/mixins/loginMixin";
import i18n from "../plugins/Translations.vue";

export default {
  name: "WebUserLoginComponent",
  mixins: [loginMixin],
  components: {Button, CheckboxFormElement, TextFormElement},
  props: {
    callback: Function,
    displayReason: String,
    sharedState: Object
  },
  data() {
    return {
      focus: null,
      submitData: {
        username: "",
        password: "",
        embedded: true,
        rememberMe: false
      },
      inputUsername: {
        id: 1,
        field: 'username',
        fieldType: 'TEXT',
        fieldLabel: i18n.t("genios.login.labels.groupUsername"),
        value: "",
        error: ""
      },
      inputPassword: {
        id: 2,
        field: 'password',
        fieldType: 'TEXT',
        fieldLabel: i18n.t("genios.login.labels.password"),
        value: "",
        error: ""
      },
      emailError: i18n.t("genios.login.errors.email"),
    };
  },
  computed: {
    solution: function() {
      return this.sharedState.solution;
    },
  },
  }
</script>

<style scoped>

</style>