export default function persistedState(store) {
    let lastState = {};
    let isStateUpdated = false;

    store.subscribe((mutation, state) => {
        const stateToPersist = {
            selectedSearchPurposeId: state.selectedSearchPurposeId,
            selectedBookmarkList: state.selectedBookmarkList,
            userInteractionSearchFilterDisplaySettings: state.userInteractionSearchFilterDisplaySettings,
            searchFilterDisplaySettings: state.searchFilterDisplaySettings,
            searchResultSideNavLastPosition: state.searchResultSideNavLastPosition,
            searchResultSettings: state.searchResultSettings,
            showPanel: state.showPanel,
            lastWebUrl: state.lastWebUrl,
        };

        if (state.bookmarksModule) {
            stateToPersist.bookmarksModule = {
                selectedBookmarks: state.bookmarksModule.selectedBookmarks,
                sortSize: state.bookmarksModule.sortSize,
                sortBy: state.bookmarksModule.sortBy,
                sortOrder: state.bookmarksModule.sortOrder,
                currentPage: state.bookmarksModule.currentPage,
            };
        }

        if (state.documentHistoryModule) {
            stateToPersist.documentHistoryModule = {
                selectedDocumentHistory: state.documentHistoryModule.selectedDocumentHistory,
                sortSize: state.documentHistoryModule.sortSize,
                sortBy: state.documentHistoryModule.sortBy,
                sortOrder: state.documentHistoryModule.sortOrder,
                currentPage: state.documentHistoryModule.currentPage,
            };
        }

        if (state.searchHistoryModule) {
            stateToPersist.searchHistoryModule = {
                selectedSearchHistory: state.searchHistoryModule.selectedSearchHistory,
                currentPage: state.searchHistoryModule.currentPage,
            };
        }

        if (JSON.stringify(stateToPersist) !== JSON.stringify(lastState)) {
            lastState = stateToPersist;
            isStateUpdated = true;
        }
    });

    setInterval(async () => {
        if (isStateUpdated) {
            isStateUpdated = false;
            try {
                await fetch('/api/sessionVariables/vuexState', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(lastState)
                });
            } catch (error) {
                console.log('Error saving session variable to backend:', error);
            }
        }
    }, 500);
}
