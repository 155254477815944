<template>
  <checkbox-form-element
      class="header-layout--checkbox"
      :bold-on-selection="false"
      :key="selectAll.id"
      :element="selectAll"
      :passed-value="selectAll.value"
      :focus="focus"
      @focus="$emit('update:focus', $event)"
      @modified="handleSelectDeselect($event)"
  ></checkbox-form-element>
</template>


<script>
import CheckboxFormElement from "../../FormElements/CheckboxFormElement.vue";

export default {
  name: "SelectAll",
  components: {CheckboxFormElement},
  emits: ['update:selected-all', 'update:focus'],
  props: {
    focus: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },
    isAllSelected: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      selectAll: {
        id: 'selectAll',
        field: 'selectAll',
        fieldLabel: this.$i18n.t(`headerLayout.selectAll`),
        fieldType: 'text',
        value: this.isAllSelected
      }
    }
  },
  watch: {
    isAllSelected(newVal, oldVal) {
      if (!_.isEqual(newVal, oldVal)) {
        this.selectAll.value = newVal;
      }
    }
  },
  methods: {
    handleSelectDeselect(event) {
      if (!this.selectAll.value && !event) {
        this.selectAll.value = event;
      } else {
        this.selectAll.value = event;
        this.$emit('update:selected-all', event)
      }
    }
  }
}
</script>