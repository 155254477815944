<template>
  <transition name="toggle">
    <div class="advancedSearch">
      <div class="border-line"></div>
      <form class="advancedSearch__form"
            @submit="checkForm"
            @reset="reset"
            autocomplete="off">
        <template v-for="element in searchMask.elements">
          <TextFormElement
              :key="element.fieldLabel"
              v-if="element.fieldType === 'TEXT'"
              :element="element"
              :passedValue="fields[element.fieldLabel]"
              :focus="focus"
              v-on:focus="focus = $event"
              v-on:modified="updateFields(element.fieldLabel, $event)"
          />
          <CheckboxFormElement
              :key="element.fieldLabel"
              v-else-if="element.fieldType === 'CHECKBOX'"
              :element="element"
              :passedValue="fields[element.fieldLabel]"
              :focus="focus"
              v-on:focus="focus = $event"
              v-on:modified="updateFields(element.fieldLabel, $event)"
          />
          <DropdownFormElement
              :key="element.fieldLabel"
              v-else-if="element.fieldType === 'DROPDOWN'"
              :element="element"
              :focus="focus"
              v-on:focus="focus = $event"
              v-on:modified="updateFields(element.fieldLabel, $event.text)"
              :passedValue="getAdvancedSearchOption(element)"
              :scroll-into-view="true"
          />
          <CheckboxGroupFormElement
              :key="element.fieldLabel"
              v-else-if="element.fieldType === 'CHECKBOX_GROUP'"
              :element="element"
              :passedValue="fields[element.fieldLabel]"
              :focus="focus"
              v-on:focus="focus = $event"
              v-on:modified="updateFields(element.fieldLabel, $event)"
          />
          <RangeFormElement
              :key="element.fieldLabel"
              v-else-if="element.fieldType === 'RANGE'"
              :element="element"
              :passedValue="fields[element.fieldLabel]"
              :focus="focus"
              v-on:focus="focus = $event"
              v-on:modified="updateFields(element.fieldLabel, $event)"
          />
        </template>
        <div class="advancedSearch__form__footer">
          <Button
              name="submit"
              :text="$t('genios.words.search')"
              variant="primary"
              position="left"
              type="submit"
              icon="search-outline"
              :toggle-text="false"
              class="advanced_search_btn"
          />
          <Button
              name="reset"
              :text="$t('genios.input.clear')"
              variant="secondary"
              type="reset"
              :toggle-text="false"
              @click.stop="clearFilters()"
          />
        </div>
      </form>

    </div>
  </transition>
</template>

<script>
import TextFormElement from "../FormElements/TextFormElement.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import RangeFormElement from "../FormElements/RangeFormElement.vue";
import CheckboxGroupFormElement from "../FormElements/CheckboxGroupFormElement.vue";
import Button from "../styled/Button.vue";
import {fetchSearchMask} from "../../../functions/fetching";
import {replaceSearchResult} from "../../../functions/replacing";

// noinspection JSUnusedLocalSymbols
export default {
  name: "AdvancedSearchComponent",
  components: {
    Button,
    CheckboxGroupFormElement,
    RangeFormElement,
    DropdownFormElement,
    CheckboxFormElement,
    TextFormElement
  },
  props: {
    sidePanelPosition: String,
    sourceFilters: Array,
    categoryFilters: Array,
    advancedSearchParams: Array
  },
  data() {
    return {
      searchMask: {},
      focus: null,
      fields: {},
      showOverlayScrollbarSection: false,
    };
  },
  computed: {
    elements: function () {
      if (!this.searchMask) return [];
      return this.searchMask.elements;

    },
    elementMap: function () {
      let map = {};
      this.elements.forEach(element => {
        map[element.fieldLabel] = element;
      });
      return map;
    },
    newAdvancedSearchParams: function () {
      let params = [];
      Object.keys(this.fields).forEach(key => {
        const element = this.elementMap[key];
        const field = this.fields[key];
        if (element && field) {
          if (typeof field === 'object') {
            Object.keys(field).forEach(key2 => {
              const value = field[key2];
              if (value) {
                params.push(`${element.id}-${element.fieldLabel}_${key2}_${value}`);
              }
            });
          } else {
            params.push(`${element.id}-${element.fieldLabel}_${field}`);
          }
        }
      });
      return params;
    }
  },
  mounted() {
    this.showOverlayScrollbarSection = true;
    this.$nextTick(() => {
      this.loadSearchMask();
      this.setFieldsFromParams();
    });

  },
  watch: {
    sourceFilters: function () {
      this.loadSearchMask();
    },
    categoryFilters: function () {
      this.loadSearchMask();
    },
    advancedSearchParams: function () {
      this.setFieldsFromParams();
    },
    elements: function () {
      if (!this.elements || this.elements.length === 0) {
        this.$emit("empty-search-masks", true);
      } else {
        this.$emit("empty-search-masks", false);
      }
    },
  },
  methods: {
    clearFilters() {
      this.$emit('clearAdvancedSearch');
    },
    substringAfter(string, splitter) {
      return string.split(splitter).splice(1).join(splitter);
    },
    getAdvancedSearchOption(element) {
      const advancedParameter = window.sharedState.advancedSearchParams;
      if (advancedParameter.length) {
        const queryIndex = advancedParameter.findIndex(item => this.substringAfter(item, '_') === this.fields[element.fieldLabel]);
        if (queryIndex !== -1) {
          const queryParam = advancedParameter[queryIndex];
          const optionIndex = element.options.findIndex(item => item.text === this.substringAfter(queryParam, '_'));
          return optionIndex !== -1 ? element.options[optionIndex] : element.options[0];
        } else return element.options[0];
      } else return element.options[0];
    },
    reset: function () {
      this.fields = {};
    },
    performSearch: function () {
      window.sharedState.advancedSearchParams = this.newAdvancedSearchParams;
      // take changes to the search component searchString before searching.
      window.sharedState.queryString = window.sharedState.searchComponentSearchString
      window.sharedState.offset = 0;
      replaceSearchResult();
    },
    loadSearchMask: function () {
      fetchSearchMask(this.setSearchMaskCallback);
    },
    setSearchMaskCallback: function (searchMask) {
      this.searchMask = searchMask;
    },
    updateFields: function (id, value) {
      let newFields = {...this.fields};
      newFields[id] = value;
      this.fields = newFields;
    },
    setFieldsFromParams: function () {
      let newFields = {};
      this.advancedSearchParams.forEach(param => {
        const paramSplit = param.split('_');
        const label = this.substringAfter(paramSplit[0], '-');
        const fromToIndexOrValue = paramSplit[1];
        if (paramSplit.length > 2) {
          if (newFields[label] === undefined) {
            newFields[label] = {};
          }
          newFields[label][fromToIndexOrValue] = paramSplit[2];
        } else {
          newFields[label] = fromToIndexOrValue;
        }
      });
      this.fields = newFields;
    },
    checkForm: function (e) {
      this.performSearch();
      e.preventDefault();
    }
  }
};
</script>

<style scoped>
.advanced_search_btn {
  padding: 6px 9px;
}
</style>
