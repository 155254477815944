<!--suppress HtmlUnknownTarget -->
<template>
  <div class="filter" v-if="type !== 'category' || sharedState.urlSecondLevel">
    <filter-title
        has-toggle
        :show="show"
        :title="title"
        key="filter-title"
        @toggle-show="toggleShow()"
    />
    <transition name="grow">
      <div class="filter__item_container" v-if="show">
        <div class="filter__item_search" v-if="showSearch">
          <div class="filter__item_search__box">
            <input
                type="text"
                :placeholder="searchPlaceholder"
                v-model="searchInput"
                v-on:keyup.enter="onEnter"
            >
            <Button
                type="button"
                v-if="searchInput"
                role="button"
                variant="icon"
                aria-pressed="false"
                icon="close-outline"
                @click="searchInput = ''"
                @keydown.enter.prevent="searchInput = ''"
                @keydown.space="searchInput = ''"
            />
            <Button
                type="button"
                v-if="!searchInput"
                icon="filter-outline"
                variant="icon"
            />
          </div>
        </div>
        <div is="transition-group" name="flip-list" class="filter__item_container__list">
          <checkbox-form-element
              v-for="(value, name, index) in displayValuesPart1"
              :key="name"
              :name="name"
              :bold-on-selection="true"
              :element="{id:`part1-${index}-${type}`, fieldLabel:formattedLabel(name, value.count)}"
              :passed-value="updateCheckedFromGlobalParams(name)"
              v-on:modified="updateCheckedFromGlobalParams(name) !== $event? selected(name) : null"
          />
          <checkbox-form-element
              v-for="(value, name, index) in displayValuesPart2"
              v-if="showMore"
              :key="name"
              :name="name"
              :bold-on-selection="true"
              :element="{id:`part2-${index}-${type}`, fieldLabel:formattedLabel(name, value.count)}"
              :passed-value="updateCheckedFromGlobalParams(name)"
              v-on:modified="updateCheckedFromGlobalParams(name) !== $event? selected(name) : null"
          />
        </div>
        <div class="filter__item_container__spinner_container" v-if="updating">
          <spinner/>
        </div>
        <show-more
            key="filter-show-more"
            v-if="show && !updating && Object.keys(displayValuesPart2).length > 0"
            :length="numberOfDisplayValues"
            :show-more="showMore"
            v-on:toggle-show-more="toggleShowMore()"
        />
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import ShowMore from "./ShowMore.vue";
import FilterTitle from "./FilterTitle.vue";
import axios from "axios";
import Spinner from "../styled/Spinner.vue";
import Button from "../styled/Button.vue";
import {fetchCountsForNavigationItems} from "../../../functions/fetching";
import {buildPath, buildSearchParametersFromCurrentState} from "../../../functions/utils/url_utils";
import _ from "lodash";
import qs from "qs";
import {toggleSearchResultActiveFilter} from "../../../functions/components/filters";
import {getAuthCookieCacheBuster} from "../../../functions/utils/cookie_utils";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import store from "../../../functions/store/modules";

// noinspection JSUnusedLocalSymbols
export default {
  name: "CheckBoxFilter",
  components: {CheckboxFormElement, Button, Spinner, FilterTitle, ShowMore},
  props: {
    title: String,
    searchPlaceholder: String,
    type: String,
    maxNumber: Number,
    sharedState: Object
  },
  data: function () {
    return {
      show: false,
      forceShowMore: undefined,
      showStorageKey: '',
      showMoreStorageKey: '',
      searchInput: '',
      suggestions: {},
      suggestionsCancelSource: axios.CancelToken.source(),
      updating: false,
      cancelToken: null,
      lastSourceFilterParams: {},
      emptyZFilters: {
        industry: {},
        company: {},
        topic: {},
        region: {},
        person: {},
        author: {},
        instructor: {}
      },
      lastZfilterParams:{
        all: {},
        industry: {},
        company: {},
        topic: {},
        region: {},
        person: {},
        author: {},
        instructor: {}
      }
    }
  },
  computed: {
    userSettings: function () {
      return store.getters.getUserInteractionSearchFilterDisplaySettings;
    },
    showCategory: function () {
      return this.userSettings?.categoryFilterOpened || false;
    },
    filters: function () {
      return this.sharedState.filters || {};
    },
    rawFilters: function () {
      return this.filters[this.type] || {};
    },
    values: function () {
      let myValues = this.rawFilters;

      //add any already selected which aren't here with count 0 to end
      let activeButMissingFilter = {};
      if (this.active !== null && this.active !== undefined) {
        this.active
            .filter(key => !Object.keys(myValues).includes(key))
            .sort()
            .forEach(key => activeButMissingFilter[key] = {count: 0})
      }

      let valuesToReturn = {...myValues, ...activeButMissingFilter};
      return Object.keys(valuesToReturn)
          .sort((a, b) => {
            let aIsActive = this.active.includes(a) ? 1 : 0;
            let bIsActive = this.active.includes(b) ? 1 : 0;
            if (aIsActive !== bIsActive) return bIsActive - aIsActive
            return valuesToReturn[b].count - valuesToReturn[a].count
          })
          .reduce((result, key) => {
            result[key] = valuesToReturn[key];
            return result;
          }, {});
    },
    active: function () {
      return this.activeFilters[this.type];
    },
    inputWords: function () {
      return this.searchInput.toLowerCase().match(/[\wäöü]+|\w+/g);
    },
    searchActive: function () {
      return this.inputWords !== null && this.inputWords.length > 0;
    },
    suggestAndActive: function () {
      // there is input - build suggestions and add already selected to end
      const activeValues = Object.keys(this.values)
          .filter(key => this.active.includes(key))
          .reduce((obj, key) => {
            obj[key] = this.values[key];
            return obj;
          }, {});

      // merge with already known to get counts where possible
      let fullSuggestions = {...this.suggestions, ...this.rawFilters};

      fullSuggestions = Object.keys(fullSuggestions)
          .filter(key => {
            // only keep es results
            return Object.keys(this.suggestions).includes(key);
          })
          .reduce((obj, key) => {
            obj[key] = fullSuggestions[key];
            return obj;
          }, {});

      let fullMatching = {...fullSuggestions, ...activeValues};
      return Object.keys(fullMatching)
          .sort((a, b) => {
            let aIsActive = this.active.includes(a) ? 1 : 0;
            let bIsActive = this.active.includes(b) ? 1 : 0;
            return aIsActive - bIsActive
          })
          .reduce((result, key) => {
            result[key] = fullMatching[key];
            return result;
          }, {});
    },
    valuesAndActive: function () {
      // display at least all selected, or normal maximum
      const maxNumberToShow = Math.max(this.active.length, this.maxNumber)
      let numberOfActiveValues = Object.keys(this.values)
          .filter(key => this.active.includes(key)).length;
      //only display normally MaxNumber at most but always include active values
      let countNonMatching = 0
      return Object.keys(this.values)
          .filter((key) => {
            let isActive = this.active.includes(key);
            let keep = isActive || countNonMatching < (maxNumberToShow - numberOfActiveValues);
            if (!isActive) countNonMatching++;
            return keep;
          })
          .reduce((result, key) => {
            result[key] = this.values[key];
            return result;
          }, {});
    },
    displayValuesPart1: function () {
      if (this.searchActive) {
        return this.suggestAndActive; // no split
      }
      return Object.keys(this.valuesAndActive)
          .filter((key, index) => index < 7)
          .reduce((result, key) => {
            result[key] = this.valuesAndActive[key];
            return result;
          }, {});
    },
    displayValuesPart2: function () {
      if (this.searchActive) {
        return {}; // no split
      }
      return Object.keys(this.valuesAndActive)
          .filter((key, index) => index >= 7)
          .reduce((result, key) => {
            result[key] = this.valuesAndActive[key];
            return result;
          }, {});
    },
    numberOfDisplayValues: function () {
      if (this.valuesAndActive === undefined) return 0;
      return Object.keys(this.valuesAndActive).length;
    },
    showSearch: function () {
      return this.type !== "category";
    },
    showMore: function () {
      if (this.forceShowMore !== undefined) {
        return this.forceShowMore;
      }
      let showMore = false;
      if (this.displayValuesPart2) {
        const part2HasActive = Object.keys(this.displayValuesPart2).filter(value => this.active.includes(value));
        if (part2HasActive.length) {
          showMore = true;
        }
      }
      return showMore;
    },
    activeFilters: function () {
      return this.sharedState.activeFilters;
    },
    advancedSearchParams: function () {
      return this.sharedState.advancedSearchParams;
    },
    requestText: function () {
      return this.sharedState.queryString;
    },
    openCheckboxFilter: function () {
      return this.sharedState.openCheckboxFilter;
    }
  },
  mounted() {
    this.$nextTick(function () {
      if (this.openCheckboxFilter === this.type) {
        this.show = true;
      }
      if (this.type === "category") {
        this.show = this.showCategory;
      }
      this.updateFilters();
    })
  },
  watch: {
    openCheckboxFilter: function (value) {
      if (this.type === "category") {
        store.commit('setUserInteractionSearchFilterDisplaySettings',
          {
            ...this.userSettings,
            categoryFilterOpened: value === this.type,
            userInteracted: true
        })
      } else {
        this.show = value === this.type;
      }
    },
    showCategory(newVal) {
      if (this.type === 'category') {
        this.show = newVal;
      }
    },
    searchInput: function (val) {
      if (this.searchActive) {
        this.getSuggestions(val);
      } else {
        this.suggestions = {};
      }
    },
    rawFilters: function (val) {
      this.updating = false;
      if (this.searchActive) {
        this.getSuggestions(this.searchInput);
      }
    },
    requestText: function (newValue) {
      setTimeout(() => {
        this.updateFilters();
      }, 200);
    },
    activeFilters: {
      handler(val) {
        setTimeout(() => {
          this.updateFilters();
        }, 100);
      },
      deep: true
    },
    advancedSearchParams: {
      handler(val) {
        setTimeout(() => {
          this.updateFilters();
        }, 100);
      },
      deep: true
    }
  },
  methods: {
    toggleShow: function () {
      this.show = !this.show;
      this.updateFilters();
      if (this.type === "category") {
        store.commit('setUserInteractionSearchFilterDisplaySettings',
          {
            ...this.userSettings,
            categoryFilterOpened: this.show,
            userInteracted: true
        })
      } else if (this.show) {
        this.sharedState.openCheckboxFilter = this.type;
      }
    },
    getSourceFilters () {
      let path = buildPath('/api/searchResult');
      let generalParams = buildSearchParametersFromCurrentState();
      delete generalParams.offset; // no offset
      delete generalParams.source; // we don't want selected sources to restrict
      let paramsForSourceFilters = {
        ...generalParams,
        size: 0,
        getSources: true
      };
      if (_.isEqual(this.lastSourceFilterParams, paramsForSourceFilters)) {
        window.sharedState.filters = {
          ...window.sharedState.filters
        }
        return;
      }
      this.lastSourceFilterParams = _.cloneDeep(paramsForSourceFilters);
      axios.get(path, {
        params: paramsForSourceFilters,
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: "repeat"})
        }
      }).then((response) => {
        if (response.data.aggregations) {
          window.sharedState.filters.source = {};
          window.sharedState.filters = {
            ...window.sharedState.filters,
            ...response.data.aggregations
          };
        }
      }).catch(error => {
        console.log(error);
      });
    },
    getZFilters(type = "all") {
      let path = buildPath('/api/searchResult');
      let generalParams = buildSearchParametersFromCurrentState();
      delete generalParams.offset;
      let paramsForZFieldFilters = {
        ...generalParams,
        size: 0,
        getFilters: type === "all" ? "true" : type
      };
      if (_.isEqual(this.lastZfilterParams[type], paramsForZFieldFilters)) {
        window.sharedState.filters = {...window.sharedState.filters};
        return;
      }
      this.lastZfilterParams[type] = _.cloneDeep(paramsForZFieldFilters);
      axios.get(path, {
        params: paramsForZFieldFilters,
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: "repeat"})
        }
      }).then(response => {
        if (!!response.data.aggregations) {
          let resetObject = {};
          if (type === "all") {
            resetObject = this.emptyZFilters;
          } else {
            resetObject[type] = {};
          }
          window.sharedState.filters = {
            ...window.sharedState.filters,
            ...resetObject,
            ...response.data.aggregations
          };
        }
      }).catch(error => {
        console.log(error);
      });
    },
    toggleShowMore: function () {
      this.forceShowMore = !this.showMore;
    },
    updateFilters: function () {
      if (this.show) {
        this.updating = true;
        if (this.type === "category") {
          const textIdsForCategoryFilter = Object.values(this.sharedState.filters.category).map(filter => filter.textId);
          fetchCountsForNavigationItems(textIdsForCategoryFilter, true);
        } else if (this.type === "source") {
          this.getSourceFilters();
        } else {
          this.getZFilters(this.type);
        }
      }
    },
    selected: function (name) {
      toggleSearchResultActiveFilter(name, this.type);
    },
    onEnter: function () {
      if (Object.keys(this.suggestions).length === 1) {
        this.selected(Object.keys(this.suggestions)[0]);
        this.searchInput = '';
        this.suggestions = {};
      }
    },
    getSuggestions: function (val) {
      let path = '/api/suggestFilter';
      let urlTopLevelNavigation = this.sharedState.urlTopLevelNavigation;
      let urlSecondLevel = this.sharedState.urlSecondLevel;
      if (urlTopLevelNavigation !== undefined && urlTopLevelNavigation !== null) path = path + '/' + urlTopLevelNavigation;
      if (urlSecondLevel !== undefined && urlSecondLevel !== null && urlSecondLevel !== 'undefined') path = path + '/' + urlSecondLevel;

      // use auth cookie as cache busting trick for user switching etc.
      let params = {
        requestText: val,
        type: this.type,
        auth: getAuthCookieCacheBuster(),
      };
      if (this.cancelToken) {
        this.suggestionsCancelSource.cancel('new input');
        this.suggestionsCancelSource = axios.CancelToken.source(); // new source
      }
      this.cancelToken = this.suggestionsCancelSource.token;
      //this.suggestions = {};
      axios.get(path, {
        params: params,
        cancelToken: this.cancelToken
      }).then(response => {
        this.suggestions = Object.keys(response.data)
            .reduce((obj, key) => {
              if (Object.keys(this.rawFilters).length < 2000) {
                obj[key] = {count: 0};
              } else {
                obj[key] = {count: undefined};
              }
              return obj;
            }, {});
      }).catch(errors => {
        console.log(errors);
      }).finally(() => {
        this.cancelToken = null;
      });
    },
    formattedCount: function (count) {
      return count === undefined ? '...' : count.toLocaleString('de-DE');
    },
    updateCheckedFromGlobalParams: function (name) {
      return this.active === undefined ? false : this.active.includes(name);
    },
    formattedLabel(name, count){
      return `<span class='filter__item__name'>${name}</span><span class='filter__item__count count-color'>${this.formattedCount(count)}</span>`
    }
  }
}
</script>

<style scoped>
</style>
