<template>

  <div v-click-outside="onClickOutside" class="watchlist_dialog add_dialog" v-if="showIt">

    <p class="watchlist_dialog__header">{{ $t("genios.watchlist.addTitle") }}</p>
    <div class="info">{{ $t("genios.watchlist.addDescription") }}</div>

    <div v-if="noDocumentsSelected"
         class="watchlist_dialog__errorBox">

      {{ $t("genios.watchlist.noSelection") }}

    </div>
    <div v-else>

      <div class="watchlistCollection">

        <spinner v-if="watchlistCollection === null && errorCode === null"/>

        <checkbox-form-element
            v-else
            v-for="watchlist in watchlistCollectionCheckBox"
            :key="watchlist.id"
            :element="{...watchlist, fieldLabel: watchlist.name}"
            :passed-value="isSelected(watchlist.id)"
            @modified="toggleSelection(watchlist.id)"/>


        <div v-if="watchlistCollection !== null && watchlistCollection.length>0"
             class="watchlistSelection">
          <v-select
              :searchable="false"
              :clearable="false"
              v-model="newSelected"
              :value="newSelected"
              label="name"
              :options="watchlistCollection">
            <template #open-indicator="{ attributes }">
              <ion-icon v-bind="attributes" src="/icons/custom/chevron-down-outline.svg"/>
            </template>
          </v-select>
        </div>
      </div>
    </div>


    <div v-if="errorCode !== null"
         class="watchlist_dialog__errorBox">

      {{ $t(errorCode) }}

    </div>

    <div class="watchlist_dialog__button_line">
      <Button
          name="close"
          variant="secondary"
          @click="hideDialog"
          :text="$t('genios.close')"
          :toggle-text="false"
      />
      <Button
          name="save"
          variant="primary"
          @click="saveElements"
          :text="$t('genios.watchlist.add')"
          :disabled="noDocumentsSelected"
          :toggle-text="false"
      />
    </div>

  </div>

</template>


<script>

import axios from "axios";
import vClickOutside from "v-click-outside";
import vSelect from "vue-select";

import Spinner from "../styled/Spinner.vue";
import i18n from "../plugins/Translations.vue";
import Button from "../styled/Button.vue";

import {addBookmarks, fetchUsersWatchlistBookmarks} from "../../../functions/fetching";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import store from "../../../functions/store/modules";

export default {
  name: "WatchlistAddComponent",
  components: {Button, Spinner, vSelect, CheckboxFormElement},
  props: {
    sharedState: Object,
    documentIds: Array
  },
  data() {
    return {
      showIt: true,
      watchlistCollection: null,
      watchlistCollectionCheckBox: [],
      selectedItems: [],
      entityId: 'test',
      errorCode: null,
      newSelected: i18n.t("genios.watchlist.selectWatchlist"),
      defaultNrCheckBox: 3,
    }
  },
  mounted() {
    this.loadUsersWatchlists();
    fetchUsersWatchlistBookmarks();
  },
  directives: {
    clickOutside: vClickOutside?.directive
  },
  computed: {
    noDocumentsSelected() {
      return this.documentIds == null || this.documentIds.length < 1
    },
  },
  watch: {
    newSelected: function (value) {
      const defaultValue = i18n.t("genios.watchlist.selectWatchlist");
      if (value !== defaultValue) {
        this.watchlistCollectionCheckBox.push(value);
        this.addOrRemove(this.watchlistCollection, value);
        this.toggleSelection(value.id);
        this.newSelected = defaultValue;
      }
    }
  },
  methods: {
    loadUsersWatchlists() {
      axios.get("/api/watchlists/all", {}).then(response => {
        this.watchlistCollection = response.data;

        if (this.watchlistCollection !== null && this.watchlistCollection.length > 0) {
          const activeWatchlists = this.watchlistCollection.filter(watchlist =>
              watchlist.categoryTags && watchlist.categoryTags.includes("Active")
          );
          this.selectedItems = activeWatchlists.map(watchlist => watchlist.id);

          const watchlistSize = this.watchlistCollection.length;
          if (watchlistSize === 1) {
            this.selectedItems = [this.watchlistCollection[0].id];
          }
          for (let i = 0; i < watchlistSize; i++) {
            this.watchlistCollectionCheckBox.push(this.watchlistCollection.shift());
            if (i >= (this.defaultNrCheckBox - 1) && this.watchlistCollection.length > 1) {
              break;
            }
          }
        }
        this.errorCode = null;
      }).catch(errors => {
        this.errorCode = "genios.watchlist.errorLoad";
        console.error("Load-Watchlists " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      });
    },
    onClickOutside() {
      this.hideDialog();
    },

    hideDialog() {
      this.showIt = false;
      this.$emit('closeWidget', true);
    },
    toggleSelection(watchlistId) {
      this.addOrRemove(this.selectedItems, watchlistId);
    },
    isSelected(watchlistId) {
      return this.selectedItems.indexOf(watchlistId) > -1;
    },
    addBookmarkToList(savedBookmarks) {
      const bookmarks = this.sharedState.watchlistDocumentIds;
      if (bookmarks && this.sharedState.loginStatus.loggedIn) {
        for (let i = 0; i < savedBookmarks.length; i++) {
          if (bookmarks.indexOf(savedBookmarks[i]) === -1) {
            bookmarks.push(savedBookmarks[i]);
          }
        }
        sessionStorage.setItem('watchlistDocumentIds', JSON.stringify(bookmarks));
      }
    },

    saveElements() {
      if (this.selectedItems.length < 1) {
        this.errorCode = "genios.watchlist.noWatchlistSelected";
        return
      }

      addBookmarks(this.selectedItems, this.documentIds).then((response) => {
        const { data } = response;
        if (data && data.length > 0) {
          const savedBookmarks = data.flatMap(d =>
              d.map(doc => ({
                bookmarkId: doc.id,
                documentId: doc.bookmarkedItem,
              }))
          );
          this.addBookmarkToList(savedBookmarks);
          this.errorCode = null;
          this.$emit('documentsSaved', true);
          this.hideDialog();
        }
        this.watchlistCollectionCheckBox.forEach((watchlist) => {
          const isActive = this.isSelected(watchlist.id);
          store.dispatch('activateBookmarkList', {
            id: watchlist.id,
            isActive: isActive,
          });
        });
      }).catch(errors => {
        this.errorCode = "genios.watchlist.errorSave";
        console.error("Save-Watchlistentries " + errors + ".  Response:\n" + (errors.response ? JSON.stringify(errors.response.data) : '----'));
      });
    },
    addOrRemove(array, value) {
      const index = array.indexOf(value);
      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
    }

  }
}

</script>


<style src="vue-select/dist/vue-select.css"></style>

<style scoped>

/*
.filter__item {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin-top: 10px;
}

.filter__item .filter__item__name {
  flex: 20;
}

.filter__item .filter__item__checkbox {
  flex: 1;
  padding-left: 5px;
}*/

.watchlistCollection {
  padding-top: 20px;
}

.info {
  /*padding-top: 10px;*/
}
</style>
