<template>
  <div
      class="toggle-container"
      role="switch"
      :aria-checked="toggleOn"
      tabindex="0"
      @keydown.enter.prevent="toggle"
      @keydown.space.prevent="toggle"
      @click="toggle"
      :id="`toggle-${id}`"
      :aria-labelledby="`label-${id}`"
  >
    <div class="toggle-slider" :class="{ toggled: toggleOn }">
      <div class="toggle-ball" aria-hidden="true"></div>
    </div>
    <span class="visually-hidden" :id="`label-${id}`">
      {{ toggleOn ? 'Enabled' : 'Disabled' }}
    </span>
    <div class="toggle-label" v-if="label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    isSelected: false,
    label: {
      type: String,
      required: false
    },
    id: ''
  },
  data() {
    return {
      toggleOn: this.isSelected,
    };
  },
  methods: {
    toggle() {
      this.toggleOn = !this.toggleOn;
      this.$emit('update-toggle', this.toggleOn);
    }
  },
  watch: {
    isSelected(val) {
      this.toggleOn = val;
    }
  }
};
</script>

<style>
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-slider {
  width: 40px;
  height: 20px;
  background-color: var(--shade-color);
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-slider.toggled {
  background-color:  var(--primary-color);
}

.toggle-ball {
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
  box-shadow: var(--active-shadow)
}

.toggle-slider.toggled .toggle-ball {
  left: 22px;
}

.toggle-container {
  cursor: pointer;
}

.toggle-label {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-left: 8px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
