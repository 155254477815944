<template>
  <a :href="linkOut" :class="pClass">{{name}}</a>
</template>

<script>

export default {
  name: "NavigationSelectorItem",
  props: {
    name: String,
    urlSafeName: String,
    count: Number,
    pathRoot: String,
    navRoot: String,
    requestText: String,
    dateFilter: Array,
    advancedSearchParams: Array
  },
  computed: {
    linkOut: function () {
      const root = this.urlSafeName === this.navRoot ? '' : '/' + this.navRoot;
      const path = '/' + encodeURIComponent(this.pathRoot) + root + '/' + encodeURIComponent(this.urlSafeName);
      const urlWithParams = new URL(path, document.location);
      if (this.requestText) {
        urlWithParams.searchParams.append("requestText", this.requestText);
      }
      if (this.dateFilter && this.dateFilter.length ) {
        if (this.dateFilter[0].from) {
          urlWithParams.searchParams.append("date", 'from_' + this.dateFilter[0].from);
        }
        if (this.dateFilter[0].to) {
          urlWithParams.searchParams.append("date", 'to_' + this.dateFilter[0].to);
        }
      }
      if (this.advancedSearchParams && this.advancedSearchParams.length) {
        this.advancedSearchParams.forEach(function (param) {
          urlWithParams.searchParams.append("advanced", param);
        });
      }
      return urlWithParams.href;
    },
    active: function () {
      return this.count === undefined || this.count===null || this.count;
    },
    pClass: function () {
      return this.active ? "" : "deactivated";
    }
  }
}
</script>

<style scoped>
</style>
