import _ from 'lodash';
import eventBus from "../../eventBus";

export default {
    data() {
        return {
            scrollListenerInitialized: false,
            scrollPosition: 0,
            scrollHandler: null,
        };
    },
    created() {
        this.scrollHandler = _.debounce(this.handleScroll.bind(this), 0);
    },
    mounted() {
        this.checkScrollbarsAndInitialize();
    },
    beforeDestroy() {
        this.removeScrollListener();
    },
    methods: {
        handleScroll() {
            let scrollTop = window.pageOverlayScrollbar?.scroll?.().position?.y
                ?? document.body.scrollTop // Mobile priority
                ?? document.documentElement.scrollTop
                ?? window.scrollY;

            this.scrollPosition = scrollTop;

            if (typeof this.onScroll === 'function') {
                this.onScroll(scrollTop);
                eventBus.$emit("scrollEvent", scrollTop);
            }
        },
        initializeScrollListener() {
            if (window.pageOverlayScrollbar) {
                window.pageOverlayScrollbar.options("callbacks.onScroll", this.scrollHandler);
            } else {
                window.addEventListener("scroll", this.scrollHandler, { passive: true, capture: true });
                window.addEventListener("touchmove", this.scrollHandler, { passive: true });
            }
            this.scrollListenerInitialized = true;
        },
        checkScrollbarsAndInitialize() {
            const initialize = () => {
                if (!this.scrollListenerInitialized && (window.pageOverlayScrollbar || document.readyState === "complete")) {
                    this.initializeScrollListener();
                }
            };
            document.addEventListener("DOMContentLoaded", initialize);
            window.addEventListener("load", initialize);
        },
        removeScrollListener() {
            if (window.pageOverlayScrollbar) {
                window.pageOverlayScrollbar.options("callbacks.onScroll", null);
            } else {
                window.removeEventListener("scroll", this.scrollHandler);
                window.removeEventListener("touchmove", this.scrollHandler);
            }
        },
        onScroll(scrollPosition) {
            // To be implemented by components
        },
    }
};
