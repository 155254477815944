var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"right-drawer"},[(_vm.localMenuItems.length > 0)?_c('div',_vm._l((_vm.localMenuItems),function(item,index){return _c('div',{key:index,staticClass:"menu-item-wrapper"},[_c('div',{staticClass:"navigation-options",class:{
              'selected': _vm.isParentOrSelfSelected(item),
              'hovered': _vm.hoverItem === item.label || (_vm.hoverItem && item.children && item.children.includes(_vm.hoverItem))
            },on:{"click":function($event){return _vm.handleItemClick(item)},"mouseover":function($event){_vm.hoverItem = item.label},"mouseleave":function($event){_vm.hoverItem = null}}},[(item.url)?_c('div',{staticClass:"nav-link",on:{"click":function($event){$event.stopPropagation();return _vm.navigateToNavItem(item.url)}}},[_vm._v("\n            "+_vm._s(_vm.$t(`meinGenios.navigation.${item.label}`))+"\n          ")]):_c('div',[_vm._v(_vm._s(_vm.$t(`meinGenios.navigation.${item.label}`)))]),_vm._v(" "),(item.children)?_c('ion-icon',{attrs:{"name":item.expanded ? 'chevron-up-outline' : 'chevron-down-outline'},on:{"click":function($event){$event.stopPropagation();return _vm.toggleItem(item)}}}):_vm._e()],1),_vm._v(" "),(item.expanded && item.children)?_c('div',{staticClass:"sub-items"},_vm._l((item.children),function(subItem,subIndex){return _c('div',{key:subIndex,staticClass:"navigation-sub-options",class:{
                'selected': _vm.selectedItem === subItem.label,
                'hovered': _vm.hoverItem === subItem,
                'last-sub-item': subIndex === item.children.length - 1
              },on:{"click":function($event){$event.stopPropagation();return _vm.selectSubItem(item, subItem)},"mouseover":function($event){_vm.hoverItem = subItem},"mouseleave":function($event){_vm.hoverItem = null}}},[(subItem.url)?_c('div',{staticClass:"nav-link",on:{"click":function($event){return _vm.navigateToNavItem(subItem.url)}}},[_vm._v("\n              "+_vm._s(_vm.$t(`meinGenios.navigation.${subItem.label}`))+"\n            ")]):_c('div',[_vm._v(_vm._s(_vm.$t(`meinGenios.navigation.${subItem.label}`)))])])}),0):_vm._e()])}),0):_vm._e(),_vm._v(" "),_c('ResearchPurposeNavigationComponent')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }