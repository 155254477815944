// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.radio-left {
  flex-direction: row-reverse;
  padding-right: 10px;
}
.radio-right {
  flex-direction: row;
  padding-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/FormElements/radio/RadioFormComponent.vue"],"names":[],"mappings":";AA4CA;EACA,2BAAA;EACA,mBAAA;AACA;AAEA;EACA,mBAAA;EACA,kBAAA;AACA","sourcesContent":["<template>\n  <label class=\"radio-container\" :class=\"radioPosition && `radio-${radioPosition}`\">\n    {{ element.fieldLabel }}\n    <input\n        :id=\"element.id\"\n        class=\"form_element__input-radio\"\n        type=\"radio\"\n        name=\"radio-group\"\n        @change=\"setValue\"\n        :value=\"element.value\"\n    />\n    <ion-icon v-if=\"isChecked\" name=\"radio-button-on-outline\"/>\n    <ion-icon v-else name=\"radio-button-off-outline\"/>\n  </label>\n</template>\n\n<script>\nexport default {\n  props: {\n    element: Object,\n    groupValue: [String, Number],\n    radioPosition: {\n      type: String,\n      default: 'left'\n    }\n  },\n  data(){\n    return {\n    }\n  },\n  computed: {\n    isChecked() {\n      return this.groupValue === this.element.value\n    }\n  },\n  methods: {\n    setValue() {\n      this.$emit('modified', this.element.value)\n    }\n  }\n}\n</script>\n\n<style>\n.radio-left {\n  flex-direction: row-reverse;\n  padding-right: 10px;\n}\n\n.radio-right {\n  flex-direction: row;\n  padding-left: 10px;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
